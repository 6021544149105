import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Component from '../components/PostmasterRoute.jsx';
import * as actionCreators from '../actions';

const Container = withRouter(connect(
  (state) => {
    const props = {
      auth_type: state.auth_type,
    };
    return props;
  },
  actionCreators,
)(Component));

export default Container;
