export default {
  attribute: {
    amavisSpamTag2Level: {
        1: "9.5",
        2: "5.5",
        3: "4.0",
        4: "2.5",
      },
      amavisSpamTagLevel: {
        1: "7.0",
        2: "3.0",
        3: "1.5",
        4: "0.0",
      },
      amavisSpamKillLevel: {
        1: "10.0",
        2: "6.0",
        3: "4.5",
        4: "3.0",
      },
  },
  range: {
    amavisSpamTag2Level: {
        "9.5": 1,
        "5.5": 2,
        "4.0": 3,
        "2.5": 4,
      },
      amavisSpamTagLevel: {
        "7.0": 1,
        "3.0": 2,
        "1.5": 3,
        "0.0": 4,
      },
      amavisSpamKillLevel: {
        "10.0": 1,
        "6.0": 2,
        "4.5": 3,
        "3.0": 4,
      }, 
  }

};
