/* eslint-disable react/no-string-refs */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-plusplus */
/* eslint-disable radix */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable quote-props */
/* eslint-disable react/prop-types */

import React from 'react'
import { MDBBtn, MDBRow, MDBCol, MDBIcon } from 'mdbreact'
import Multiselect from 'react-widgets/lib/Multiselect'
import Input from './common/Input.jsx'
import Switch from './common/Switch.jsx'
import NavTab from './common/NavTab.jsx'
import { buildHomeDirectoryPattern } from '../utils'

export default class MailAliasConfig extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			loaded: 'none',
			selected: [],
			maildrop: [],
			refresh: 0
		}
	}

	componentDidMount = async () => {
		var { base } = this.props.match.params
		const [username, domain] = base.split('@')
		const minPostmaster = this.isMinPostmaster();
		await this.props.getMailAlias([base, minPostmaster]);
		this.setState({ loaded: 'success' })
	}

	isMinPostmaster = () =>
    this.props.auth_type === "admin" || this.props.auth_type === "postmaster";

	onSwitchChange = (e) => {
		const inverseOptions = ['amavisBypassVirusChecks', 'amavisBypassSpamChecks', 'bypassGreyListing']
		let { checked, id } = e.currentTarget
		const [mail, switchName] = id.split('&_')
		if (inverseOptions.includes(switchName)) checked = !checked
		const mailAlias = this.props.current_mail_alias
		mailAlias[switchName] = checked
		this.props.setCurrentMailAlias({ mailAlias })
	}

	onInputChange = e => {
		const { value, id } = e.currentTarget
		const [mail, inputName] = id.split('£_')

		const mailAlias = this.props.current_mail_alias
		mailAlias[inputName] = value
		this.props.setCurrentMailAlias({ mailAlias })
	}

	onMaildropChange = value => {
		const { current_mail_alias } = this.props
		current_mail_alias.maildrop = value
		this.props.setCurrentMailAlias({ mailAlias: current_mail_alias })
		this.setState({ refresh: this.state.refresh + 1 })
	}

	onCreateMaildropItem = name => {
		const { current_mail_alias } = this.props
		if (typeof current_mail_alias.maildrop === 'string')
			current_mail_alias.maildrop = [current_mail_alias.maildrop, name]
		else current_mail_alias.maildrop = [...current_mail_alias.maildrop, name]
		this.props.setCurrentMailAlias({ mailAlias: current_mail_alias })
		this.setState({ refresh: this.state.refresh + 1 })
	}

	onSelectChange = e => {
		const { value, id } = e.currentTarget
		const [selectName] = id.split('£_')
		const mailAlias = this.props.current_mail_alias
		mailAlias[selectName] = value
		this.props.setCurrentMailAlias({ mailAlias })
	}

	onSubmitConfig = async e => {
		e.preventDefault()
		const options = {}
		options.accountActive = document.getElementById('mail_config&_accountActive&_sw').checked
		options.amavisBypassVirusChecks = !document.getElementById('mail_config&_amavisBypassVirusChecks&_sw').checked
		options.bypassGreyListing = !document.getElementById('mail_config&_bypassGreyListing&_sw').checked
		options.smtpAuth = document.getElementById('mail_config&_smtpAuth&_sw').checked
		options.amavisBypassSpamChecks = !document.getElementById('mail_config&_amavisBypassSpamChecks&_sw').checked
		options.amavisSpamKillLevel = document.getElementById('amavisSpamKillLevel£_select').value
		options.amavisSpamTagLevel = document.getElementById('amavisSpamTagLevel£_select').value
		options.amavisSpamTag2Level = document.getElementById('amavisSpamTag2Level£_select').value
		options.maildrop = this.props.current_mail_alias.maildrop
		if(document.getElementById('mail_config£_homeDirectory')){
			options.homeDirectory = document.getElementById('mail_config£_homeDirectory').value
		}

		options.userPassword = document.getElementById('mail_config£_passwd').value
		options.conf_password = document.getElementById('mail_config£_conf_password').value
		const givenName = document.getElementById('mail_config£_givenName').value
		const sn = document.getElementById('mail_config£_sn').value

		/* Uso cn attuale per modificarlo se cambia gn o sn , altrimenti rimane il valore vecchio. */
		const { cn } = this.props.current_mail_alias
		let [currGN, currSN] = cn.trim().split(' ')
		if (givenName && givenName.trim() !== '') {
			options.givenName = givenName
			currGN = givenName
			options.cn = `${currGN} ${currSN}`
		}
		if (sn && sn.trim() !== '') {
			options.sn = sn
			currSN = sn
			options.cn = `${currGN} ${currSN}`
		}

		await this.props.modifyMailAlias({ mail: this.props.current_mail_alias.mail, options })
		this.props.getMailAlias([this.props.current_mail_alias.mail,this.isMinPostmaster()])
		if (this.isMinPostmaster())
			this.props.getDomain(this.props.current_domain.vd)
	}

	renderSpamKillSelect = currentValue => {
		const values = [
			{ value: '10.0', text: 'Low(10.0)', selected: false },
			{ value: '6.0', text: 'Medium(6.0)', selected: false },
			{ value: '4.5', text: 'High(4.5)', selected: false },
			{ value: '3.0', text: 'Very High(3.0)', selected: false }
		]
		return (
			<div className="mdl-selectfield">
				<label>Spam Kill Level</label>
				<select
					className="browser-default custom-select"
					id="amavisSpamKillLevel£_select"
					onChange={this.onSelectChange}
					disabled={!this.isMinPostmaster()}
				>
					{values.map(item => (
						<option key={item.value} value={item.value} selected={item.value === currentValue}>
							{item.text}
						</option>
					))}
				</select>
			</div>
		)
	}

	renderSpamTagSelect = currentValue => {
		const values = [
			{ value: '7.0', text: 'Low(7.0)', selected: false },
			{ value: '3.0', text: 'Medium(3.0)', selected: false },
			{ value: '1.5', text: 'High(1.5)', selected: false },
			{ value: '0.0', text: 'Very High(0.0)', selected: false }
		]
		return (
			<div className="mdl-selectfield">
				<label>Spam Tag Level</label>
				<select
					className="browser-default custom-select"
					id="amavisSpamTagLevel£_select"
					onChange={this.onSelectChange}
					disabled={!this.isMinPostmaster()}
				>
					{values.map(item => (
						<option key={item.value} value={item.value} selected={item.value === currentValue}>
							{item.text}
						</option>
					))}
				</select>
			</div>
		)
	}

	renderSpamTag2Select = currentValue => {
		const values = [
			{ value: '9.5', text: 'Low(9.5)', selected: false },
			{ value: '5.5', text: 'Medium(5.5)', selected: false },
			{ value: '4.0', text: 'High(4.0)', selected: false },
			{ value: '2.5', text: 'Very High(2.5)', selected: false }
		]
		return (
			<div className="mdl-selectfield" id="last_selectfield">
				<label>Spam Tag2 Level</label>
				<select
					className="browser-default custom-select"
					id="amavisSpamTag2Level£_select"
					onChange={this.onSelectChange}
					disabled={!this.isMinPostmaster()}
				>
					{values.map(item => (
						<option key={item.value} value={item.value} selected={item.value === currentValue}>
							{item.text}
						</option>
					))}
				</select>
			</div>
		)
	}

	renderSwitches = mailbox => (
		<div>
			<Switch
				id={'mail_config&_smtpAuth&_sw'}
				className="custom-control-inline"
				onChange={this.onSwitchChange}
				defaultChecked={mailbox.smtpAuth}
				label="SmtpAuth"
				disabled={!(this.isMinPostmaster() && (mailbox.allowedsmtpauth || mailbox.smtpAuth))}
			/>
			<Switch
				id={'mail_config&_accountActive&_sw'}
				className="custom-control-inline"
				onChange={this.onSwitchChange}
				defaultChecked={mailbox.accountActive}
				label="Attivo"
				disabled={!this.isMinPostmaster()}
			/>
			<Switch
				id={'mail_config&_bypassGreyListing&_sw'}
				className="custom-control-inline"
				onChange={this.onSwitchChange}
				defaultChecked={!mailbox.bypassGreyListing}
				label="GreyListing"
				disabled={!this.isMinPostmaster()}
			/>
			<Switch
				className="custom-control-inline"
				id={'mail_config&_amavisBypassVirusChecks&_sw'}
				onChange={this.onSwitchChange}
				defaultChecked={!mailbox.amavisBypassVirusChecks}
				label="Antivirus"
				disabled={!(this.isMinPostmaster() && (mailbox.allowedprotection || !mailbox.amavisBypassVirusChecks))}
			/>
			<Switch
				id={'mail_config&_amavisBypassSpamChecks&_sw'}
				className="custom-control-inline"
				onChange={this.onSwitchChange}
				defaultChecked={!mailbox.amavisBypassSpamChecks}
				label="SpamChecks"
				disabled={!this.isMinPostmaster()}
			/>
		</div>
	)

	renderBody = mailbox => (
		<form onSubmit={this.onSubmitConfig}>
			<p>Configurazione Account</p>
			<MDBRow className="mb-2">
				<MDBCol md="6">
					<Input
						type="text"
						id="mail_config£_givenName"
						className="mb-4"
						defaultValue={mailbox.givenName}
						label="Nome"
						onChange={this.onInputChange}
					/>
				</MDBCol>
				<MDBCol md="6">
					<Input
						type="text"
						id="mail_config£_sn"
						defaultValue={mailbox.sn}
						label="Cognome"
						onChange={this.onInputChange}
					/>
				</MDBCol>
			</MDBRow>
			<MDBRow className="mb-2">
				<MDBCol md="6">
					{/* The following two line are used to prevent browser from autofilling Form fields */}
					<input type= "text" style={{display:"none"}}/>
					<input type="password" style={{display:"none"}}/>
					{/* end */}
					<Input
						type="password"
						id="mail_config£_passwd"
						onChange={this.onInputChange}
						label="Password"
						defaultValue={''}
						pattern="^(?!.* ).{8,128}$"
					/>
					<small className="orange-text">
						Password, se inserita, deve essere tra 8 e 128 caratteri e non contenere spazi
					</small>
				</MDBCol>
				<MDBCol md="6">
					<Input
						type="password"
						id="mail_config£_conf_password"
						onChange={this.onInputChange}
						label="Conferma password"
						defaultValue={''}
						pattern="^(?!.* ).{8,128}$"
					/>
					<small className="orange-text">Le due password, se inserite , devono essere uguali</small>
				</MDBCol>
			</MDBRow>
			{this.props.auth_type === 'admin' && mailbox.objectClass.includes('PureFTPdUser') ? 
			<MDBRow className="mb-2">
				<MDBCol md="6">
					<Input
						type="text"
						id="mail_config£_homeDirectory"
						onChange={this.onInputChange}
						label="Home Directory"
						defaultValue={mailbox.homeDirectory}
						pattern={buildHomeDirectoryPattern(this.props.current_domain.name)}
					/>
					<small className="text-muted">{`Percorsi definibili solo da /srv/www/${this.props.current_domain.name} in giù`}</small>
				</MDBCol>
			</MDBRow> : null}
			<hr/>
			<MDBRow className="justify-content-center mt-2">
				<MDBCol md="12">
					<p>Configurazione filtri</p>
					{this.renderSwitches(mailbox)}
				</MDBCol>
			</MDBRow>
			<MDBRow className="mb-2">
				<MDBCol md="6">{this.renderSpamKillSelect(mailbox.amavisSpamKillLevel)}</MDBCol>
				<MDBCol md="6">
					<label>Maildrop</label>
					<Multiselect
						data={this.isMinPostmaster() ? this.props.mailboxes.map(item => item.mail) : []}
						value={mailbox.maildrop}
						allowCreate={this.props.auth_type === 'admin' ? 'onFilter' : undefined}
						onCreate={
							this.props.auth_type === 'admin' ? name => this.onCreateMaildropItem(name) : undefined
						}
						onChange={this.onMaildropChange}
						disabled={!this.isMinPostmaster()}
					/>
				</MDBCol>
			</MDBRow>
			<MDBRow className="mb-2">
				<MDBCol md="6">{this.renderSpamTagSelect(mailbox.amavisSpamTagLevel)}</MDBCol>
				<MDBCol md="6"> {this.renderSpamTag2Select(mailbox.amavisSpamTag2Level)}</MDBCol>
			</MDBRow>
			<MDBRow className="justify-content-center">
				<MDBBtn
					type="submit"
					value="Submit"
					disabled={this.props.modify_mail_state === 'request'}
					color="success"
				>
					<MDBIcon far icon="save" size="lg"></MDBIcon> Salva
				</MDBBtn>
			</MDBRow>
		</form>
	)

	render() {
		const mailbox = this.props.current_mail_alias
		const { auth_type } = this.props
		// eslint-disable-next-line react/prop-types
		return (
			<>
				{auth_type === 'admin' || auth_type === 'postmaster' ? (
					<MDBRow className="mb-3">
						<MDBCol md="5">
							<NavTab
								to={`/mailboxes/${this.props.current_domain.name}`}
								label="Torna al dominio"
								direction="left"
							/>
						</MDBCol>
					</MDBRow>
				) : null}
				<h3>
					Configurazione di <strong>{mailbox.mail}</strong>
				</h3>
				{this.state.loaded === 'success' ? this.renderBody(mailbox) : null}
			</>
		)
	}
}
