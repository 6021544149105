/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React from "react";
import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBIcon,
  MDBInput,
} from "mdbreact";
import Input from "./common/Input.jsx";
import Footer from "./Footer.jsx";
import config from "../config";

export default class Login extends React.Component {
  onSubmit = async (e) => {
    e.preventDefault();
    const login = document.getElementById("username").value;
    const password = document.getElementById("password").value;
    await this.props.auth({ login, password });
    this.props.history.push("/");
  };

  onChange = (e) => {
    e.preventDefault();
    const login = document.getElementById("username").value;
    const password = document.getElementById("password").value;
    this.props.setAuthState();
    this.props.setCredentials({ login, password });
  };

  render() {
    return (
      <>
        <MDBRow className="justify-content-center mt-5">
          <MDBCol md="6">
            <MDBCard className="card-body">
              <div className="d-flex flex-row justify-content-center p-3" style={{backgroundColor : config.mainColor}}>
                <img
                  width="120px"
                  height="120px"
                  src={config.logoPath}
                  className="img-fluid"
                  alt=""
                />
              </div>

              <MDBCardTitle className="text-center mt-3">
                Gestisci i servizi del tuo dominio
              </MDBCardTitle>
              <hr />
              { process.env.MAINTENANCE === "false" ?
              (
              <MDBCardText>
                <form onSubmit={this.onSubmit}>
                  <MDBInput
                    label="Username"
                    id="username"
                    type="text"
                    onChange={this.onChange}
                    value={this.props.credentials_username}
                    className="mb-4"
                    icon="user"
                  />
                  <MDBInput
                    label="Password"
                    id="password"
                    onChange={this.onChange}
                    type="password"
                    value={this.props.credentials_password}
                    className="mb-4"
                    icon="lock"
                  />
                  <MDBBtn block color="black" type="submit" className="mt-4">
                    Accedi
                  </MDBBtn>
                  {this.props.auth_state === "failure" ? (
                    <span className="red-text">Autenticazione fallita!</span>
                  ) : null}
                </form>
              </MDBCardText>
              )
              :
              (
              <MDBCardTitle>
                <span className="red-text">Attenzione il servizio è in manutenzione per attività programmata. Se avete particolari urgenze contattateci a support@rhx.it</span>
              </MDBCardTitle>
              )
              }
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol size="12">
            <Footer />
          </MDBCol>
        </MDBRow>
      </>
    );
  }
}
