/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/no-string-refs */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-plusplus */
/* eslint-disable radix */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable quote-props */
/* eslint-disable react/prop-types */

import React from 'react';
import {
  MDBBtn, MDBRow, MDBCol, MDBIcon,
} from 'mdbreact';
import Modal from './common/Modal.jsx';
import VirtualBackupMailCreationForm from './common/VirtualBackupMailCreationForm.jsx';
import Input from './common/Input.jsx';
import Spinner from './common/Spinner.jsx';

export default class VirtualBackupMail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //  loaded: 'none',
      refresh: 0,
    };
  }

  componentDidMount = async () => {
    await this.props.getVirtualBackupMail(this.props.current_domain.name);
  };

  onSubmitConfig = async (e) => {
    e.preventDefault();
    const options = {};
    options.postfixTransport = document.getElementById(
      'virtual_backup&_postfixTransport',
    ).value;
    await this.props.modifyDomain({
      domain: this.props.current_domain.name,
      options,
    });
   // this.props.getVirtualBackupMail(this.props.current_domain.name);
  };

  onCreateVirtualBackupMail = async (e) => {
	e.preventDefault();
    const postfixTransport = document.getElementById('create_postfixTransport').value;
    await this.props.createVirtualBackupMail({ domain: this.props.current_domain.name, postfixTransport });
	await this.props.getVirtualBackupMail(this.props.current_domain.name);
	await this.props.getDomain(this.props.current_domain.name);
	this.setState({ isOpenCreationModal: false });
  };

  openCreationModal = () => {
    this.setState({ isOpenCreationModal: true });
  };

  deleteVirtualBackupMail = async () => {
    await this.props.deleteVirtualBackupMail({
      mail: this.props.current_virtual_backup_mail.mail,
	});
	await this.props.getDomain(this.props.current_domain.name);
    this.setState({ isOpenDeleteModal: false });
	
  };

  renderError = () => (
    <h5 className="text-center mt-5">
      <MDBIcon
        fas
        icon="exclamation-triangle"
        className="text-danger"
      ></MDBIcon>{' '}
      Impostazioni VirtualBackupMail al momento non disponibili
    </h5>
  );

  renderBody = (virtualBackupMail) => {
    if (virtualBackupMail && Object.keys(virtualBackupMail).length !== 0) {
      return (
        <>
          <Modal
            title="Cancellazione VirtualBackupMail"
            message="Sei sicuro di voler cancellare questo VirtualBackupMail?"
            isOpen={this.state.isOpenDeleteModal}
            onClose={() => this.setState({ isOpenDeleteModal: false }).bind(this)
            }
            onConfirm={this.deleteVirtualBackupMail.bind(this)}
          />
          <form onSubmit={this.onSubmitConfig}>
            <MDBRow className="justify-content-center mb-4 pt-4">
              <MDBCol size="6">
                <Input
                  id={'virtual_backup&_postfixTransport'}
                  label="Postfix Transport"
                  defaultValue={virtualBackupMail.postfixTransport}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow className="justify-content-center mb-4 pt-4">
              <MDBBtn
                color="success"
                type="submit"
                value="Submit"
                disabled={this.props.modify_mail_state === 'request'}
              >
                <MDBIcon far icon="save" size="lg" /> Salva
              </MDBBtn>
              <MDBBtn
                value="Delete"
                color="danger"
                onClick={() => this.setState({ isOpenDeleteModal: true }).bind(this)
                }
              >
                <MDBIcon far icon="trash-alt" size="lg" /> Elimina
              </MDBBtn>
            </MDBRow>
          </form>
        </>
      );
    }
    return (
      <>
        <Modal
          title="Creazione VirtualBackupMail"
          isOpen={this.state.isOpenCreationModal}
          withoutFooter
          message={
            <VirtualBackupMailCreationForm
              onClose={() => this.setState({ isOpenCreationModal: false }).bind(this)
              }
              onSubmit={this.onCreateVirtualBackupMail.bind(this)}
            />
          }
        />
        <h4>
          VirtualBackupMail non abilitato per questo dominio. Vuoi crearlo?
        </h4>
        <MDBRow>
          <MDBCol md="4" className="md-offset-2 text-center">
            <MDBBtn
              className="btn-rhx"
              color="yellow"
              block
              onClick={this.openCreationModal}
            >
              + Crea
            </MDBBtn>
          </MDBCol>
        </MDBRow>
      </>
    );
  };

  render() {
    const { current_virtual_backup_mail } = this.props;
    // eslint-disable-next-line react/prop-types
    return (
      <>
        {this.props.get_virtual_backup_mail_state === 'success'
          ? this.renderBody(current_virtual_backup_mail)
          : null}
        {this.props.get_virtual_backup_mail_state === 'failure'
          ? this.renderError()
          : null}
		{this.props.get_virtual_backup_mail_state === 'request'
		  ? <Spinner/>
		  : null}
      </>
    );
  }
}
