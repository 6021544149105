/* eslint-disable import/prefer-default-export */
/* eslint-disable react/display-name */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React from 'react'
import { MDBRow, MDBCol, MDBBtn } from 'mdbreact'
import Multiselect from 'react-widgets/lib/Multiselect'
import Input from './Input.jsx'
import Switch from './Switch.jsx'

export default props => (
	<form onSubmit={props.onSubmit}>
		<MDBRow>
			<MDBCol size="12">
				<Input
					id="catch_name"
					type="text"
					label="Nome alias *"
					placeholder="Nome alias"
					disabled
					value={props.nameValue}
					required
				/>
			</MDBCol>
		</MDBRow>
		<MDBRow className="mt-2">
			<MDBCol size="12">
				<Input
					id="create_catch_name"
					label="Nome *"
					placeholder="Nome dell'utente"
					type="text"
					disabled
					value="Catch"
				/>
			</MDBCol>
		</MDBRow>
		<MDBRow className="mt-2">
			<MDBCol size="12">
				<Input
					id="create_catch_surname"
					label="Cognome *"
					placeholder="Cognome dell'utente"
					type="text"
					disabled
					value="All"
				/>
			</MDBCol>
		</MDBRow>
		<MDBRow className="my-2">
			<MDBCol size="12">
				<label>Maildrop</label>
				<Multiselect
					data={props.maildropData}
					value={props.maildropValue}
					allowCreate={props.allowMaildropCreate}
					onCreate={props.onMaildropCreate}
					onChange={props.onMaildropChange}
				/>
			</MDBCol>
		</MDBRow>
		<Switch id="catch_greylisting" label="GreyListing" />
		<Switch id="catch_spamchecks" label="SpamChecks" />
		<MDBRow className="mt-2 text-center">
			<MDBCol size="12">
				<MDBBtn color="white" onClick={props.onClose}>
					Annulla
				</MDBBtn>
				<MDBBtn className="btn-rhx" color="yellow" type="submit">
					+ Crea
				</MDBBtn>
			</MDBCol>
		</MDBRow>
	</form>
)
