/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { MDBContainer } from 'mdbreact';
import Domains from '../containers/Domains';
import MailConfig from '../containers/MailConfig';
import MailAliasConfig from '../containers/MailAliasConfig';
import MailBoxes from '../containers/MailBoxes';
import Login from '../containers/Login';
import Header from '../containers/Header';
import Logs from '../containers/Logs';
import BanLogs from '../containers/BanLogs';
import BannedIP from '../containers/BannedIP';
import WhiteListIP from '../containers/WhiteListIP';
import BlackListIP from '../containers/BlackListIP';
import Statistics from '../containers/Statistics';
import AdminRoute from '../containers/AdminRoute';
import UserRoute from '../containers/UserRoute';
import GuestRoute from '../containers/GuestRoute';
import PostmasterRoute from '../containers/PostmasterRoute';
import AliasRoute from '../containers/AliasRoute';
import '../styles/style.css';

export default class App extends React.Component {
  renderRedirect() {
    switch (this.props.auth_type) {
      case 'admin':
        return <Redirect to="/domains" />;
      case 'postmaster':
        return <Redirect to={`/mailboxes/${this.props.auth_domain}`} />;
      case 'user':
        return <Redirect to={`/mailbox/${this.props.auth_username}`} />;
      case 'alias':
        return <Redirect to={`/mailbox/alias/${this.props.auth_username}`} />;
      default:
        return <Redirect to="/login" />;
    }
  }

  render() {
    // eslint-disable-next-line react/prop-types
    return (
      <>
        <Header />
        <MDBContainer>
          <Switch>
            <Route exact path="/" render={() => this.renderRedirect()} />
            <AdminRoute path="/domains" component={Domains} />
            <PostmasterRoute path="/logs" component={Logs} />
            <AdminRoute path="/banned_ip/logs" component={BanLogs} />
            <AdminRoute path="/banned_ip" component={BannedIP} />
            <AdminRoute path="/whitelist_ip" component={WhiteListIP} />
            <AdminRoute path="/blacklist_ip" component={BlackListIP} />
            <AdminRoute path="/statistics" component={Statistics} />
            <GuestRoute path="/login" component={Login} />
            <AliasRoute exact path="/mailbox/alias/:base" component={MailAliasConfig} />
            <UserRoute exact path="/mailbox/:base" component={MailConfig} />
            <PostmasterRoute
              exact
              path="/mailboxes/:base"
              component={MailBoxes}
            />

            <Route render={() => this.renderRedirect()} />
          </Switch>
        </MDBContainer>

        {/* <Footer /> */}
      </>
    );
  }
}
