/* eslint-disable no-prototype-builtins */
/* eslint-disable react/jsx-key */
/* eslint-disable camelcase */
/* eslint-disable radix */
/* eslint-disable react/prop-types */
import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  MDBRow,
  MDBCol,
  MDBBadge,
  MDBIcon,
  MDBBtn,
  MDBInputGroup,
  MDBTable,
  MDBTableBody,
  MDBTooltip,
  MDBDataTable,
} from "mdbreact";
import { bytesToMb, bytesToGb, isReservedMail } from "../utils";
import FTPConfig from "./FTPConfig.jsx";
import CatchAllConfig from "../containers/CatchAllConfig";
import Modal from "./common/Modal.jsx";
import Switch from "./common/Switch.jsx";
import MailCreationForm from "./common/MailCreationForm.jsx";
import AliasCreationForm from "./common/AliasCreationForm.jsx";
import Dropdown from "./common/Dropdown.jsx";
import Logs from "../containers/Logs";
import DNS from "../containers/DNS";
import config from "../config";
import Input from "./common/Input.jsx";
import VirtualBackupMail from "../containers/VirtualBackupMail";

export default class MailBoxes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mailbox_name_value: "",
      alias_name_value: "",
      del_selected_mail: "",
      tab: "mail",
      loaded: false,
      isCreateModalOpen: false,
      isDeleteModalOpen: false,
      isCreateAliasModalOpen: false,
      maildropOfNewAlias: [],
      mailboxesFilter: false,
      aliasesFilter: false,
      aliasesMailDropFilter: false,
      refresh: 0,
    };
  }

  onOptionChange = async (e) => {
    // listener per i checkbox antivirus, spam ecc.
    const inverseOptions = [
      "amavisBypassVirusChecks",
      "amavisBypassSpamChecks",
      "bypassGreyListing",
    ];
    let { id, checked } = e.currentTarget;
    const [mail, option] = id.split("&_");
    if (inverseOptions.includes(option)) checked = !checked;
    const mailData = { mail, options: { [option]: checked } };

    const { base } = this.props.match.params;
    await this.props.modifyMailbox(mailData);
    this.props.getMailboxes(base);
  };

  refresh = () => {
    this.setState({ refresh: this.state.refresh + 1 });
  };

  onAliasOptionChange = async (e) => {
    // listener per i checkbox antivirus, spam ecc.
    const inverseOptions = [
      "amavisBypassVirusChecks",
      "amavisBypassSpamChecks",
      "bypassGreyListing",
    ];
    let { id, checked } = e.currentTarget;
    const [mail, option] = id.split("&_");
    if (inverseOptions.includes(option)) checked = !checked;
    const mailData = { mail, options: { [option]: checked } };
    const { base } = this.props.match.params;
    await this.props.modifyMailAlias(mailData);
    this.props.getMailAliases(base);
  };

  downloadCsv = async (aliases = false) => {
    const { base } = this.props.match.params;
    await this.props.getCsv(base, aliases);
  };

  onCreateMailbox = async (e) => {
    e.preventDefault();
    const gn = document.getElementById("create_mail_name").value;
    const sn = document.getElementById("create_mail_surname").value;
    const mail = document.getElementById("mailbox_name").value;
    const password = document.getElementById("create_mail_pw").value;
    const conf_password = document.getElementById("create_mail_conf_pw").value;
    const mailData = {
      mail,
      password,
      conf_password,
      sn,
      gn,
    };
    await this.props.createMailbox(mailData);
    const { base } = this.props.match.params;
    this.onDialogClose();
    this.props.getMailboxes(base);
  };

  onMaildropChange = (value) => {
    this.setState({ maildropOfNewAlias: value });
  };

  onCreateAlias = async (e) => {
    e.preventDefault();
    const gn = document.getElementById("create_alias_name").value;
    const sn = document.getElementById("create_alias_surname").value;
    const mail = document.getElementById("alias_name").value;
    const maildrop = this.state.maildropOfNewAlias;
    const password = document.getElementById("create_alias_pw").value || null;
    const conf_password =
      document.getElementById("create_alias_conf_pw").value || null;
    const bypassGreyListing =
      !document.getElementById("alias_greylisting").checked;
    const amavisBypassSpamChecks =
      !document.getElementById("alias_spamchecks").checked;

    const mailData = {
      mail,
      maildrop,
      amavisBypassSpamChecks,
      bypassGreyListing,
      password,
      conf_password,
      sn,
      gn,
    };
    await this.props.createMailAlias(mailData);
    const { base } = this.props.match.params;
    this.setState({ isCreateAliasModalOpen: false });
    this.props.getMailAliases(base);
  };

  onCreateAliasPress = () => {
    const { base } = this.props.match.params;
    const inputValue = document.getElementById("create-alias-input").value;
    const mail = `${inputValue}@${base}`;
    this.setState({ alias_name_value: mail, isCreateAliasModalOpen: true });
  };

  onCreateNewAliasMaildropItem = (name) => {
    this.setState({
      maildropOfNewAlias: [...this.state.maildropOfNewAlias, name],
      refresh: this.state.refresh + 1,
    });
  };

  onCreateMailPress = () => {
    const { base } = this.props.match.params;
    const inputValue = document.getElementById("create-mail-input").value;
    const mail = `${inputValue}@${base}`;
    this.setState({ mailbox_name_value: mail, isCreateModalOpen: true });
  };

  onDialogClose = () => this.setState({ isCreateModalOpen: false });

  onDeleteDialogClose = () => this.setState({ isDeleteModalOpen: false });

  onMailDelete = async () => {
    await this.props.deleteMailbox({ mail: this.state.del_selected_mail });
    this.onDeleteDialogClose();
    const { base } = this.props.match.params;
    this.props.getMailboxes(base);
    this.props.getMailAliases(base);
  };

  filterMailboxes = (mailboxes) => {
    const filter = this.state.mailboxesFilter;
    return this.filter(mailboxes, filter);
  };

  filterAliases = (mailboxes) => {
    const filter = this.state.aliasesFilter;
    return this.filter(mailboxes, filter);
  };

  filter = (mailboxes, filter) => {
    if (filter) {
      return mailboxes.filter((item) => item.mail.includes(filter));
    }
    return mailboxes;
  };

  filterAliasesMaildrop = (mailboxes) => {
    const filter = this.state.aliasesMaildropFilter;
    if (filter) {
      return mailboxes.filter((item) => {
        if (Array.isArray(item.maildrop)) {
          return item.maildrop.some((address) => address.includes(filter));
        }
        return item.maildrop && item.maildrop.includes(filter);
      });
    }
    return mailboxes;
  };

  setFilter = (e) => {
    const { value, name } = e.currentTarget;
    this.setState({
      [name]: value && value.trim() !== "" ? value : false,
    });
  };

  onClickDelete = (e) => {
    const { id } = e.currentTarget;
    const [mail] = id.split("&_");
    this.setState({ del_selected_mail: mail, isDeleteModalOpen: true });
  };

  componentDidMount = async () => {
    const { base } = this.props.match.params;
    await this.props.getDomain(base);
    await this.props.getMailboxes(base);
    await this.props.getMailAliases(base);
    await this.props.getFTPUser(base);
    this.setState({ loaded: true });
  };

  getCreatedTime = (creationDate) => {
    if (creationDate && creationDate.toString().length === 8)
      return moment(creationDate, "YYYYMMDD").format("DD-MM-YYYY HH:mm");
    if (creationDate && creationDate.toString().length === 12)
      return moment(creationDate, "YYYYMMDDhhmm").format("DD-MM-YYYY HH:mm");
    if (creationDate && Number.isInteger(Number(creationDate)))
      return moment.unix(Number(creationDate)).format("DD-MM-YYYY HH:mm");
    if (creationDate) return creationDate.toString();
    return "Data Sbagliata";
  };

  isVirtualBackupMail = () => {
    const { current_domain } = this.props;
    return (
      current_domain.postfixTransport &&
      current_domain.postfixTransport !== "maildrop:"
    );
  };

  hasCatchAll = () => {
    const { mail_aliases, current_domain } = this.props;
    const names = mail_aliases.map((item) => item.mail);
    return names.includes(`@${current_domain.name}`);
  };

  FTPInputChange = (e) => {
    const { value, id } = e.currentTarget;
    const [ftp, inputName] = id.split("£_");

    const ftpUser = this.props.current_ftp_user;
    ftpUser[inputName] = value;
    this.props.setCurrentFTPUser({ ftpUser });
  };

  FTPSwitchChange = (e) => {
    const { checked, id } = e.currentTarget;
    const [ftp, inputName] = id.split("£_");
    const ftpUser = this.props.current_ftp_user;
    ftpUser[inputName] = checked;
    this.props.setCurrentFTPUser({ ftpUser });
  };

  isAllowedSmtpAuth = () => {
    const { mailboxes, mail_aliases, current_domain } = this.props;
    const boxes = [...mailboxes, ...mail_aliases];
    const usedSmtpAuth = boxes
      .filter((item) => !isReservedMail(item.mail))
      .reduce((acc, curr) => (curr.smtpAuth ? acc + 1 : acc), 0);
    return (
      current_domain.maxSmtpAuth > 0 &&
      current_domain.maxSmtpAuth > usedSmtpAuth
    );
  };

  isAllowedVirusProtection = () => {
    const { mailboxes, mail_aliases, current_domain } = this.props;
    const boxes = [...mailboxes, ...mail_aliases];
    const usedVirusChecks = boxes
      .filter((item) => !isReservedMail(item.mail))
      .reduce(
        (acc, curr) => (curr.amavisBypassVirusChecks === false ? acc + 1 : acc),
        0
      );
    return (
      current_domain.maxAntivirus > 0 &&
      current_domain.maxAntivirus > usedVirusChecks
    );
  };

  FTPSubmit = async (e) => {
    e.preventDefault();
    const { current_ftp_user } = this.props;
    if (!current_ftp_user.objectClass.includes("PureFTPdUser")) {
      delete current_ftp_user.FTPStatus;
      delete current_ftp_user.FTPQuotaMBytes;
    }
    const { dn } = current_ftp_user;
    let options = { ...current_ftp_user };
    if (this.props.auth_type === "postmaster") {
      const opt = {};
      if (current_ftp_user.hasOwnProperty("userPassword"))
        opt.userPassword = current_ftp_user.userPassword;
      if (current_ftp_user.hasOwnProperty("conf_password"))
        opt.conf_password = current_ftp_user.conf_password;
      options = opt;
    }
    await this.props.modifyFTPUser({ dn, options });

    if (this.props.auth_type === "admin") {
      const { current_domain } = this.props;
      await this.props.modifyDomain({
        domain: current_domain.name,
        options: { ...current_domain },
      });
    }
  };

  domainInputChange = (e) => {
    const { value, id } = e.currentTarget;
    const [domain, inputName] = id.split("£_");

    const { current_domain } = this.props;
    current_domain[inputName] = value;
    this.props.setCurrentDomain({ domain: current_domain });
  };

  domainSwitchChange = (e) => {
    const { checked, id } = e.currentTarget;
    const [domain, inputName] = id.split("£_");
    const { current_domain } = this.props;
    current_domain[inputName] = checked;
    this.props.setCurrentDomain({ domain: current_domain });
  };

  renderMailboxes(mailboxes) {
    const data = {
      columns: [
        {
          label: "Info",
          field: "info",
        },
        {
          label: "Indirizzo",
          field: "address",
        },
        {
          label: "Attivo",
          field: "active",
        },
        {
          label: "SmtpAuth",
          field: "smtpauth",
        },
        {
          label: "Antivirus",
          field: "antivirus",
        },

        {
          label: "AntiSPAM",
          field: "spamchecks",
        },
        {
          label: "Greylist",
          field: "greylisting",
        },
        {
          label: "Cancella",
          field: "delete",
        },
      ],
      rows: mailboxes.map((item) => ({
        info: (
          <Dropdown
            key={`dropdown_${item.mail}`}
            items={[
              <p>
                <MDBIcon far icon="calendar-alt" className="mr-1" />
                Data di creazione:{" "}
                <span className="font-weight-light">
                  {this.getCreatedTime(item.creationDate)}
                </span>
              </p>,
              <p>
                <MDBIcon icon="pencil-alt" className="mr-1" />
                Data di modifica:{" "}
                <span className="font-weight-light">
                  {moment
                    .unix(parseInt(item.lastChange))
                    .format("DD-MM-YYYY HH:mm")}
                </span>
              </p>,
              <p>
                <MDBIcon far icon="user" className="mr-1" />
                Nome completo:{" "}
                <span className="font-weight-light">{item.cn}</span>
              </p>,
            ]}
          />
        ),
        address: (
          <div
            key={`address_${item.mail}`}
            className="d-flex flex-column align-items-start"
          >
            <MDBTooltip placement="right">
              <Link className="font-weight-normal" to={`/mailbox/${item.mail}`}>
                {item.mail}
              </Link>
              <div>Configura</div>
            </MDBTooltip>

            <>
              { 
                //Se quotausage non è impostato correttamente visualizzo solo la quota della casella.
                isNaN(parseInt(item.quotausage)) ?
                <small className="font-weight-light">
                  Quota: {Math.round(bytesToGb(parseInt(item.quota)))}GB
                </small>
                :
                Math.round((item.quotausage/item.quota)*100)>=80?
                  Math.round((item.quotausage/item.quota)*100)>=90?
                    <>
                      <small className="font-weight-light">
                        Quota: {Math.round(bytesToGb(parseInt(item.quotausage))*100)/100}GB / {Math.round(bytesToGb(parseInt(item.quota)))}GB (
                        <span className="red-text">
                          uso {Math.round((item.quotausage/item.quota)*100)}% 
                        </span>
                          )
                      </small>
                    </>
                    :
                    <>
                      <small className="font-weight-light">
                        Quota: {Math.round(bytesToGb(parseInt(item.quotausage))*100)/100}GB / {Math.round(bytesToGb(parseInt(item.quota)))}GB (
                        <span className="orange-text">
                          uso {Math.round((item.quotausage/item.quota)*100)}% 
                        </span>
                          )
                      </small>
                    </>
                  :
                  <small className="font-weight-light">
                    Quota: {Math.round(bytesToGb(parseInt(item.quotausage))*100)/100}GB / {Math.round(bytesToGb(parseInt(item.quota)))}GB (uso {Math.round((item.quotausage/item.quota)*100)}%)
                  </small>
              }
            </>
          </div>
        ),
        active: (
          <Switch
            key={`${item.mail}&_accountActive`}
            id={`${item.mail}&_accountActive`}
            onChange={this.onOptionChange.bind(this)}
            defaultChecked={item.accountActive}
            label="Attivo"
          />
        ),
        smtpauth: (
          <Switch
            id={`${item.mail}&_smtpAuth`}
            key={`${item.mail}&_smtpAuth`}
            onChange={this.onOptionChange.bind(this)}
            defaultChecked={item.smtpAuth}
            disabled={
              !this.isAllowedSmtpAuth() &&
              !item.smtpAuth &&
              !isReservedMail(item.mail)
            }
            label="SmtpAuth"
          />
        ),
        antivirus: (
          <Switch
            id={`${item.mail}&_amavisBypassVirusChecks`}
            key={`${item.mail}&_amavisBypassVirusChecks`}
            onChange={this.onOptionChange.bind(this)}
            defaultChecked={!item.amavisBypassVirusChecks}
            disabled={
              !this.isAllowedVirusProtection() &&
              !item.amavisBypassVirusChecks === false &&
              !isReservedMail(item.mail)
            }
            label="Antivirus"
          />
        ),
        spamchecks: (
          <Switch
            key={`${item.mail}&_amavisBypassSpamChecks`}
            id={`${item.mail}&_amavisBypassSpamChecks`}
            onChange={this.onOptionChange.bind(this)}
            defaultChecked={!item.amavisBypassSpamChecks}
            label="AntiSPAM"
          />
        ),
        greylisting: (
          <Switch
            key={`${item.mail}&_bypassGreyListing`}
            id={`${item.mail}&_bypassGreyListing`}
            onChange={this.onOptionChange.bind(this)}
            defaultChecked={!item.bypassGreyListing}
            label="Greylist"
          />
        ),
        delete: (
          <div className="text-right" key={`${item.mail}&_del_button`}>
            <a
              tag="a"
              id={`${item.mail}&_del_button`}
              onClick={this.onClickDelete.bind(this)}
            >
              <MDBIcon far icon="trash-alt" className="text-danger" size="lg" />
            </a>
          </div>
        ),
      })),
    };
    return (
      <MDBDataTable
        data={data}
        entries="20"
        entriesLabel="Per pagina:"
        searching={false}
        sorting={false}
        bordered
        className="table-striped mt-4"
      ></MDBDataTable>
    );
  }

  renderMailAliases(mailAliases) {
    const aliases = mailAliases.filter(
      (item) =>
        !item.dn.includes("cn=postmaster") &&
        item.mail !== `@${this.props.current_domain.name}`
    );

    var columns = [
      {
        label: "Info",
        field: "info",
      },
      {
        label: "Indirizzo",
        field: "address",
      },
      {
        label: "Attivo",
        field: "active",
      },
      {
        label: "SmtpAuth",
        field: "smtpauth",
      },
      {
        label: "Antivirus",
        field: "antivirus",
      },

      {
        label: "AntiSPAM",
        field: "spamchecks",
      },
      {
        label: "Cancella",
        field: "delete",
      },
    ];

    var rows = aliases.map((item) => ({
      info: (
        <Dropdown
          key={item.mail}
          items={[
            <p>
              <MDBIcon far icon="calendar-alt" className="mr-1" />
              Data di creazione:{" "}
              <span className="font-weight-light">
                {this.getCreatedTime(item.creationDate)}
              </span>
            </p>,
            <p>
              <MDBIcon icon="pencil-alt" className="mr-1" />
              Data di modifica:{" "}
              <span className="font-weight-light">
                {moment
                  .unix(parseInt(item.lastChange))
                  .format("DD-MM-YYYY HH:mm")}
              </span>
            </p>,
          ]}
        />
      ),
      address: (
        <div
          className="d-flex flex-column  align-items-start"
          key={`${item.mail}&_address`}
        >
          <MDBTooltip placement="right">
            <Link
              className="font-weight-normal"
              to={`/mailbox/alias/${item.mail}`}
            >
              {item.mail}
            </Link>
            <div>Configura</div>
          </MDBTooltip>

          <small className="font-weight-light">{item.cn}</small>
        </div>
      ),
      active: (
        <Switch
          key={`${item.mail}&_accountActive`}
          id={`${item.mail}&_accountActive`}
          onChange={this.onAliasOptionChange.bind(this)}
          defaultChecked={item.accountActive}
          label="Attivo"
        />
      ),
      smtpauth: (
        <Switch
          id={`${item.mail}&_smtpAuth`}
          key={`${item.mail}&_smtpAuth`}
          onChange={this.onAliasOptionChange.bind(this)}
          defaultChecked={item.smtpAuth}
          disabled={
            !this.isAllowedSmtpAuth() &&
            !item.smtpAuth &&
            !isReservedMail(item.mail)
          }
          label="SmtpAuth"
        />
      ),
      antivirus: (
        <Switch
          id={`${item.mail}&_amavisBypassVirusChecks`}
          onChange={this.onAliasOptionChange.bind(this)}
          key={`${item.mail}&_amavisBypassVirusChecks`}
          defaultChecked={!item.amavisBypassVirusChecks}
          disabled={
            !this.isAllowedVirusProtection() &&
            !item.amavisBypassVirusChecks === false &&
            !isReservedMail(item.mail)
          }
          label="Antivirus"
        />
      ),
      spamchecks: (
        <Switch
          key={`${item.mail}&_amavisBypassSpamChecks`}
          id={`${item.mail}&_amavisBypassSpamChecks`}
          onChange={this.onAliasOptionChange.bind(this)}
          defaultChecked={!item.amavisBypassSpamChecks}
          label="SpamChecks"
        />
      ),
      delete: (
        <div className="text-right" key={`${item.mail}&_del_button`}>
          {item.mail !== `webmaster@${this.props.current_domain.name}` ? (
            <a
              tag="a"
              className="ml-5"
              id={`${item.mail}&_del_button`}
              onClick={this.onClickDelete.bind(this)}
            >
              <MDBIcon far icon="trash-alt" className="text-danger" size="lg" />
            </a>
          ) : null}
        </div>
      ),
    }));
    return (
      <MDBDataTable
        data={{ rows, columns }}
        entries="20"
        entriesLabel="Per pagina:"
        searching={false}
        sorting={false}
        bordered
        className="table-striped mt-4"
      ></MDBDataTable>
    );
  }

  render() {
    const { mailboxes, mail_aliases, auth_type } = this.props;
    const { base } = this.props.match.params;
    const { current_domain } = this.props;
    const maxMail = parseInt(current_domain.maxMail);
    // const maxQuota = parseInt(current_domain.maxQuota);

    return (
      <main id="mailboxes">
        <Modal
          title="Creazione della casella"
          isOpen={this.state.isCreateModalOpen}
          withoutFooter
          message={
            <MailCreationForm
              onSubmit={this.onCreateMailbox.bind(this)}
              onClose={this.onDialogClose.bind(this)}
              mailboxNameValue={this.state.mailbox_name_value}
              mailboxNameChange={(e) =>
                this.setState({
                  mailbox_name_value: e.currentTarget.value,
                }).bind(this)
              }
            />
          }
        />

        <Modal
          title="Creazione dell'alias"
          isOpen={this.state.isCreateAliasModalOpen}
          withoutFooter
          message={
            <AliasCreationForm
              onClose={() =>
                this.setState({ isCreateAliasModalOpen: false }).bind(this)
              }
              onSubmit={this.onCreateAlias.bind(this)}
              nameValue={this.state.alias_name_value}
              nameChange={(e) =>
                this.setState({ alias_name_value: e.currentTarget.value }).bind(
                  this
                )
              }
              maildropValue={this.state.maildropOfNewAlias}
              allowMaildropCreate={
                this.props.auth_type === "admin" ? "onFilter" : undefined
              }
              onMaildropCreate={
                this.props.auth_type === "admin"
                  ? this.onCreateNewAliasMaildropItem.bind(this)
                  : undefined
              }
              maildropData={mailboxes.map((item) => item.mail)}
              onMaildropChange={this.onMaildropChange.bind(this)}
            />
          }
        />

        <Modal
          title="Cancellazione della casella"
          isOpen={this.state.isDeleteModalOpen}
          onClose={this.onDeleteDialogClose.bind(this)}
          onConfirm={this.onMailDelete.bind(this)}
          message={`Sei sicuro di voler cancellare la casella ${this.state.del_selected_mail}?`}
        />
        <h2>
          <i className="fa fa-hdd mr-2"></i>
          {base}
        </h2>
        {this.hasCatchAll() ? (
          <h3 className="text-warning">
            Catch all attivo per questo dominio!
          </h3>
        ) : null}
        {this.isVirtualBackupMail() ? (
          <h3 className="text-warning">
            Questo dominio è un VirtualBackupMail!
          </h3>
        ) : null}
        <ul className="nav nav-tabs nav-fill mt-4 justify-content-center">
          <li className="nav-item">
            <a
              className={`nav-link font-weight-bold  ${
                this.state.tab === "mail" ? "active" : " "
              }`}
              onClick={() => this.setState({ tab: "mail" })}
              href="#"
            >
              <MDBIcon far icon="envelope" className="mr-1" />
              Mail
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link font-weight-bold  ${
                this.state.tab === "alias" ? "active" : " "
              }`}
              onClick={() => this.setState({ tab: "alias" })}
              href="#"
            >
              <MDBIcon icon="at" className="mr-1" /> Alias
            </a>
          </li>
          {this.props.auth_type === "admin" && !this.hasCatchAll() ? (
            <li className="nav-item">
              <a
                className={`nav-link font-weight-bold  ${
                  this.state.tab === "virtual_backup_mail" ? "active" : " "
                }`}
                onClick={() => this.setState({ tab: "virtual_backup_mail" })}
                href="#"
              >
                <MDBIcon icon="mail-bulk" className="mr-1" /> VirtualBackupMail
              </a>
            </li>
          ) : null}
          {!this.isVirtualBackupMail() ? (
            <li className="nav-item">
              <a
                className={`nav-link font-weight-bold  ${
                  this.state.tab === "catch_all" ? "active" : " "
                }`}
                onClick={() => this.setState({ tab: "catch_all" })}
                href="#"
              >
                <MDBIcon icon="mail-bulk" className="mr-1" />
                Catch All
              </a>
            </li>
          ) : null}
          <li className="nav-item">
            <a
              className={`nav-link font-weight-bold ${
                this.state.tab === "ftp" ? "active" : " "
              }`}
              onClick={() => this.setState({ tab: "ftp" })}
              href="#"
            >
              <MDBIcon icon="server" className="mr-1" />
              Impostazioni Dominio
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link font-weight-bold ${
                this.state.tab === "dns" ? "active" : " "
              }`}
              onClick={() => this.setState({ tab: "dns" })}
              href="#"
            >
              <MDBIcon icon="tools" className="mr-1" />
              Avanzate
            </a>
          </li>
        </ul>

        <MDBRow className="mt-4">
          {this.props.auth_type === "admin" || !this.isVirtualBackupMail() ? (
            <MDBCol md="6">
              {this.state.tab === "mail" && mailboxes.length < maxMail ? (
                <MDBInputGroup
                  type="text"
                  id="create-mail-input"
                  className="lowercase"
                  containerClassName="mb-3"
                  hint="Crea una nuova casella mail"
                  append={
                    <>
                      <span className="input-group-text">@{base}</span>
                      <MDBBtn
                        className="m-0 px-3 py-2 z-depth-0 btn-rhx"
                        color="yellow"
                        onClick={this.onCreateMailPress.bind(this)}
                      >
                        + Crea
                      </MDBBtn>
                    </>
                  }
                  onKeyPress={(event) => {
                    if (event.key === "Enter") this.onCreateMailPress();
                  }}
                />
              ) : null}
              {this.state.tab === "alias" ? (
                <MDBInputGroup
                  type="text"
                  className="lowercase"
                  id="create-alias-input"
                  containerClassName="mb-3"
                  hint="Crea un Alias"
                  append={
                    <>
                      <span className="input-group-text">@{base}</span>
                      <MDBBtn
                        className="m-0 px-3 py-2 z-depth-0 btn-rhx"
                        color="yellow"
                        onClick={this.onCreateAliasPress.bind(this)}
                      >
                        + Crea
                      </MDBBtn>
                    </>
                  }
                  onKeyPress={(event) => {
                    if (event.key === "Enter") this.onCreateAliasPress();
                  }}
                />
              ) : null}
            </MDBCol>
          ) : null}

          {this.state.tab === "mail" && mailboxes.length > 0 ? (
            <>
              {" "}
              <MDBCol md="4">
                <Input
                  id="mailbox-search-input"
                  onChange={this.setFilter}
                  value={this.state.mailboxesFilter}
                  name="mailboxesFilter"
                  placeholder="Cerca casella..."
                />
              </MDBCol>
              <MDBCol md="2">
                <MDBBtn
                  className="m-0 px-3 py-2 z-depth-0 btn-rhx"
                  color="yellow"
                  onClick={this.downloadCsv.bind(this)}
                >
                  <MDBIcon icon="file-download" className="mr-2" />
                  CSV
                </MDBBtn>
              </MDBCol>
            </>
          ) : null}
          {this.state.tab === "alias" && mail_aliases.length > 0 ? (
            <>
              <MDBCol md="4">
                <Input
                  id="mailbox-search-input"
                  onChange={this.setFilter}
                  value={this.state.aliasesFilter}
                  name="aliasesFilter"
                  placeholder="Cerca alias..."
                />
              </MDBCol>
              <MDBCol md="2">
                <MDBBtn
                  className="m-0 px-3 py-2 z-depth-0 btn-rhx"
                  color="yellow"
                  onClick={() => this.downloadCsv(true)}
                >
                  <MDBIcon icon="file-download" className="mr-2" />
                  CSV
                </MDBBtn>
              </MDBCol>
              <MDBCol md="4">
                <Input
                  id="mailbox-search-input"
                  onChange={this.setFilter}
                  value={this.state.aliasesMaildropFilter}
                  name="aliasesMaildropFilter"
                  placeholder="Cerca per destinatari..."
                />
              </MDBCol>
            </>
          ) : null}
        </MDBRow>
        {this.state.tab === "mail" ? (
          <MDBRow>
            <MDBCol md="12">
              <span className="mr-3">Caselle Mail:</span>
              <span className="mr-3">
                Create:{" "}
                <MDBBadge color="rgba-yellow-strong z-depth-0">
                  <strong className="text-dark">{mailboxes.length}</strong>
                </MDBBadge>
              </span>
              <span>
                {config.forPrivate !== true ? "Acquistate" : "MaxMail"}:{" "}
                <MDBBadge color="dark z-depth-0">
                  <strong className="text-white">{maxMail}</strong>
                </MDBBadge>
              </span>
            </MDBCol>
          </MDBRow>
        ) : null}
        {this.state.loaded && this.state.tab === "mail"
          ? this.renderMailboxes(this.filterMailboxes(mailboxes))
          : null}
        {this.state.loaded && this.state.tab === "alias"
          ? this.renderMailAliases(this.filterAliasesMaildrop(this.filterAliases(mail_aliases)))
          : null}
        {this.state.loaded && this.state.tab === "catch_all" ? (
          <CatchAllConfig />
        ) : null}
        {this.state.loaded && this.state.tab === "virtual_backup_mail" ? (
          <VirtualBackupMail refresh={this.refresh.bind(this)} />
        ) : null}
        {this.state.loaded && this.state.tab === "ftp" ? (
          <FTPConfig
            ftpUser={this.props.current_ftp_user}
            authType={auth_type}
            onSwitchChange={this.FTPSwitchChange.bind(this)}
            onSubmit={this.FTPSubmit.bind(this)}
            onInputChange={this.FTPInputChange.bind(this)}
            onDomainInputChange={this.domainInputChange.bind(this)}
            domain={this.props.current_domain}
            onDomainSwitchChange={this.domainSwitchChange.bind(this)}
          />
        ) : null}
        {this.state.loaded && this.state.tab === "dns" ? <DNS /> : null}
        {this.state.loaded && this.state.tab === "logs" ? (
          <Logs /*forDomain={true}*/ />
        ) : null}
      </main>
    );
  }
}
