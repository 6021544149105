/* eslint-disable camelcase */
import { handleActions } from "redux-actions";
import { combineReducers } from "redux";
import * as actions from "../actions";

const credentials_username = handleActions(
  {
    [actions.setCredentials](state, action) {
      return action.payload.login;
    },
    [actions.logoutSuccess](state, action) {
      return "";
    },
  },
  ""
);
const credentials_password = handleActions(
  {
    [actions.setCredentials](state, action) {
      return action.payload.password;
    },
    [actions.logoutSuccess](state, action) {
      return "";
    },
  },
  ""
);

const modify_mail_state = handleActions(
  {
    [actions.modifyMailboxSuccess](state, action) {
      return "success";
    },
    [actions.modifyMailboxFailure](state, action) {
      return "failure";
    },
    [actions.modifyMailboxRequest](state, action) {
      return "request";
    },
    [actions.setModifyMailboxState](state, action) {
      return action.payload.state;
    },
    [actions.logoutSuccess](state, action) {
      return "none";
    },
  },
  "none"
);

const get_logs_state = handleActions(
  {
    [actions.getLogsSuccess](state, action) {
      return "success";
    },
    [actions.getLogsFailure](state, action) {
      return "failure";
    },
    [actions.getLogsRequest](state, action) {
      return "request";
    },
    [actions.logoutSuccess](state, action) {
      return "none";
    },
  },
  "none"
);

const get_statistics_state = handleActions(
  {
    [actions.getStatisticsSuccess](state, action) {
      return "success";
    },
    [actions.getStatisticsFailure](state, action) {
      return "failure";
    },
    [actions.getStatisticsRequest](state, action) {
      return "request";
    },
    [actions.logoutSuccess](state, action) {
      return "none";
    },
  },
  "none"
);

const get_ban_logs_state = handleActions(
  {
    [actions.getBanLogsSuccess](state, action) {
      return "success";
    },
    [actions.getBanLogsFailure](state, action) {
      return "failure";
    },
    [actions.getBanLogsRequest](state, action) {
      return "request";
    },
    [actions.logoutSuccess](state, action) {
      return "none";
    },
  },
  "none"
);

const get_banned_ip_state = handleActions(
  {
    [actions.getBannedIPSuccess](state, action) {
      return "success";
    },
    [actions.getBannedIPFailure](state, action) {
      return "failure";
    },
    [actions.getBannedIPRequest](state, action) {
      return "request";
    },
    [actions.logoutSuccess](state, action) {
      return "none";
    },
  },
  "none"
);

const get_whitelist_ip_state = handleActions(
  {
    [actions.getWhiteListIPSuccess](state, action) {
      return "success";
    },
    [actions.getWhiteListIPFailure](state, action) {
      return "failure";
    },
    [actions.getWhiteListIPRequest](state, action) {
      return "request";
    },
    [actions.logoutSuccess](state, action) {
      return "none";
    },
  },
  "none"
);

const get_blacklist_ip_state = handleActions(
  {
    [actions.getBlackListIPSuccess](state, action) {
      return "success";
    },
    [actions.getBlackListIPFailure](state, action) {
      return "failure";
    },
    [actions.getBlackListIPRequest](state, action) {
      return "request";
    },
    [actions.logoutSuccess](state, action) {
      return "none";
    },
  },
  "none"
);

const get_virtual_backup_mail_state = handleActions(
  {
    [actions.getVirtualBackupMailSuccess](state, action) {
      return "success";
    },
    [actions.getVirtualBackupMailFailure](state, action) {
      return "failure";
    },
    [actions.getVirtualBackupMailRequest](state, action) {
      return "request";
    },
    [actions.getVirtualBackupMailState](state, action) {
      return action.payload.state;
    },
    [actions.logoutSuccess](state, action) {
      return "none";
    },
  },
  "none"
);

const get_dns_domain_state = handleActions(
  {
    [actions.getDNSDomainSuccess](state, action) {
      return "success";
    },
    [actions.getDNSDomainFailure](state, action) {
      return "failure";
    },
    [actions.getDNSDomainRequest](state, action) {
      return "request";
    },
    [actions.logoutSuccess](state, action) {
      return "none";
    },
  },
  "none"
);

const get_dns_zones_state = handleActions(
  {
    [actions.getDNSZonesSuccess](state, action) {
      return "success";
    },
    [actions.getDNSZonesFailure](state, action) {
      return "failure";
    },
    [actions.getDNSZonesRequest](state, action) {
      return "request";
    },
    [actions.logoutSuccess](state, action) {
      return "none";
    },
  },
  "none"
);

const modify_mail_alias_state = handleActions(
  {
    [actions.modifyMailAliasSuccess](state, action) {
      return "success";
    },
    [actions.modifyMailAliasFailure](state, action) {
      return "failure";
    },
    [actions.modifyMailAliasRequest](state, action) {
      return "request";
    },
    [actions.logoutSuccess](state, action) {
      return "none";
    },
  },
  "none"
);

const modify_banned_ip_state = handleActions(
  {
    [actions.modifyBannedIPSuccess](state, action) {
      return "success";
    },
    [actions.modifyBannedIPFailure](state, action) {
      return "failure";
    },
    [actions.modifyBannedIPRequest](state, action) {
      return "request";
    },
    [actions.logoutSuccess](state, action) {
      return "none";
    },
  },
  "none"
);

const modify_whitelist_ip_state = handleActions(
  {
    [actions.modifyWhiteListIPSuccess](state, action) {
      return "success";
    },
    [actions.modifyWhiteListIPFailure](state, action) {
      return "failure";
    },
    [actions.modifyWhiteListIPRequest](state, action) {
      return "request";
    },
    [actions.logoutSuccess](state, action) {
      return "none";
    },
  },
  "none"
);

const modify_blacklist_ip_state = handleActions(
  {
    [actions.modifyBlackListIPSuccess](state, action) {
      return "success";
    },
    [actions.modifyBlackListIPFailure](state, action) {
      return "failure";
    },
    [actions.modifyBlackListIPRequest](state, action) {
      return "request";
    },
    [actions.logoutSuccess](state, action) {
      return "none";
    },
  },
  "none"
);

const create_mail_state = handleActions(
  {
    [actions.createMailboxSuccess](state, action) {
      return "success";
    },
    [actions.createMailboxFailure](state, action) {
      return "failure";
    },
    [actions.createMailboxRequest](state, action) {
      return "request";
    },
    [actions.setCreateMailboxState](state, action) {
      return action.payload.state;
    },
    [actions.logoutSuccess](state, action) {
      return "none";
    },
  },
  "none"
);

const get_mail_state = handleActions(
  {
    [actions.getMailboxSuccess](state, action) {
      return "success";
    },
    [actions.getMailboxFailure](state, action) {
      return "failure";
    },
    [actions.getMailboxRequest](state, action) {
      return "request";
    },
    [actions.logoutSuccess](state, action) {
      return "none";
    },
  },
  "none"
);

const get_domains_state = handleActions(
  {
    [actions.getDomainsSuccess](state, action) {
      return "success";
    },
    [actions.getDomainsFailure](state, action) {
      return "failure";
    },
    [actions.getDomainsRequest](state, action) {
      return "request";
    },
    [actions.logoutSuccess](state, action) {
      return "none";
    },
  },
  "none"
);

const delete_mail_state = handleActions(
  {
    [actions.deleteMailboxSuccess](state, action) {
      return "success";
    },
    [actions.deleteMailboxFailure](state, action) {
      return "failure";
    },
    [actions.deleteMailboxRequest](state, action) {
      return "request";
    },
    [actions.setDeleteMailboxState](state, action) {
      return action.payload.state;
    },
    [actions.logoutSuccess](state, action) {
      return "none";
    },
  },
  "none"
);

const delete_domain_state = handleActions(
  {
    [actions.deleteDomainSuccess](state, action) {
      return "success";
    },
    [actions.deleteDomainFailure](state, action) {
      return "failure";
    },
    [actions.deleteDomainRequest](state, action) {
      return "request";
    },
    [actions.setDeleteDomainState](state, action) {
      return action.payload.state;
    },
    [actions.logoutSuccess](state, action) {
      return "none";
    },
  },
  "none"
);

const auth_state = handleActions(
  {
    [actions.authSuccess](state, action) {
      return "success";
    },
    [actions.authFailure](state, action) {
      return "failure";
    },
    [actions.authRequest](state, action) {
      return "request";
    },
    [actions.setAuthState](state, action) {
      return "none";
    },
    [actions.logoutSuccess](state, action) {
      return "none";
    },
  },
  "none"
);

const modify_ftp_user_state = handleActions(
  {
    [actions.modifyFTPUserSuccess](state, action) {
      return "success";
    },
    [actions.modifyFTPUserFailure](state, action) {
      return "failure";
    },
    [actions.modifyFTPUserRequest](state, action) {
      return "request";
    },
    [actions.logoutSuccess](state, action) {
      return "none";
    },
  },
  "none"
);

const modify_domain_state = handleActions(
  {
    [actions.modifyDomainSuccess](state, action) {
      return "success";
    },
    [actions.modifyDomainRequest](state, action) {
      return "request";
    },
    [actions.modifyDomainFailure](state, action) {
      return "failure";
    },

    [actions.setModifyDomainState](state, action) {
      return action.payload.state;
    },
    [actions.logoutSuccess](state, action) {
      return "none";
    },
  },
  "none"
);

const current_mailbox = handleActions(
  {
    [actions.getMailboxSuccess](state, action) {
      return action.payload.mailbox;
    },
    [actions.setCurrentMail](state, action) {
      return action.payload.mailbox;
    },
    [actions.logoutSuccess](state, action) {
      return {};
    },
  },
  {}
);

const current_mail_alias = handleActions(
  {
    [actions.getMailAliasSuccess](state, action) {
      return action.payload.mailAlias;
    },
    [actions.setCurrentMailAlias](state, action) {
      return action.payload.mailAlias;
    },
    [actions.logoutSuccess](state, action) {
      return {};
    },
  },
  {}
);

const current_mailbox_smtpAuth = handleActions(
  {
    [actions.getMailboxSuccess](state, action) {
      return action.payload.mailbox.smtpAuth;
    },
    [actions.setCurrentMail](state, action) {
      return action.payload.mailbox.smtpAuth;
    },
    [actions.logoutSuccess](state, action) {
      return {};
    },
  },
  {}
);

const current_ftp_user = handleActions(
  {
    [actions.getFTPUserSuccess](state, action) {
      return action.payload.ftpUser;
    },
    [actions.getFTPUserFailure](state, action) {
      return {};
    },
    [actions.setCurrentFTPUser](state, action) {
      return action.payload.ftpUser;
    },
    [actions.logoutSuccess](state, action) {
      return {};
    },
  },
  {}
);

const current_domain = handleActions(
  {
    [actions.getDomainSuccess](state, action) {
      return action.payload.domain;
    },
    [actions.setCurrentDomain](state, action) {
      return action.payload.domain;
    },
    [actions.logoutSuccess](state, action) {
      return "";
    },
  },
  ""
);

const logsView = handleActions(
  {
    [actions.getLogsViewSuccess](state, action) {
      return action.payload.logsView;
    },
    [actions.getLogsViewFailure](state, action) {
      return "FAILED";
    },
    [actions.setLogsView](state, action) {
      return action.payload.logsView;
    },
    [actions.logoutSuccess](state, action) {
      return "LOGOUT";
    },
  },
  ""
);

const current_virtual_backup_mail = handleActions(
  {
    [actions.getVirtualBackupMailSuccess](state, action) {
      return action.payload.virtualBackupMail;
    },
    [actions.deleteVirtualBackupMailSuccess](state, action) {
      return {};
    },
    [actions.getVirtualBackupMailFailure](state, action) {
      return {};
    },
    [actions.logoutSuccess](state, action) {
      return {};
    },
  },
  {}
);

const mailboxes = handleActions(
  {
    [actions.getMailboxesSuccess](state, action) {
      return action.payload.mailboxes.slice().sort((a, b) => {
        if (a.mail < b.mail) return -1;
        if (a.mail > b.mail) return 1;
        return 0;
      });
    },
    [actions.logoutSuccess](state, action) {
      return [];
    },
  },
  []
);

const mail_aliases = handleActions(
  {
    [actions.getMailAliasesSuccess](state, action) {
      return action.payload.mailAliases.slice().sort((a, b) => {
        if (a.mail < b.mail) return -1;
        if (a.mail > b.mail) return 1;
        return 0;
      });
    },
    [actions.logoutSuccess](state, action) {
      return [];
    },
  },
  []
);

const domains = handleActions(
  {
    [actions.getDomainsSuccess](state, action) {
      return action.payload.domains;
    },
    [actions.setDomains](state, action) {
      return action.payload.domains;
    },
    [actions.logoutSuccess](state, action) {
      return [];
    },
  },
  []
);

const banned_ip = handleActions(
  {
    [actions.getBannedIPSuccess](state, action) {
      return action.payload.banned_ip;
    },
    [actions.logoutSuccess](state, action) {
      return [];
    },
  },
  []
);

const banned_ip_count = handleActions(
  {
    [actions.getBannedIPSuccess](state, action) {
      return action.payload.count;
    },
    [actions.logoutSuccess](state, action) {
      return 0;
    },
  },
  []
);

const backup_db = handleActions(
  {
    [actions.getBannedIPSuccess](state, action) {
      return action.payload.backup_db;
    },
    [actions.logoutSuccess](state, action) {
      return 0;
    },
  },
  []
);

const whitelist_ip = handleActions(
  {
    [actions.getWhiteListIPSuccess](state, action) {
      return action.payload.whitelist_ip;
    },
    [actions.logoutSuccess](state, action) {
      return [];
    },
  },
  []
);

const blacklist_ip = handleActions(
  {
    [actions.getBlackListIPSuccess](state, action) {
      return action.payload.blacklist_ip;
    },
    [actions.logoutSuccess](state, action) {
      return [];
    },
  },
  []
);

const filtred_domains = handleActions(
  {
    [actions.getDomainsSuccess](state, action) {
      return action.payload.domains.slice().sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
    },
    [actions.setFiltredDomains](state, action) {
      return action.payload.filtred_domains.slice().sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
    },
    [actions.setDomains](state, action) {
      return action.payload.domains.slice().sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
    },
    [actions.logoutSuccess](state, action) {
      return [];
    },
  },
  []
);

const logs = handleActions(
  {
    [actions.getLogsSuccess](state, action) {
      return action.payload.logs;
    },
    [actions.logoutSuccess](state, action) {
      return [];
    },
  },
  []
);

const statistics = handleActions(
  {
    [actions.getStatisticsSuccess](state, action) {
      return action.payload;
    },
    [actions.logoutSuccess](state, action) {
      return {};
    },
  },
  []
);

const ban_logs = handleActions(
  {
    [actions.getBanLogsSuccess](state, action) {
      return action.payload.logs;
    },
    [actions.logoutSuccess](state, action) {
      return [];
    },
  },
  []
);

const logs_filters = handleActions(
  {
    [actions.setLogsFilters](state, action) {
      return action.payload.filters;
    },
    [actions.logoutSuccess](state, action) {
      return {};
    },
  },
  {}
);

const logs_limit = handleActions(
  {
    [actions.setLogsLimit](state, action) {
      return action.payload.limit;
    },
    [actions.logoutSuccess](state, action) {
      return 100;
    },
  },
  100
);

const auth_domain = handleActions(
  {
    [actions.authSuccess](state, action) {
      return action.payload.domain;
    },
    [actions.getCurrentSessionSuccess](state, action) {
      return action.payload.domain;
    },
    [actions.logoutSuccess](state, action) {
      return "";
    },
  },
  ""
);

const auth_username = handleActions(
  {
    [actions.authSuccess](state, action) {
      return action.payload.username;
    },
    [actions.getCurrentSessionSuccess](state, action) {
      return action.payload.username;
    },
    /* [actions.logoutSuccess](state, action) {
    return action.payload.domain;
  }, */
    [actions.logoutSuccess](state, action) {
      return "";
    },
  },
  ""
);
const auth_type = handleActions(
  {
    [actions.authSuccess](state, action) {
      return action.payload.type;
    },
    [actions.getCurrentSessionSuccess](state, action) {
      return action.payload.type;
    },
    [actions.logoutSuccess](state, action) {
      return "";
    },
  },
  ""
);

const current_dns_domain = handleActions(
  {
    [actions.getDNSDomainSuccess](state, action) {
      return action.payload.domain;
    },
    [actions.getDNSDomainFailure](state, action) {
      return action.payload.domain;
    },
  },
  {}
);

const current_dns_zones = handleActions(
  {
    [actions.getDNSZonesSuccess](state, action) {
      return action.payload.zones;
    },
    [actions.getDNSZonesFailure](state, action) {
      return action.payload.zones;
    },
  },
  []
);

export default combineReducers({
  current_dns_domain,
  current_dns_zones,
  get_dns_domain_state,
  get_dns_zones_state,
  get_ban_logs_state,
  get_logs_state,
  domains,
  auth_domain,
  auth_type,
  auth_username,
  credentials_username,
  modify_domain_state,
  credentials_password,
  mailboxes,
  mail_aliases,
  filtred_domains,
  current_mailbox,
  current_domain,
  logsView,
  whitelist_ip,
  blacklist_ip,
  current_mail_alias,
  current_virtual_backup_mail,
  get_virtual_backup_mail_state,
  modify_mail_state,
  modify_mail_alias_state,
  delete_domain_state,
  modify_ftp_user_state,
  current_mailbox_smtpAuth,
  auth_state,
  logs,
  ban_logs,
  current_ftp_user,
  logs_filters,
  logs_limit,
  create_mail_state,
  delete_mail_state,
  get_mail_state,
  get_domains_state,
  get_banned_ip_state,
  banned_ip,
  banned_ip_count,
  backup_db,
  modify_banned_ip_state,
  get_whitelist_ip_state,
  modify_whitelist_ip_state,
  get_blacklist_ip_state,
  modify_blacklist_ip_state,
  statistics,
  get_statistics_state
});
