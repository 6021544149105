/* eslint-disable react/no-string-refs */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-plusplus */
/* eslint-disable radix */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable quote-props */
/* eslint-disable react/prop-types */

import React from "react";
import Multiselect from "react-widgets/lib/Multiselect";
import moment from "moment";
import { MDBBtn, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import momentLocalizer from "react-widgets-moment";
import { GbToBytes, bytesToMb, bytesToGb } from "../utils";
import Input from "./common/Input.jsx";
import Switch from "./common/Switch.jsx";
import proxyData from "../config/proxy_data";
import NavTab from "./common/NavTab.jsx";
import config from "../config";
import SpamProtectionInput from "./common/SpamProtectionRange.jsx";
import spamMap from "../config/spam_map";

moment.locale("it");
momentLocalizer();

const { Address4 } = require("ip-address");

export default class MailConfig extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "standard",
      loaded: "none",
      selected: [],
      proxyWebmail: [],
      proxyProtocols: [],
      proxyAllowedIP: [],
      refresh: 0,
      description: config.ftpDescriptionRoles,
    };
  }

  componentDidMount = async () => {
    var { base } = this.props.match.params;
    const [username, domain] = base.split("@");
    const minPostmaster = this.isMinPostmaster();
    await this.props.getMailbox([base, minPostmaster]);
    if (minPostmaster) await this.props.getDomain(domain);
    this.setState({ loaded: "success" });
  };

  isMinPostmaster = () =>
    this.props.auth_type === "admin" || this.props.auth_type === "postmaster";

  onSwitchChange = (e) => {
    const inverseOptions = [
      "amavisBypassVirusChecks",
      "amavisBypassSpamChecks",
      "bypassGreyListing",
    ];
    let { checked, id } = e.currentTarget;
    const [mail, switchName] = id.split("&_");
    if (inverseOptions.includes(switchName)) checked = !checked;
    const mailbox = this.props.current_mailbox;
    mailbox[switchName] = checked;
    this.props.setCurrentMail({ mailbox });
    //Needed for mail forward address field
    this.setState({ refresh: this.state.refresh + 1 });
  };

  onInputChange = (e) => {
    const { value, id } = e.currentTarget;
    const [mail, inputName] = id.split("£_");

    const mailbox = this.props.current_mailbox;
    mailbox[inputName] = value;
    this.props.setCurrentMail({ mailbox });
  };

  onSelectChange = (e) => {
    const { value, id } = e.currentTarget;
    const [selectName] = id.split("£_");
    const mailbox = this.props.current_mailbox;
    mailbox[selectName] = value;
    this.props.setCurrentMail({ mailbox });
  };

  onClickTabsButton = (e) => {
    const { id } = e.currentTarget;
    const [tab] = id.split("_");
    const buttons = document.querySelectorAll(".logs_button");
    buttons.forEach((button) => button.classList.remove("is-active"));
    document.getElementById(id).classList.add("is-active");
    this.setState({ tab });
  };

  proxyContinentsChange = (value) => {
    const { current_mailbox } = this.props;
    current_mailbox.proxyFilters.allowed_continents = value.map(
      (item) => item.valueField
    );
    this.props.setCurrentMail({ mailbox: current_mailbox });
  };

  proxyProtocolsChange = (e) => {
    const { current_mailbox } = this.props;
    const [protocol] = e.currentTarget.id.split("£_");
    const { checked } = e.currentTarget;
    const current_allowed = current_mailbox.proxyFilters.allowed_protocols;
    if (!checked) {
      current_mailbox.proxyFilters.allowed_protocols = current_allowed.filter(
        (item) => item !== protocol
      );
    } else {
      current_mailbox.proxyFilters.allowed_protocols = Array.from(
        new Set([...current_allowed, protocol])
      );
    }
    this.props.setCurrentMail({ mailbox: current_mailbox });
  };

  proxyWebmailChange = (e) => {
    const { checked } = e.currentTarget;
    const mailbox = this.props.current_mailbox;
    mailbox.proxyFilters.webmail = checked ? 1 : 0;
    this.props.setCurrentMail({ mailbox });
  };

  onCreateIPItem = (name) => {
    const address = new Address4(name);
    if (address.isValid()) {
      const allowedIP = this.state.proxyAllowedIP;
      this.setState({ proxyAllowedIP: [...allowedIP, name] });
    }
  };

  proxyAllowedIPChange = (value) => {
    const mailbox = this.props.current_mailbox;
    mailbox.proxyFilters.allowed_ip = value;
    this.props.setCurrentMail({ mailbox });
  };

  descriptionChange = value =>  {
    const mailbox = this.props.current_mailbox;
    mailbox.description = value;
    this.props.setCurrentMail({ mailbox });
  };

  onCreateDescription = value => {
    const description = this.state.description;
    this.setState({ description: [...description, value] });
  };

  proxyDeniedWeekDaysChange = (value) => {
    const mailbox = this.props.current_mailbox;
    mailbox.proxyFilters.allowed_dates.deniedWeekDays = value.map(
      (item) => item.valueField
    );
    this.props.setCurrentMail({ mailbox });
  };

  proxyHolidaysChange = (e) => {
    const { checked } = e.currentTarget;
    const mailbox = this.props.current_mailbox;
    mailbox.proxyFilters.allowed_dates.isDeniedHoliday = !checked;
    this.props.setCurrentMail({ mailbox });
  };

  proxyWeekendsChange = (e) => {
    const { checked } = e.currentTarget;
    const mailbox = this.props.current_mailbox;
    mailbox.proxyFilters.allowed_dates.isDeniedWeekend = !checked;
    this.props.setCurrentMail({ mailbox });
  };

  fromRangeDateChange = (value) => {
    const mailbox = this.props.current_mailbox;
    const { deniedRange } = mailbox.proxyFilters.allowed_dates;
    deniedRange.from = moment(value).format("YYYY-MM-DD HH:mm:ss");
    this.props.setCurrentMail({ mailbox });
  };

  toRangeDateChange = (value) => {
    const mailbox = this.props.current_mailbox;
    const { deniedRange } = mailbox.proxyFilters.allowed_dates;
    deniedRange.to = moment(value).format("YYYY-MM-DD HH:mm:ss");
    this.props.setCurrentMail({ mailbox });
  };

  fromOutWorkHoursChange = (value) => {
    const mailbox = this.props.current_mailbox;
    const { deniedOutWorkHours } = mailbox.proxyFilters.allowed_dates;
    deniedOutWorkHours.from = moment(value).format("YYYY-MM-DD HH:mm:ss");
    this.props.setCurrentMail({ mailbox });
  };

  toOutWorkHoursChange = (value) => {
    const mailbox = this.props.current_mailbox;
    const { deniedOutWorkHours } = mailbox.proxyFilters.allowed_dates;
    deniedOutWorkHours.to = moment(value).format("YYYY-MM-DD HH:mm:ss");
    this.props.setCurrentMail({ mailbox });
  };

  onSubmitProxy = async (e) => {
    e.preventDefault();
    const { mail, proxyFilters } = this.props.current_mailbox;
    proxyFilters.updatedAt = moment().unix();
    await this.props.modifyMailbox({
      mail,
      options: { proxyFilters: JSON.stringify(proxyFilters) },
    });
    this.props.getMailbox(this.props.current_mailbox.mail);
  };

  onSubmitConfig = async (e) => {
    e.preventDefault();
    const options = {};

    if (this.isMinPostmaster()) {
      options.amavisBypassVirusChecks = !document.getElementById(
        "mail_config&_amavisBypassVirusChecks&_sw"
      ).checked;
      options.accountActive = document.getElementById(
        "mail_config&_accountActive&_sw"
      ).checked;
      options.bypassGreyListing = !document.getElementById(
        "mail_config&_bypassGreyListing&_sw"
      ).checked;
      options.smtpAuth = document.getElementById(
        "mail_config&_smtpAuth&_sw"
      ).checked;
      options.amavisBypassSpamChecks = !document.getElementById(
        "mail_config&_amavisBypassSpamChecks&_sw"
      ).checked;
      if (document.getElementById("quota£_select").value)
        options.quota = document.getElementById("quota£_select").value;
      if (document.getElementById("ftp£_FTPQuotaMBytes").value){
        options.FTPQuotaMBytes = document.getElementById("ftp£_FTPQuotaMBytes").value*1024;
      }
    }

    const password = document.getElementById("mail_config£_passwd").value;
    const conf_password = document.getElementById("mail_config£_conf_password")
      .value;
    const givenName = document.getElementById("mail_config£_givenName").value;
    const sn = document.getElementById("mail_config£_sn").value;
    var mobile;
    var mailLocalAddress;
    if(config.optionalInfo.toLowerCase()==="true") {
      mobile = document.getElementById("mail_config£_mobile").value;

      //mailLocalAddress = document.getElementById("mail_config£_mailLocalAddress").value;
    }

    /* Uso cn attuale per modificarlo se cambia gn o sn , altrimenti rimane il valore vecchio. */
    const { cn } = this.props.current_mailbox;
    let [currGN, currSN] = cn.trim().split(" ");
    if (password) options.userPassword = password;
    if (conf_password) options.conf_password = conf_password;
    if (givenName && givenName.trim() !== "") {
      options.givenName = givenName;
      currGN = givenName;
      options.cn = `${currGN} ${currSN}`;
    }
    if (sn && sn.trim() !== "") {
      options.sn = sn;
      currSN = sn;
      options.cn = `${currGN} ${currSN}`;
    }

    let spamProtectionValue = document.getElementById("spamProtectionInput").value;
    let spamAttributes = [
      "amavisSpamKillLevel",
      "amavisSpamTagLevel",
      "amavisSpamTag2Level",
    ];

    for (const attribute of spamAttributes) {
      options[attribute] = spamMap.attribute[attribute][spamProtectionValue];
    }
    if(config.forward_feature !== "disabled") {
      options.forwardActive = document.getElementById(
        "mail_config&_forwardActive&_sw"
      ).checked;
    }
    if (options.forwardActive === true) {
      options.maildrop = document.getElementById("mail_config£_maildrop").value;
    }

    if(this.props.current_mailbox.objectClass.includes('PureFTPdUser')){
      options.description = this.props.current_mailbox.description;
    }

    if(mobile) options.mobile = mobile;
    //if(mailLocalAddress) options.mailLocalAddress = mailLocalAddress;

    await this.props.modifyMailbox({
      mail: this.props.current_mailbox.mail,
      options,
    });
    this.props.getMailbox([this.props.current_mailbox.mail,this.isMinPostmaster()]);
    if (this.isMinPostmaster())
      this.props.getDomain(this.props.current_domain.name);
  };

  renderSpamKillSelect = (currentValue) => {
    const values = [
      { value: "10.0", text: "Low(10.0)", selected: false },
      { value: "6.0", text: "Medium(6.0)", selected: false },
      { value: "4.5", text: "High(4.5)", selected: false },
      { value: "3.0", text: "Very High(3.0)", selected: false },
    ];
    return (
      <div>
        <label>Spam Kill Level</label>
        <select
          className="browser-default custom-select"
          id="amavisSpamKillLevel£_select"
          onChange={this.onSelectChange}
        >
          {values.map((item) => (
            <option
              key={item.value}
              value={item.value}
              selected={item.value === currentValue}
            >
              {item.text}
            </option>
          ))}
        </select>
      </div>
    );
  };

  renderSpamTagSelect = (currentValue) => {
    const values = [
      { value: "7.0", text: "Low(7.0)", selected: false },
      { value: "3.0", text: "Medium(3.0)", selected: false },
      { value: "1.5", text: "High(1.5)", selected: false },
      { value: "0.0", text: "Very High(0.0)", selected: false },
    ];
    return (
      <div>
        <label htmlFor="amavisSpamTagLevel£_select">Spam Tag Level</label>
        <select
          className="browser-default custom-select"
          id="amavisSpamTagLevel£_select"
          onChange={this.onSelectChange}
        >
          {values.map((item) => (
            <option
              key={item.value}
              value={item.value}
              selected={item.value === currentValue}
            >
              {item.text}
            </option>
          ))}
        </select>
      </div>
    );
  };

  renderSpamTag2Select = (currentValue) => {
    const values = [
      { value: "9.5", text: "Low(9.5)", selected: false },
      { value: "5.5", text: "Medium(5.5)", selected: false },
      { value: "4.0", text: "High(4.0)", selected: false },
      { value: "2.5", text: "Very High(2.5)", selected: false },
    ];
    return (
      <div>
        <label>Spam Tag2 Level</label>
        <select
          className="browser-default custom-select"
          id="amavisSpamTag2Level£_select"
          onChange={this.onSelectChange}
        >
          {values.map((item) => (
            <option
              key={item.value}
              value={item.value}
              selected={item.value === currentValue}
            >
              {item.text}
            </option>
          ))}
        </select>
      </div>
    );
  };

  renderQuotaSelect = (maxQuotaGb, currentQuota, quotausage) => {
    const { defaultQuota, expansionSlot } = config;
    let { maxLimitQuota } = config;
    let calcMaxLimitQuota = defaultQuota/1024/1024/1024*1000; //BEFORE 3000
    const QUOTA_MAX_LIMIT = maxLimitQuota > calcMaxLimitQuota ? maxLimitQuota : calcMaxLimitQuota;
    const QUOTA_MIN_LIMIT = defaultQuota/1024/1024/1024; //BEFORE 3
    // se è 0 o -1 significa che user non ha nessuna espansione quindi non gli mostro il select.
    if(!maxQuotaGb) maxQuotaGb=0; // Se max quota non è impostato viene considerato come 0.
    const maxQuotaToUse = maxQuotaGb > QUOTA_MAX_LIMIT ? QUOTA_MAX_LIMIT : maxQuotaGb;
    /*
      isDisabled determina se l'item della lista di espansione deve essere disabilitato
      value = valore item,
      currValue = quota nominativa casella (senza considerare quota base),
      usedQuota = sommatoria quote nominative dominio (senza considerare quota base),
      maxQuotaGb = quota massima impostata per il dominio
    */
    var free;
    const isDisabled = (value, currValue) => {
    const { usedQuota } = this.props.current_domain;
    if(!free) free = GbToBytes(maxQuotaToUse) - (usedQuota - currValue);
    return free < value && currValue < value;
    };
    if (maxQuotaGb < QUOTA_MIN_LIMIT) {
      // Se forPrivate è false visualizzo la quota utilizzata e il select disabilitato (no espansioni disponibili)
      if (config.forPrivate !== true) {
        return (
          <div>
            <label>
              { 
                //Se quotausage non è impostato correttamente visualizzo solo la quota della casella.
                isNaN(parseInt(quotausage)) ?
                <p className="font-weight-light">
                  Quota: {Math.round(bytesToGb(parseInt(currentQuota)))}GB
                </p>
                :
                Math.round((quotausage/currentQuota)*100)>=80?
                  Math.round((quotausage/currentQuota)*100)>=90?
                    <>
                      <p className="font-weight-light">
                        Quota: {Math.round(bytesToGb(parseInt(quotausage))*100)/100}GB / {Math.round(bytesToGb(parseInt(currentQuota)))}GB (
                        <span className="red-text">
                          uso {Math.round((quotausage/currentQuota)*100)}% 
                        </span>
                          )
                      </p>
                    </>
                    :
                    <>
                      <p className="font-weight-light">
                        Quota: {Math.round(bytesToGb(parseInt(quotausage))*100)/100}GB / {Math.round(bytesToGb(parseInt(currentQuota)))}GB (
                        <span className="orange-text">
                          uso {Math.round((quotausage/currentQuota)*100)}% 
                        </span>
                          )
                      </p>
                    </>
                  :
                  <p className="font-weight-light">
                    Quota: {Math.round(bytesToGb(parseInt(quotausage))*100)/100}GB / {Math.round(bytesToGb(parseInt(currentQuota)))}GB (uso {Math.round((quotausage/currentQuota)*100)}%)
                  </p>
              }
            </label>
            <select
              className="browser-default custom-select"
              id="quota£_select"
            >
              <option value="" selected disabled={true}>
                Espansioni non disponibili
              </option>
            </select>
          </div>
        );
      }
    }
    const options = Math.round(parseInt(maxQuotaToUse)) / (expansionSlot/1024/1024/1024) + 1;
    const curr = parseInt(currentQuota);
    const values = [];
    for (let i = 1; i <= options; i++) {
      if (i === 1) values[i] = defaultQuota;
      else {
        values[i] = values[i - 1] + expansionSlot;
      }
    }

    const items = values.map((item) => ({
      value: item,
      text: `${bytesToGb(item)}GB (${bytesToMb(item)}MB)`,
    }));
    return (
      <div>
        <label>{ 
                //Se quotausage non è impostato correttamente visualizzo solo la quota della casella.
                isNaN(parseInt(quotausage)) ?
                <p className="font-weight-light">
                  Quota: {Math.round(bytesToGb(parseInt(currentQuota)))}GB
                </p>
                :
                Math.round((quotausage/currentQuota)*100)>=80?
                  Math.round((quotausage/currentQuota)*100)>=90?
                    <>
                      <p className="font-weight-light">
                        Quota: {Math.round(bytesToGb(parseInt(quotausage))*100)/100}GB / {Math.round(bytesToGb(parseInt(currentQuota)))}GB (
                        <span className="red-text">
                          uso {Math.round((quotausage/currentQuota)*100)}% 
                        </span>
                          )
                      </p>
                    </>
                    :
                    <>
                      <p className="font-weight-light">
                        Quota: {Math.round(bytesToGb(parseInt(quotausage))*100)/100}GB / {Math.round(bytesToGb(parseInt(currentQuota)))}GB (
                        <span className="orange-text">
                          uso {Math.round((quotausage/currentQuota)*100)}% 
                        </span>
                          )
                      </p>
                    </>
                  :
                  <p className="font-weight-light">
                    Quota: {Math.round(bytesToGb(parseInt(quotausage))*100)/100}GB / {Math.round(bytesToGb(parseInt(currentQuota)))}GB (uso {Math.round((quotausage/currentQuota)*100)}%)
                  </p>
              }</label>
        <select
          className="browser-default custom-select"
          id="quota£_select"
          onChange={this.onSelectChange}
        >
          {items.map((item) => (
            <option
              key={`${item.value}quota`}
              value={item.value}
              disabled={isDisabled(
                item.value - defaultQuota,
                currentQuota - defaultQuota
              )}
              selected={item.value === curr}
            >
              {item.text}
            </option>
          ))}
        </select>
      </div>
    );
  };

  renderQuotaUsage = (currentQuota, quotausage) => {
    // Se forPrivate è false visualizzo la quota utilizzata
    if (config.forPrivate !== true) {
      return (
        <div>
          <label>
            { 
              //Se quotausage non è impostato correttamente visualizzo solo la quota della casella.
              isNaN(parseInt(quotausage)) ?
              <p className="font-weight-light">
                Quota: {Math.round(bytesToGb(parseInt(currentQuota)))}GB
              </p>
              :
              Math.round((quotausage/currentQuota)*100)>=80?
                Math.round((quotausage/currentQuota)*100)>=90?
                  <>
                    <p className="font-weight-light">
                      Quota: {Math.round(bytesToGb(parseInt(quotausage))*100)/100}GB / {Math.round(bytesToGb(parseInt(currentQuota)))}GB (
                      <span className="red-text">
                        uso {Math.round((quotausage/currentQuota)*100)}% 
                      </span>
                        )
                    </p>
                  </>
                  :
                  <>
                    <p className="font-weight-light">
                      Quota: {Math.round(bytesToGb(parseInt(quotausage))*100)/100}GB / {Math.round(bytesToGb(parseInt(currentQuota)))}GB (
                      <span className="orange-text">
                        uso {Math.round((quotausage/currentQuota)*100)}% 
                      </span>
                        )
                    </p>
                  </>
                :
                <p className="font-weight-light">
                  Quota: {Math.round(bytesToGb(parseInt(quotausage))*100)/100}GB / {Math.round(bytesToGb(parseInt(currentQuota)))}GB (uso {Math.round((quotausage/currentQuota)*100)}%)
                </p>
            }
          </label>
        </div>
      );
    }
  };

  renderSwitches = (mailbox) => (
    <div>
      <Switch
        id={"mail_config&_accountActive&_sw"}
        disabled={!this.isMinPostmaster()}
        onChange={this.onSwitchChange}
        defaultChecked={mailbox.accountActive}
        label="Attivo"
        className="mb-3 custom-control-inline"
      />
      <Switch
        id={"mail_config&_smtpAuth&_sw"}
        disabled={!(this.isMinPostmaster() && (mailbox.allowedsmtpauth || mailbox.smtpAuth))}
        onChange={this.onSwitchChange}
        defaultChecked={mailbox.smtpAuth}
        label="SmtpAuth"
        className="mb-3 custom-control-inline"
      />
      <Switch
        disabled={!(this.isMinPostmaster() && (mailbox.allowedprotection || !mailbox.amavisBypassVirusChecks))}
        id={"mail_config&_amavisBypassVirusChecks&_sw"}
        onChange={this.onSwitchChange}
        defaultChecked={!mailbox.amavisBypassVirusChecks}
        label="Antivirus"
        className="mb-3 custom-control-inline"
      />
      <Switch
        id={"mail_config&_amavisBypassSpamChecks&_sw"}
        disabled={!this.isMinPostmaster()}
        onChange={this.onSwitchChange}
        defaultChecked={!mailbox.amavisBypassSpamChecks}
        label="AntiSPAM"
        className="mb-3 custom-control-inline"
      />
      <Switch
        id={"mail_config&_bypassGreyListing&_sw"}
        disabled={!this.isMinPostmaster()}
        onChange={this.onSwitchChange}
        defaultChecked={!mailbox.bypassGreyListing}
        label="Greylist"
        className="mb-3 custom-control-inline"
      />
    </div>
  );

  renderProxyBody = (mailbox) => (
    <>
      <form onSubmit={this.onSubmitProxy}>
        <h5 className="mt-3">
          Limita l'accesso alla casella secondo le seguenti regole:
        </h5>
        <MDBRow className="mb-2">
          <MDBCol md="8">
            <label>Aree geografiche, consenti l'accesso solo da:</label>
            <Multiselect
              data={proxyData.continents.concat(proxyData.countries)}
              valueField="valueField"
              textField="textField"
              defaultValue={mailbox.proxyFilters.allowed_continents}
              onChange={this.proxyContinentsChange}
            />
            <small>
              Gli accessi sono consentiti dalle aree geografiche presenti nella lista.
              <strong>Se lasciato vuoto è possibile accedere da qualsiasi area geografica.</strong>
            </small>
          </MDBCol>
          <MDBCol md="4" lg="3" className="offset-lg-1 d-none d-md-flex">
            <img
              className="img-fluid"
              alt="Mappa dei continenti"
              src="/fonts/continents.png"
            ></img>
          </MDBCol>
        </MDBRow>
        <MDBRow className="mb-2">
          <MDBCol md="8">
            <label>IP WhiteList</label>
            <Multiselect
              data={this.state.proxyAllowedIP}
              defaultValue={mailbox.proxyFilters.allowed_ip}
              allowCreate="onFilter"
              onCreate={(name) => this.onCreateIPItem(name)}
              onChange={(value) => this.proxyAllowedIPChange(value)}
            />
            <small className="grey-text font-weight-light">
              Gli indirizzi IP indicati saranno ignorati dal filtro delle Aree geografiche.
            </small>
          </MDBCol>
        </MDBRow>

        <hr />
        <h5>Protocolli abilitati</h5>
        <MDBRow className="mb-2">
          <MDBCol md="8" className="pb-3">
            <div className="d-flex h-100">
              <div className="justify-content-center align-self-center">
                <Switch
                  label="Webmail"
                  id="proxy_webmail_sw"
                  className="custom-control-inline"
                  onChange={this.proxyWebmailChange}
                  defaultChecked={mailbox.proxyFilters.webmail === 1}
                />
                <Switch
                  label="IMAP"
                  id="imap£_sw"
                  className="custom-control-inline"
                  onChange={this.proxyProtocolsChange}
                  defaultChecked={mailbox.proxyFilters.allowed_protocols.includes(
                    "imap"
                  )}
                />
                <Switch
                  label="POP3"
                  id="pop3£_sw"
                  className="custom-control-inline"
                  onChange={this.proxyProtocolsChange}
                  defaultChecked={mailbox.proxyFilters.allowed_protocols.includes(
                    "pop3"
                  )}
                />
              </div>
            </div>
            <small className="grey-text font-weight-light">
              La casella sarà accessibile solo dai protocolli indicati
              (indicarne almeno uno)
            </small>
          </MDBCol>
        </MDBRow>
        <hr />
        <h5>Blocca gli accessi in date e orari specifici</h5>
        <MDBRow className="mb-2">
          <MDBCol md="8">
            <label>Giorni della settimana</label>
            <Multiselect
              data={proxyData.daysOfWeek}
              defaultValue={proxyData.daysOfWeek.filter((item) =>
                mailbox.proxyFilters.allowed_dates.deniedWeekDays.includes(
                  item.valueField
                )
              )}
              valueField="valueField"
              textField="textField"
              onChange={(value) => this.proxyDeniedWeekDaysChange(value)}
            />
            <small className="grey-text font-weight-light">
              Gli accessi nei giorni della settimana indicati, saranno bloccati.
            </small>
          </MDBCol>
          <MDBCol md="4">
            <div className="d-flex h-100">
              <div className="justify-content-center align-self-center">
                <Switch
                  label="Festività"
                  id="proxy_holidays_sw"
                  className="custom-control-inline"
                  onChange={this.proxyHolidaysChange}
                  defaultChecked={
                    !mailbox.proxyFilters.allowed_dates.isDeniedHoliday
                  }
                />
                <Switch
                  label="Weekend"
                  id="proxy_weekends_sw"
                  className="custom-control-inline"
                  onChange={this.proxyWeekendsChange}
                  defaultChecked={
                    !mailbox.proxyFilters.allowed_dates.isDeniedWeekend
                  }
                />
              </div>
            </div>
          </MDBCol>
        </MDBRow>
        <MDBRow className="mb-2">
          <MDBCol md="6">
            <label>Intervallo Date</label>
            <MDBRow>
              <MDBCol md="6">
                <label>Da</label>
                <DateTimePicker
                  format="YYYY-MM-DD HH:mm:ss"
                  defaultValue={
                    moment(
                      mailbox.proxyFilters.allowed_dates.deniedRange.from,
                      "YYYY-MM-DD HH:mm:ss"
                    ).isValid()
                      ? moment(
                          mailbox.proxyFilters.allowed_dates.deniedRange.from,
                          "YYYY-MM-DD HH:mm:ss"
                        ).toDate()
                      : null
                  }
                  onChange={this.fromRangeDateChange}
                />
              </MDBCol>
              <MDBCol md="6">
                <label>A</label>
                <DateTimePicker
                  format="YYYY-MM-DD HH:mm:ss"
                  defaultValue={
                    moment(
                      mailbox.proxyFilters.allowed_dates.deniedRange.to,
                      "YYYY-MM-DD HH:mm:ss"
                    ).isValid()
                      ? moment(
                          mailbox.proxyFilters.allowed_dates.deniedRange.to,
                          "YYYY-MM-DD HH:mm:ss"
                        ).toDate()
                      : null
                  }
                  onChange={this.toRangeDateChange}
                />
              </MDBCol>
            </MDBRow>
            <small className="grey-text font-weight-light">
              Blocca gli accessi in un intervallo di tempo tra due date. Per
              esempio il periodo delle ferie.
            </small>
          </MDBCol>
          <MDBCol md="6">
            <label>Orario non Lavorativo</label>
            <MDBRow>
              <MDBCol md="6">
                <label>Da</label>
                <DateTimePicker
                  timeFormat="HH:mm:ss"
                  date={false}
                  defaultValue={
                    moment(
                      mailbox.proxyFilters.allowed_dates.deniedOutWorkHours
                        .from,
                      "YYYY-MM-DD HH:mm:ss"
                    ).isValid()
                      ? moment(
                          mailbox.proxyFilters.allowed_dates.deniedOutWorkHours
                            .from,
                          "YYYY-MM-DD HH:mm:ss"
                        ).toDate()
                      : null
                  }
                  onChange={this.fromOutWorkHoursChange}
                />
              </MDBCol>
              <MDBCol md="6">
                <label>A</label>
                <DateTimePicker
                  timeFormat="HH:mm:ss"
                  date={false}
                  defaultValue={
                    moment(
                      mailbox.proxyFilters.allowed_dates.deniedOutWorkHours.to,
                      "YYYY-MM-DD HH:mm:ss"
                    ).isValid()
                      ? moment(
                          mailbox.proxyFilters.allowed_dates.deniedOutWorkHours
                            .to,
                          "YYYY-MM-DD HH:mm:ss"
                        ).toDate()
                      : null
                  }
                  onChange={this.toOutWorkHoursChange}
                />
              </MDBCol>
            </MDBRow>
            <small className="grey-text font-weight-light">
              Blocca gli accessi fuori dagli orari lavorativi. Specificare
              l&apos;orario di inizio e di fine della giornata lavorativa.
            </small>
          </MDBCol>
        </MDBRow>
        <p className="text-center">
          <small className="grey-text font-weight-light">
            (Lasciando i campi vuoti nessun blocco verrà attivato)
          </small>
        </p>

        <MDBRow className="justify-content-center">
          <MDBBtn
            type="submit"
            disabled={this.props.modify_mail_state === "request"}
            color="success"
          >
            <MDBIcon far icon="save" size="lg" /> Salva
          </MDBBtn>
        </MDBRow>
      </form>
    </>
  );

  renderDefaultBody = (mailbox) => (
    <>
      <form onSubmit={this.onSubmitConfig}>
        <MDBRow className="mt-2">
          <MDBCol md="12">
            <p>Configurazione Account</p>
          </MDBCol>
        </MDBRow>
        <MDBRow className="mb-2">
          <MDBCol md="6" sm="12">
            <Input
              type="text"
              id="mail_config£_givenName"
              defaultValue={mailbox.givenName}
              label="Nome"
              onChange={this.onInputChange}
            />
          </MDBCol>
          <MDBCol md="6" sm="12">
            <Input
              type="text"
              id="mail_config£_sn"
              defaultValue={mailbox.sn}
              label="Cognome"
              onChange={this.onInputChange}
            />
          </MDBCol>
        </MDBRow>
        <MDBRow className="mb-2">
          <MDBCol md="6" sm="12">
            {/* The following two line are used to prevent browser from autofilling Form fields */}
            <input type= "text" style={{display:"none"}}/>
            <input type="password" style={{display:"none"}}/>
            {/* end */}
            <Input
              type="password"
              id="mail_config£_passwd"
              onChange={this.onInputChange}
              label="Password"
              defaultValue={""}
              pattern="^(?!.* ).{8,128}$"
            />
            <small className="orange-text">
              Password, se inserita, deve essere tra 8 e 128 caratteri e non
              contenere spazi
            </small>
          </MDBCol>
          <MDBCol md="6" sm="12">
            <Input
              type="password"
              id="mail_config£_conf_password"
              onChange={this.onInputChange}
              label="Conferma password"
              defaultValue={""}
              pattern="^(?!.* ).{8,128}$"
            />
            <small className="orange-text">
              Le due password, se inserite, devono essere uguali
            </small>
          </MDBCol>
        </MDBRow>
        {config.optionalInfo.toLowerCase()==="true" ? 
          <MDBRow className="mb-2">
            <MDBCol md="6" sm="12">
              <Input
                type="text"
                id="mail_config£_mobile"
                label="Telefono (facoltativo)"
                defaultValue={mailbox.mobile}
                onChange={this.onInputChange}
              />
             </MDBCol>
            {/* <MDBCol md="6" sm="12">
              <Input
                type="text"
                id="mail_config£_mailLocalAddress"
                label="Mail secondaria (facoltativo)"
                defaultValue={mailbox.mailLocalAddress}
                onChange={this.onInputChange}
              />
            </MDBCol>   */}
          </MDBRow> : ""
        }
        {config.forward_feature === "disabled" && mailbox.forwardActive === true ?
          <>
            <br />
            <small className="orange-text">
              {"ATTENZIONE! Forward account attivo ma funzionalità disattiva. Contattare gli amministratori di sistema."}
            </small>
            <br />
            <br />
          </> : null
        }
        {config.forward_feature === "enabled" || (config.forward_feature === "compatibility") ?
          <MDBRow class="mt-2" id="mail_config&_forwardActive&_row" hidden={config.forward_feature === "compatibility" && mailbox.forwardActive === false} >
            <MDBCol md="6" sm="4">
              {(config.forward_feature === "compatibility") ?
                <>
                  <small className="orange-text">
                    {config.forward_message}
                  </small>
                  <br />
                </> : null
              }
              <Switch
                id={"mail_config&_forwardActive&_sw"}
                onChange={this.onSwitchChange}
                defaultChecked={mailbox.forwardActive}
                label="Forward"
                className="mb-3 custom-control-inline"
              />
              <br></br>
              <small className="text-info">
                I messaggi verranno inoltrati all'indirizzo scelto.
              </small>
              <br />
              <small className="text-muted">
                NON mantiene copia del messaggio sulla casella locale
              </small>
            </MDBCol>
            {mailbox.forwardActive === true ? (
              <MDBCol md="6" sm="8">
                <Input
                  type="email"
                  id="mail_config£_maildrop"
                  onChange={this.onInputChange}
                  label="Indirizzo per il reindirizzamento (forward)"
                  defaultValue={mailbox.maildrop}
                />
              </MDBCol>
            ) : null}
          </MDBRow> : null
        }
        {this.props.current_mailbox.objectClass.includes('PureFTPdUser') ?<MDBRow>
          <MDBCol md="6">
            <label>Descrizione</label>
            <Multiselect
              data={this.state.description}
              valueField="valueField"
              textField="textField"
              defaultValue={mailbox.description}
              onCreate={this.onCreateDescription.bind(this)}
              onChange={this.descriptionChange.bind(this)}
            />
          </MDBCol>
        </MDBRow>: null}
        <hr />
        <MDBRow className="mb-2">
          <MDBCol md="6" className="justify-text-center">
            <p>Configurazione filtri</p>
            {this.renderSwitches(this.props.current_mailbox)}
          </MDBCol>
          <MDBCol md="6">
            {this.isMinPostmaster()
              ? this.renderQuotaSelect(
                  this.props.current_domain.maxQuota,
                  mailbox.quota,
                  mailbox.quotausage
                )
              : this.renderQuotaUsage(
                mailbox.quota,
                mailbox.quotausage
              )}
          </MDBCol>
        </MDBRow>
        <MDBRow className="mb-2">
          <MDBCol md="6" sm="12">
          </MDBCol>
          <MDBCol md="6" sm="12">
            <Input
              type={(config.user_ftp_enabled == "true")? "number" : "hidden"}
              id="ftp£_FTPQuotaMBytes"
              label={(config.user_ftp_enabled == "true")? "FTP Quota (In GB)" : ""}
              onChange={this.props.onInputChange}
              disabled={!(this.props.auth_type === 'admin' || this.props.auth_type === 'postmaster') || !this.props.current_mailbox.objectClass.includes('PureFTPdUser')}
              defaultValue={this.props.current_mailbox.FTPQuotaMBytes ? Math.floor(this.props.current_mailbox.FTPQuotaMBytes/1024) : 0}
            />
            <small className="orange-text">
              {(config.user_ftp_enabled == "true")? "Il valore inserito deve essere positivo." : ""}
            </small>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol>
            <SpamProtectionInput
              title="Protezione SPAM"
              defaultValue={
                spamMap.range.amavisSpamKillLevel[mailbox.amavisSpamKillLevel]
              }
            />
          </MDBCol>
        </MDBRow>
        <MDBRow className="justify-content-center">
          <MDBBtn
            type="submit"
            value="Submit"
            disabled={this.props.modify_mail_state === "request"}
            color="success"
          >
            <MDBIcon far icon="save" size="lg"></MDBIcon> Salva
          </MDBBtn>
        </MDBRow>
      </form>
    </>
  );

  renderBody(tab) {
    switch (tab) {
      case -"standard":
        return this.renderDefaultBody(this.props.current_mailbox);
      case -"proxy":
        return this.renderProxyBody(this.props.current_mailbox);
      default:
        return null;
    }
  }

  render() {
    const mailbox = this.props.current_mailbox;
    const { auth_type } = this.props;
    // eslint-disable-next-line react/prop-types
    return (
      <>
        {this.isMinPostmaster() ? (
          <MDBRow className="mb-3">
            <MDBCol md="5">
              <NavTab
                to={`/mailboxes/${this.props.current_domain.name}`}
                label="Torna al dominio"
                direction="left"
              />
            </MDBCol>
          </MDBRow>
        ) : null}
        <h3>
          Configurazione di <strong>{mailbox.mail}</strong>
        </h3>
        <ul className="nav nav-tabs mt-4 justify-content-center nav-fill">
          <li className="nav-item">
            <a
              className={`nav-link font-weight-bold ${
                this.state.tab === "standard" ? "active" : " "
              }`}
              id="standard_tab_button"
              onClick={this.onClickTabsButton}
              href="#"
            >
              <MDBIcon icon="user-cog" className="mr-1" />
              Impostazioni della casella
            </a>
          </li>
          {config.withProxyTabs === true /* && this.isMinPostmaster() */ ? (
            <li className="nav-item">
              <a
                className={`nav-link font-weight-bold ${
                  this.state.tab === "proxy" ? "active" : " "
                }`}
                id="proxy_tab_button"
                onClick={this.onClickTabsButton}
                href="#"
              >
                <MDBIcon icon="shield-alt" className="mr-1" />
                Opzioni di sicurezza
              </a>
            </li>
          ) : null}
        </ul>
        {this.state.tab === "standard" && this.state.loaded === "success"
          ? this.renderDefaultBody(mailbox)
          : null}
        {this.state.tab === "proxy" && this.state.loaded === "success"
          ? this.renderProxyBody(mailbox)
          : null}
      </>
    );
  }
}
