/* eslint-disable radix */
/* eslint-disable no-prototype-builtins */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React from "react";
import moment from "moment";
import {
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBInputGroup,
  MDBDataTable,
  MDBPagination,
  MDBPageItem,
  MDBPageNav,
} from "mdbreact";
import Modal from "./common/Modal.jsx";
import proxyData from "../config/proxy_data";
import DBAlert from "./common/DBAlert.jsx";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
// import { Link } from 'react-router-dom';
import Input from "./common/Input.jsx";
import { relativeTimeThreshold } from "moment";

export default class BannedIP extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      intervalId: 0,
      isModalOpen: false,
      ipToDelete: "",
      filters: {},
      page: 0,
      limit: 99999,
      bannedIP: [],
    };
  }

  onInputChange = (e) => {
    var { id, value } = e.currentTarget;
    value = value && value.trim() !== "" ? value : "";
    const [key] = id.split('_');
    const filters = this.state.filters;
    filters[key] = value;
    this.setState({ filters: filters });
    this.getPaginatedIPs();
  };

  //OLD CODE TO REMOVE
  // filter = (e) => {
  //   const { value } = e.currentTarget;
  //   this.setState(
  //     {
  //       filter: value && value.trim() !== "" ? value : null,
  //     },
  //     async () => {
  //       await this.getPaginatedIPs(); 
  //       this.setState({ page: 0 });
  //     }
  //   );
  // };

  getPaginatedIPs = async (page = 0) => {
    await this.props.getBannedIP(page, this.state.limit, this.state.filters);
    this.setState({ bannedIP: this.props.banned_ip, backup_db: this.props.backup_db });
  };

  onCreate = async (e) => {
    const { value } = document.getElementById("add_ip_input");
    const banTo = document.getElementById("add_ip_ban_to_input").value;
    await this.props.modifyBannedIP({ ip: value, action: "add", banTo });
    this.getPaginatedIPs();
  };

  onDelete = async () => {
    const ip = this.state.ipToDelete;
    await this.props.modifyBannedIP({ ip, action: "delete" });
    this.onDeleteDialogClose();
    this.getPaginatedIPs();
  };

  onClickDelete = (e) => {
    const { id } = e.currentTarget;
    const [ip] = id.split("&_");
    this.setState({ ipToDelete: ip, isModalOpen: true });
  };

  onDeleteDialogClose = () => this.setState({ isModalOpen: false });

  componentDidMount = async () => {
    await this.getPaginatedIPs();
    document.getElementById("ip_filter_input").focus();
  };

  getContinentName = (code) => {
    const element = proxyData.continents.find((el) => el.valueField === code);
    return element ? element.textField : null;
  };

  renderIPs(IPs) {
    const columns = [
      {
        label: "Indirizzo IPv4",
        field: "address",
      },
      {
        label: "Bloccato dal",
        field: "from",
      },
      {
        label: "Al",
        field: "to",
      },
      {
        label: "Zona",
        field: "zone",
      },
      {
        label: "Cancella",
        field: "delete",
      },
    ];

    const rows = IPs.map((item) => ({
      address: <a href={"/logs?ip=" + item.ip}><p className="font-weight-normal teal-text">{item.ip}</p></a>,
      from: (
        <div className="d-flex flex-column align-items-start mr-4">
          <span className="font-weight-normal">
            {" "}
            <MDBIcon far icon="clock" size="xs" className="mr-1" />{" "}
            {moment(item.from).format("HH:mm:ss")}
          </span>
          <small className="blue-grey-text font-weight-light">
            <MDBIcon far size="md" icon="calendar-alt" className="mr-2" />{" "}
            {moment(item.from).format("YYYY-MM-DD")}
          </small>
        </div>
      ),
      to: (
        <div className="d-flex flex-column align-items-start mr-4">
          <span className="font-weight-normal">
            {" "}
            <MDBIcon far icon="clock" size="xs" className="mr-1" />{" "}
            {moment(item.to).format("HH:mm:ss")}
          </span>
          <small className="blue-grey-text font-weight-light">
            <MDBIcon far icon="calendar-alt" size="md" className="mr-2" />{" "}
            {moment(item.to).format("YYYY-MM-DD")}
          </small>
        </div>
      ),
      zone: (
        <div className="d-flex flex-column align-items-start mr-4">
          <span className="indigo-text font-weight-normal">{item.country}</span>
          <small
            className="orange-text font-weight-normal"
            title={item.continent}
          >
            {this.getContinentName(item.continent)}
          </small>
        </div>
      ),

      delete: (
        <div className="text-right">
          <a
            tag="a"
            className="text-right"
            id={`${item.ip}&_del_button`}
            onClick={this.onClickDelete.bind(this)}
          >
            <MDBIcon far icon="trash-alt" className="text-danger" size="lg" />
          </a>
        </div>
      ),
    }));
    return (
      <MDBDataTable
        data={{ rows, columns }}
        entriesLabel="Per pagina:"
        entries="20"
        searching={false}
        //sortable={false}
        fixed bordered
        infoLabel= {["Da", "a", "su", <strong>IPv4</strong>]}
        className="table-striped mt-4"
      />
    );
  }

  render() {
    return (
      <>
        <Modal
          size="md"
          isOpen={this.state.isModalOpen}
          onClose={this.onDeleteDialogClose}
          closeTitle="Annulla"
          onConfirm={this.onDelete.bind(this)}
          title={`Sbloccare IPv4 ${this.state.ipToDelete}?`}
          confirmTitle="Continua"
          message="Sei sicuro di voler procedere?"
        />
        <MDBRow>
          <MDBCol md="6">
            <label>Cerca un IPv4</label>
            <Input
              type="text"
              id="ip_filter_input"
              onChange={this.onInputChange}
              placeholder="Cerca ipv4..."
            />
            {/*OLD CODE TO REMOVE
            <label htmlFor="search-input">Cerca un IPv4</label>
            <Input
              id="search-input"
              onChange={this.filter}
              placeholder="Cerca ipv4..."
            /> */}
          </MDBCol>
          <MDBCol md="6">
            <MDBInputGroup
              type="text"
              id="add_ip_input"
              hint="IPv4 da bloccare"
              append={
                <MDBBtn
                  onClick={this.onCreate}
                  className="m-0 px-3 py-2 z-depth-0 btn-rhx"
                  color="yellow"
                >
                  <MDBIcon icon="lock" />
                </MDBBtn>
              }
              onKeyPress={(event) => {
                if (event.key === "Enter") this.onCreate();
              }}
              label="Blocca un IPv4"
            />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md="6">
            <label>Zona</label>
            <MDBInputGroup
              type="text"
              inputs={
                <>
                  <Input
                    type="text"
                    placeholder="nazione"
                    id="country_filter_input"
                    onChange={this.onInputChange}
                  />
                  <select
                    className="browser-default custom-select"
                    id="continent_filter_input"
                    onChange={this.onInputChange}
                    placeholder="continente"
                  >
                    <option className="text-muted" value="" default>
                      Tutti
                    </option>
                    {proxyData.continents.map(element => (
                      <option
                        value={element.valueField}
                        key={element.valueField}
                      >
                        {element.textField}
                      </option>
                    ))}
                    {}
                  </select>
                </>
              }
            />
          </MDBCol>
          <MDBCol md="6">
            <label>Blocca fino a:</label>
            <DateTimePicker
              format="YYYY-MM-DD HH:mm:ss"
              time={false}
              id="add_ip_ban_to"
            />
          </MDBCol>
        </MDBRow>

        {this.props.get_banned_ip_state === "failure" ? (
          <DBAlert />
        ) : (
          this.state.backup_db ?
            (
            <MDBRow>
              <h3 className="text-warning">
                In utilizzo database Geo IP di Backup!
              </h3> 
              {this.renderIPs(this.state.bannedIP)}
            </MDBRow>
            )
            :
            this.renderIPs(this.state.bannedIP)
        )}
        {/*<MDBRow>
          <MDBCol className="d-flex justify-content-between">
            <p>{this.props.banned_ip_count} <span className='text-muted'>IP attualmente in Ban</span></p>
             <MDBPagination className="mb-5">
              <MDBPageItem
                disabled={this.state.page === 0}
                onClick={this.getPrevious}
              >
                <MDBPageNav aria-label="Previous">
                  <span aria-hidden="true">Previous</span>
                </MDBPageNav>
              </MDBPageItem>
              <MDBPageItem disabled={true}>
                <MDBPageNav>
                  <h6 className="font-weight-normal text-dark mb-3">
                    {this.state.page + 1}
                  </h6>
                </MDBPageNav>
              </MDBPageItem>
              <MDBPageItem onClick={this.getNext} disabled={this.isLastPage()}>
                <MDBPageNav aria-label="Previous">
                  <span aria-hidden="true">Next</span>
                </MDBPageNav>
              </MDBPageItem>
            </MDBPagination> *

          </MDBCol>
        </MDBRow>*/}
      </>
    );
  }
}
