import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Component from '../components/Domains.jsx';
import * as actionCreators from '../actions';

const Container = withRouter(connect(
  (state) => {
    const props = {
      domains: state.domains,
      filtred_domains: state.filtred_domains,
      get_domains_state: state.get_domains_state,
      modify_domain_state: state.modify_domain_state,
      delete_domain_state: state.delete_domain_state,
    };
    return props;
  },
  actionCreators,
)(Component));

export default Container;
