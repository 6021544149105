/* eslint-disable import/prefer-default-export */
/* eslint-disable react/display-name */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React from 'react'
import { MDBRow, MDBCol, MDBBtn } from 'mdbreact'
import Input from './Input.jsx'

export default props => (
	<form onSubmit={props.onSubmit}>
		<MDBRow className="mb-2">
			<MDBCol size="12">
				<Input
					id="mailbox_name"
					type="text"
					value={props.mailboxNameValue}
					onChange={props.mailboxNameChange}
					className="lowercase"
					label="Nome casella *"
					placeholder="Nome casella"
					required={true}
				/>
			</MDBCol>
		</MDBRow>
		<MDBRow className="mb-2">
			<MDBCol size="12">
				<Input
					id="create_mail_name"
					label="Nome *"
					placeholder="Nome dell'utente"
					type="text"
					required={true}
				/>
			</MDBCol>
		</MDBRow>
		<MDBRow className="mb-2">
			<MDBCol size="12">
				<Input
					id="create_mail_surname"
					label="Cognome *"
					placeholder="Cognome dell'utente"
					type="text"
					required={true}
				/>
			</MDBCol>
		</MDBRow>
		<MDBRow className="mb-2">
			<MDBCol size="12">
				{/* The following two line are used to prevent browser from autofilling Form fields */}
				<input type= "text" style={{display:"none"}}/>
				<input type="password" style={{display:"none"}}/>
				{/* end */}
				<Input
					id="create_mail_pw"
					label="Password *"
					placeholder="Inserire password"
					type="password"
					pattern="^(?!.* ).{8,128}$"
					required={true}
				/>
				<small className="orange-text">
					La password deve essere tra 8 e 128 caratteri e non contenere gli spazi!
				</small>
			</MDBCol>
		</MDBRow>
		<MDBRow className="mb-2">
			<MDBCol size="12">
				<Input
					id="create_mail_conf_pw"
					label="Conferma Password *"
					placeholder="Ripetere password"
					type="password"
					pattern="^(?!.* ).{8,128}$"
					required={true}
				/>
			</MDBCol>
		</MDBRow>
		<MDBRow>
			<MDBCol size="12" className="text-center">
				<MDBBtn color="white" onClick={props.onClose}>
					Annulla
				</MDBBtn>
				<MDBBtn className="btn-rhx" color="yellow" type="submit">
					+ Crea
				</MDBBtn>
			</MDBCol>
		</MDBRow>
	</form>
)
