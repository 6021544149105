/* eslint-disable react/jsx-key */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-const */
/* eslint-disable no-plusplus */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';
import {
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBBadge,
  MDBIcon,
  MDBInputGroup,
  MDBDataTable,
  MDBTooltip,
} from 'mdbreact';
import Modal from './common/Modal.jsx';
import Switch from './common/Switch.jsx';
import DomainCreationForm from './common/DomainCreationForm.jsx';

export default class Domains extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      del_selected_domain: '',
      createDomainName: '',
      isModalOpen: false,
      isCreateModalOpen: false,
      domainsFilter: false,
      domainsSort: 'name',
      domainsSortDirection: 'asc',
      refresh: 0,
    };
  }

  componentDidMount = async () => {
    // eslint-disable-next-line no-undef
    await this.props.getDomains();
    document.getElementById("search_input").focus();
  };


  onOptionChange = async (e) => {
    // listener per i checkbox antivirus, spam ecc.
    const { id, checked } = e.currentTarget;
    const [domain, option] = id.split('&_');
    const domainData = { domain, options: { [option]: checked } };
    await this.props.modifyDomain(domainData);
    const domains = this.props.domains.map((item) => {
      if (item.name === domain) {
        item[option] = checked;
      }
    });
    // this.props.getDomains();
    this.props.setDomains(domains);
  };


  onClickDelete = (e) => {
    const { id } = e.currentTarget;
    const [domain] = id.split('&_');
    this.setState({ del_selected_domain: domain, isModalOpen: true });
  };

  onClickCreate = () => {
    const domainName = document.getElementById('create_input').value;
    this.setState({ isCreateModalOpen: true, createDomainName: domainName });
  };

  onCreate = () => {};

  onDeleteDialogClose = () => this.setState({ isModalOpen: false });

  onCreateDialogClose = () => this.setState({ isCreateModalOpen: false });

  onDomainDelete = async () => {
    const [domain] = this.props.domains.filter(
      item => item.name === this.state.del_selected_domain,
    );
    await this.props.deleteDomain({ dn: domain.dn });
    this.onDeleteDialogClose();
    this.props.getDomains();
  };

  createDomain = async (e) => {
    e.preventDefault();
    const domainAttributes = [
      'vd',
      'maxMail',
      'maxAntivirus',
      'maxSmtpAuth',
      'maxGreyList',
    ];
    const postmasterAttributes = [
      'gidNumber',
      'uidNumber',
      'homeDirectory',
      'userPassword',
      'confPassword',
    ];

    const domainOptions = domainAttributes.reduce(
      (acc, curr) => ({
        ...acc,
        [curr]: document.getElementById(`create_domain_${curr}`).value,
      }),
      {},
    );
    const postmasterOptions = postmasterAttributes.reduce(
      (acc, curr) => ({
        ...acc,
        [curr]: document.getElementById(`create_domain_${curr}`).value,
      }),
      {},
    );
    await this.props.createDomain({
      domain: domainOptions.vd,
      options: { postmasterOptions, domainOptions },
    });
    this.onCreateDialogClose();
    this.props.getDomains();
  };

  setDomainsFilter = (e) => {
    const { value } = e.currentTarget;
    this.setState({
      domainsFilter: value && value.trim() !== '' ? value : false,
    });
  };

  setDomainsSort = (e) => {
    const sortBy = e.currentTarget.getAttribute('sortBy');
    const {domainsSortDirection} = this.state;
    this.setState({
      domainsSort: sortBy, domainsSortDirection: domainsSortDirection == 'asc' ? 'desc' : 'asc',
    });
  };

  filterDomains = (domains) => {
    const filter = this.state.domainsFilter;
    if (filter) {
      return domains.filter(item => item.name.includes(filter));
    }
    return domains;
  };

  sortDomains = (domains) => {
    const { domainsSort, domainsSortDirection} = this.state;
    if (domainsSort) {
      return domains.concat().sort((a, b) => {
        if (a[domainsSort] < b[domainsSort]) { return domainsSortDirection == 'asc' ? -1 : 1; }
        if (a[domainsSort] > b[domainsSort]) { return domainsSortDirection == 'asc' ? 1 : -1; }
        return 0;
      });
    }
    return domains;
  };

  renderDomains2(domains) {
    const data = {
      columns: [
        {
        label: <p onClick={this.setDomainsSort} sortBy="name">{'Dominio'}</p>,
          field: 'domain',
        },
        {
          label: <p onClick={this.setDomainsSort} sortBy="accountActive">{'Status'}</p>,
          field: 'status',
        },
        {
          label: <p onClick={this.setDomainsSort} sortBy="logsView">{'Logs view'}</p>,
          field: 'logs_view',
        },
        {
          label: 'Elimina',
          field: 'delete',
        },
      ],
      rows: domains.map(item => ({
        domain: (
          <MDBTooltip id={item.name} placement="right" key={`link_${item.name}`}>
            <Link className="font-weight-normal" to={`/mailboxes/${item.name}`}>
              {item.name}
            </Link>
            <div>Configura</div>
          </MDBTooltip>
        ),
        status: (
          <div
            className="text-right"
            title={`Domino${
              item.accountActive ? '' : ' non'
            } attivo per la ricezione di posta`}
          >
            {/* <p>{item.accountActive ? 'attivo' : 'no'}</p> */}
       <Switch key={`active_${item.name}`}
              id={`${item.name}&_accountActive`}
        onChange={this.onOptionChange.bind(this)}
			  defaultChecked={item.accountActive}
              label="Attivo"/>
          </div>
        ),
        logs_view: (
          <div
            className="text-right"
            title={`${
              item.logsView ? 'Vista logs postmaster attiva' : 'Vista logs postmaster non attiva'
            }`}
          >
       <Switch key={`${item.name}&_logsView`}
              id={`${item.name}&_logsView`}
        onChange={this.onOptionChange.bind(this)}
			  defaultChecked={item.logsView}
              label="Attivo"/>
          </div>
        ),
        delete: (
			<div className="text-right" key={`delete_${item.name}`}>
          <a
            tag="a"
            id={`${item.name}&_del_button`}
            onClick={this.onClickDelete.bind(this)}
          >
            <MDBIcon far icon="trash-alt" className="text-danger" size="lg" />
          </a></div>
        ),
      })),
    };

    return (
      <MDBDataTable entries="50" entriesLabel="Per pagina:" id="domainsTable" searching={false} sortable={false} fixed bordered data={data} className="table-striped mt-4" />
    );
  }

  render() {
    let domains = this.filterDomains(this.props.domains);
    domains = this.sortDomains(domains);
    return (
      <>
        <MDBRow className="mb-3">
          <MDBCol md="6">
            <MDBInputGroup
              onKeyPress={(event) => {
                if (event.key === 'Enter') this.onClickCreate();
              }}
              type="text"
              id="create_input"
              className="lowercase"
              append={
                <MDBBtn
                  className="m-0 px-3 py-2 z-depth-0 btn-rhx"
                  color="yellow"
                  onClick={this.onClickCreate.bind(this)}
                >
                  + Crea
                </MDBBtn>
              }
              hint="Inserire il nome del nuovo dominio"
              label="Crea un Nuovo Dominio"
            />
          </MDBCol>
          <MDBCol md="6">
            <MDBInputGroup
              onChange={this.setDomainsFilter}
              type="text"
              id="search_input"
              append={
                <MDBBtn
                  className="m-0 px-3 py-2 z-depth-0 btn-rhx"
                  color="yellow"
                >
                  <MDBIcon icon="search" />
                </MDBBtn>
              }
              label="Cerca Dominio"
            />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md="12">
            <span className="mr-3">Domini:</span>
            <span className="mr-3">
              Attivi{' '}
              <MDBBadge color="rgba-yellow-strong z-depth-0">
                <strong className="text-dark">
                  {domains.filter(item => item.accountActive).length}
                </strong>
              </MDBBadge>
            </span>
            <span>
              Totali{' '}
              <MDBBadge color="dark z-depth-0">
                <strong className="text-white">{domains.length}</strong>
              </MDBBadge>
            </span>
          </MDBCol>
        </MDBRow>

        <Modal
          size="lg"
          isOpen={this.state.isModalOpen}
          onClose={this.onDeleteDialogClose}
          onConfirm={this.onDomainDelete}
          title={`Cancellazione del dominio ${this.state.del_selected_domain}`}
          message="Sei sicuro di voler procedere?"
        />

        <Modal
          size="lg"
          isOpen={this.state.isCreateModalOpen}
          onClose={this.onCreateDialogClose}
          onConfirm={this.onCreate}
          withoutFooter={true}
          title="Creazione Nuovo Dominio "
          message={
            <DomainCreationForm
              nameValue={this.state.createDomainName}
              onSubmit={this.createDomain.bind(this)}
            />
          }
        />
		{this.props.get_domains_state === 'failure' ? <h3>Caricamento dei domini non riuscito...</h3> : null}
		{this.props.get_domains_state === 'request' ? <div className="mt-5 pt-5 text-center" ><div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div></div> : null}
	    {this.props.get_domains_state === 'success' ? this.renderDomains2(domains) : null}
      </>
    );
  }
}
