import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Component from '../components/WhiteListIP.jsx';
import * as actionCreators from '../actions';

const Container = withRouter(connect(
  (state) => {
    const props = {
      logs: state.logs,
      logs_filters: state.logs_filters,
      logs_limit: state.logs_limit,
      whitelist_ip: state.whitelist_ip,
      get_whitelist_ip_state: state.get_whitelist_ip_state,
      modify_whitelist_ip_state: state.modify_whitelist_ip_state,
      get_blacklist_ip_state: state.get_blacklist_ip_state,
      blacklist_ip: state.blacklist_ip,
    };
    return props;
  },
  actionCreators,
)(Component));

export default Container;
