/* eslint-disable no-var */
export const generateUrl = path => `${window.location.protocol}//${window.location.host}${path}`;

export const bytesToMb = bytes => bytes / 1024 / 1024;
export const bytesToGb = bytes => bytes / 1024 / 1024 / 1024;
export const MgToBytes = Mg => Mg * 1024 * 1024;
export const GbToBytes = Gb => Gb * 1024 * 1024 * 1024;

export const getReservedMailNames = domain => [
  `abuse@${domain}`,
  `postmaster@${domain}`,
  `webmaster@${domain}`,
  `www@${domain}`,
];

export const buildHomeDirectoryPattern = (domain) => {
  var domainString = domain.replace('.', '\\.');
  var pattern = '^\\/srv\\/www\\/replacement\\/?((?!\\.\\.\\/|\\.\\/|\\s).)*$';
  pattern = pattern.replace('replacement', domainString);
  return pattern;
};

export const isReservedMail = (mail) => {
  if (mail) {
    const [username, domain] = mail.split('@');
    const reservedMails = getReservedMailNames(domain);
    return reservedMails.includes(mail);
  }
  return false;
};

export const parseBoolean = (bool) => {
  return bool ? bool.toLowerCase() === "true" : false;
}