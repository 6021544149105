/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable radix */
/* eslint-disable no-prototype-builtins */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useRef } from "react";
import moment, { relativeTimeThreshold } from "moment";
import { MDBRow, MDBCol } from "mdbreact";
import momentLocalizer from "react-widgets-moment";
import { Pie, Line } from "react-chartjs-2";

moment.locale("it");
momentLocalizer();

export default class Statistics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hoursRange: 12,
      dataPie: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: [
              "#F7464A",
              "#46BFBD",
              "#FDB45C",
              "#949FB1",
              "#4D5360",
              "#AC64AD",
            ],
            hoverBackgroundColor: [
              "#FF5A5E",
              "#5AD3D1",
              "#FFC870",
              "#A8B3C5",
              "#616774",
              "#DA92DB",
            ],
          },
        ],
      },
      dataLine: {
        labels: [],
        datasets: [
          {
            label: "Failed",
            fill: true,
            lineTension: 0.3,
            backgroundColor: "#faada7",
            borderColor: "#f44336",
            borderCapStyle: "butt",
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: "miter",
            data: [],
          },
          {
            label: "Logged",
            fill: true,
            lineTension: 0.3,
            backgroundColor: "#a5d7a7",
            borderColor: "#4caf50",
            borderCapStyle: "butt",
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: "miter",
            data: [],
          },
        ],
      },
      dataProtocols: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: [
              "#334E58",
              "#A69888",
              "#DE2B99",
            ],
            hoverBackgroundColor: [
              "#334E58",
              "#A69888",
              "#DE2B99",
            ],
          },
        ],
      },
    };
  }

  componentDidMount = async () => {
    await this.getStatistics(this.state.hoursRange);
  };

  getStatistics = async (hoursRange) => {
    await this.props.getStatistics(hoursRange);
    var { dataPie, dataLine, dataProtocols } = this.props.statistics;
    var statePie = this.state.dataPie;
    var stateLine = this.state.dataLine;
    var stateProtocols = this.state.dataProtocols;
    statePie.labels = dataPie.labels;
    statePie.datasets[0].data = dataPie.data;

    stateLine.labels = dataLine.labels;
    stateLine.datasets[1].data = dataLine.dataLogged;
    stateLine.datasets[0].data = dataLine.dataFailed;

    stateProtocols.labels = dataProtocols.labels;
    stateProtocols.datasets[0].data = dataProtocols.data;
    this.setState({ dataPie: statePie, dataLine: stateLine, dataProtocols: stateProtocols });
  };

  onRangeChange = async (e) => {
    e.preventDefault();
    var hoursRange = e.currentTarget.getAttribute("data-value");
    await this.getStatistics(hoursRange);
    this.setState({ hoursRange });
  };

  render() {
    const rangeMap = {
      1: "1 ora",
      6: "6 ore",
      12: "12 ore",
      24: "24 ore",
      48: "2 giorni",
      96: "4 giorni",
      168: "1 settimana",
    };
    return (
      <>
        {this.props.get_statistics_state === "failure" ? (
          <h3>Caricamento delle statistiche non riuscito...</h3>
        ) : null}
        {this.props.get_statistics_state === "request" ? (
          <div className="mt-5 pt-5 text-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : null}
        {this.props.get_statistics_state === "success" ? (
          <MDBRow className="mb-2 d-flex justify-content-around">
            <MDBCol md="4">
              <h6>Principali paesi per ip attualmente bannati</h6>
              <Pie data={this.state.dataPie} options={{ responsive: true }} />
            </MDBCol>
            <MDBCol md="4">
              <h6>Protocolli di posta principali</h6>
              <Pie data={this.state.dataProtocols} options={{ responsive: true }} />
            </MDBCol>
            <MDBCol md="12">
              <h6>Autenticazioni per ora</h6>
              <Line data={this.state.dataLine} options={{ responsive: true }} />
              <div className="d-flex flex-row justify-content-center">
                <strong className="mr-2">In:</strong>
                {Object.keys(rangeMap).map((key) => (
                  <a
                    data-value={key}
                    href="#"
                    key={key}
                    className={`mr-2 ${
                      key == this.state.hoursRange ? "font-weight-bold" : ""
                    }`}
                    onClick={this.onRangeChange}
                  >
                    {rangeMap[key]}
                  </a>
                ))}
              </div>
            </MDBCol>
          </MDBRow>
        ) : null}
      </>
    );
  }
}
