/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
/* eslint-disable camelcase */
import React from 'react';
import Toggle from 'react-toggle';

export default props => (
<div className={`custom-control col-form-label-sm custom-switch ${props.className ? props.className : ' '}`} >
{props.prependLabel ? <small className="mr-5">{props.prependLabel}</small> : null}
    <input
      type='checkbox'
      className='custom-control-input'
      id={props.id}
      defaultChecked={typeof props.defaultChecked  === 'boolean' ? props.defaultChecked : undefined }
      checked={typeof props.checked  === 'boolean' ? props.checked : undefined}
      onChange={props.onChange ? props.onChange : undefined}
      disabled={typeof props.disabled === 'boolean' ? props.disabled : undefined}
    />
    <label className='custom-control-label ' htmlFor={props.id}>
      {!props.small ? <small>{props.label}</small> : props.label }
    </label>
</div>
);
