import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import { BrowserRouter as Router } from 'react-router-dom';
import thunk from 'redux-thunk';
import '../assets/img/logoRhx.png';
import '../assets/img/phamm_logo.svg';
import '../assets/img/continents.png';
import 'react-widgets/dist/css/react-widgets.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'mdbreact/dist/css/mdb.css';
import "react-toggle/style.css";
import config from './config/index';


import App from './containers/App';
import reducers from './reducers';

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import initBoot from './bootstrap';

async function bootstrap() {
  let store;
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-underscore-dangle
   // const ext = window.__REDUX_DEVTOOLS_EXTENSION__;
   // const devtoolMiddleware = ext && ext();

    store = createStore(
      reducers,
      compose(applyMiddleware(thunk) /*, devtoolMiddleware */),
    );
    await initBoot(store.dispatch);
  } else {
    store = createStore(reducers, compose(applyMiddleware(thunk)));
    await initBoot(store.dispatch);
  }


  // configurazione toast delle notifiche
  toast.configure();
  ReactDOM.render(
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>,
    document.getElementById('app'),
  );
}

bootstrap();
