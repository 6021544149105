import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Component from '../components/MailAliasConfig.jsx';
import * as actionCreators from '../actions';

const Container = withRouter(connect(
  (state) => {
    const props = {
      current_mailbox: state.current_mailbox,
      auth_type: state.auth_type,
      current_mail_alias: state.current_mail_alias,
      current_domain: state.current_domain,
      modify_mail_state: state.modify_mail_state,
      modify_mail_alias_state: state.modify_mail_alias_state,
      get_mail_state: state.get_mail_state,
      mailboxes: state.mailboxes,
    };
    return props;
  },
  actionCreators,
)(Component));

export default Container;
