/* eslint-disable prefer-const */
/* eslint-disable react/display-name */
/* eslint-disable react/no-string-refs */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-plusplus */
/* eslint-disable radix */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable quote-props */
/* eslint-disable react/prop-types */

import React from "react";
import { MDBBtn, MDBRow, MDBCol, MDBIcon, MDBInput } from "mdbreact";
import Input from "./common/Input.jsx";
import config from '../config';
import Switch from "./common/Switch.jsx";

export default class DNS extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSPFDisabled: false,
      isDMARCDisabled: true,
      isDKIMDisabled: true,
      isSwSpfOn: false,
      isSwDmarcOn: false,
      isSwDkimOn: false,
      dkimIsChanged: false,
      dmarcIsChanged: false,
      spfIsChanged: false,
      ttlIsChanged: false,
      loaded: false,
      dkim: "",
      spfDefault: "v=spf1 include:_spf.rhx.it ~all",
      dmarcDefault: "v=DMARC1; p=none; rua=mailto:postmaster@rhx.it",
      ttlDefault: "3600",
      dmarc: "",
      spf: "",
      ttl: "",
      refresh: 0,
    };
  }

  componentDidMount = async () => {
    if (config.withDNS) {
      await this.props.getDNSDomain({ domain: this.props.current_domain.name });
    }

    if (
      !config.withDNS ||
      Object.keys(this.props.current_dns_domain).length === 0
    ) {
      this.setState({
        loaded: true,
        isSwDmarcOn: false,
        isSwSpfOn: false,
        isSwDkimOn: false,
        spf: "",
        dmarc: "",
        dkim: "",
        ttl: 0,
        dmarcDefault: "",
      });
    } else {
      await this.props.getDNSZones({ domain: this.props.current_domain.name });
      const { get_dns_domain_state } = this.props;
      if (get_dns_domain_state === "success") {
        const dmarcDefault = `v=DMARC1; p=none; rua=mailto:postmaster@${this.props.current_domain.name}`;
        this.setState({
          loaded: true,
          isSwDmarcOn: this.hasDMARC(),
          isSwSpfOn: this.hasSPF(),
          isSwDkimOn: this.hasDKIMRecord(),
          spf: this.hasSPF() ? this.getSPF() : "",
          dmarc: this.hasDMARC() ? this.getDMARC().tXTRecord : "",
          dkim: this.hasDKIMRecord() ? this.getDKIMRecord().tXTRecord : "",
          ttl: this.hasTTL() ? this.getTTL() : this.state.ttlDefault,
          dmarcDefault,
        });
      }
    }
  };

  getDKIMDomainSelector = (domain) => {
    return `${domain.replace(/\./g, "")}._domainkey`;
  };
  getDKIMRecord = () => {
    const DKIM_KEY = `${this.props.current_domain.name.replace(
      /\./g,
      ""
    )}._domainkey`;
    const zones = this.props.current_dns_zones;
    const zone = zones.find((item) => item.dc === DKIM_KEY);
    return zone;
  };

  getSPF = () => {
    const domain = this.props.current_dns_domain;

    const findSPF = (txtRecord) => {
      const SPF = "v=spf1";
      if (!txtRecord) return undefined;
      if (Array.isArray(txtRecord))
        return txtRecord.find((e) => e.includes(SPF));
      return txtRecord.includes(SPF) ? txtRecord : undefined;
    };

    return domain.tXTRecord ? findSPF(domain.tXTRecord) : undefined;
  };

  getTTL = () => {
    const domain = this.props.current_dns_domain;
    return domain.dNSTTL ? domain.dNSTTL : undefined;
  };

  getDMARC = () => {
    const DMARC_KEY = "_dmarc";
    const zones = this.props.current_dns_zones;
    const zone = zones.find((item) => item.dc === DMARC_KEY);
    return zone;
  };

  hasDKIMRecord = () => this.getDKIMRecord() !== undefined;

  hasDKIM = () => {
    const domain = this.props.current_domain;
    return domain.DKIMDomain && domain.DKIMIdentity;
  };

  hasDMARC = () => this.getDMARC() !== undefined;

  hasSPF = () => this.getSPF() !== undefined;

  hasTTL = () => this.getTTL() !== undefined;

  renderExternalDNS = () => (
    <MDBRow>
      <MDBCol
        size="12"
        className="mt-2 mb-5 d-flex flex-column justify-content-center align-items-center"
      >
        <p>
          <MDBIcon icon="exclamation-triangle mr-2" className="text-warning" />
          Questo dominio sta usando i dns esterni.
        </p>
        <p>
          Alla generazione di DKIM verrà scaricato un file con il record TXT da
          inserire nella zona
        </p>
        <h3>{`${this.getDKIMDomainSelector(
            this.props.current_domain.name
          )}.${this.props.current_domain.name}`}</h3>
      </MDBCol>
      {this.hasDKIM() ? (
        <>
          <MDBCol
            size="12"
            className="mb-5 d-flex flex-column justify-content-center align-items-center"
          >
            <MDBIcon
              far
              icon="check-circle mb-3"
              size="8x"
              className="green-text"
            />
            <h2 className="font-weight-bold">DKIM Attivo</h2>
          </MDBCol>
          <MDBCol size="12" className="mt-2 d-flex justify-content-center">
            <MDBBtn
              className="mx-auto mr-2"
              type="button"
              color="danger"
              onClick={this.deleteDKIMWithoutDNS}
            >
              Elimina DKIM
            </MDBBtn>
            <MDBBtn
              className="mx-auto ml-2"
              type="button"
              onClick={this.createDKIMWithoutDNS}
            >
              Rigenera DKIM
            </MDBBtn>
          </MDBCol>
        </>
      ) : (
        <>
          <MDBCol
            size="12"
            className="mb-5 d-flex flex-column justify-content-center align-items-center"
          >
            <MDBIcon
              far
              icon="times-circle mb-3"
              size="8x"
              className="text-danger"
            />
            <h2 className="font-weight-bold">DKIM non attivo</h2>
          </MDBCol>
          <MDBCol size="12" className="mt-2 d-flex justify-content-center">
            <MDBBtn
              className="mx-auto ml-2"
              type="button"
              onClick={this.createDKIMWithoutDNS}
            >
              Genera DKIM
            </MDBBtn>
          </MDBCol>
        </>
      )}
    </MDBRow>
  );

  submit = async (e) => {
    e.preventDefault();
    let { isSwDkimOn, dkimIsChanged } = this.state;
    const domain = this.props.current_domain.name;

    if (dkimIsChanged) {
      if (isSwDkimOn) {
        await this.props.createDKIM({ domain });
      } else await this.props.deleteDKIM({ domain });
    }
  };

  createDKIMWithoutDNS = async (e) => {
    e.preventDefault();
    const domain = this.props.current_domain.name;
    await this.props.createDKIM({ domain, withoutDNS: true });
    await this.props.getDomain(domain);
  };

  deleteDKIMWithoutDNS = async (e) => {
    e.preventDefault();
    const domain = this.props.current_domain.name;
    await this.props.deleteDKIM({ domain, withoutDNS: true });
    await this.props.getDomain(domain);
  };

  getSPFvalue = () => {
    if (this.state.isSwSpfOn)
      return this.state.isSPFDisabled ? this.state.spfDefault : this.state.spf;
    return " ";
  };

  getDMARCvalue = () => {
    if (this.state.isSwDmarcOn) {
      return this.state.isDMARCDisabled
        ? this.state.dmarcDefault
        : this.state.dmarc;
    }
    return " ";
  };

  renderBody = () => (
    <>
      {!config.withDNS || Object.keys(this.props.current_dns_domain).length === 0 ? (
        this.renderExternalDNS()
      ) : (
        <>
          <form onSubmit={this.submit}>
            <MDBRow>
              <MDBCol size="2" className="mt-2">
                <p>SPF</p>
              </MDBCol>
              <MDBCol size="7">
                <Input
                  id="spf_input"
                  disabled={true}
                  value={this.getSPFvalue()}
                />
              </MDBCol>
              <MDBCol size="3" className="mt-4"></MDBCol>
            </MDBRow>

            <MDBRow className="mt-3">
              <MDBCol size="2">
                <p>DMARC</p>
              </MDBCol>
              <MDBCol size="7">
                <Input
                  id="dmarc_input"
                  disabled={true}
                  value={this.getDMARCvalue()}
                />
              </MDBCol>
              <MDBCol size="3"></MDBCol>
            </MDBRow>

            <MDBRow className="mt-5">
              <MDBCol size="2" className="mt-2">
                <Switch
                  id="dkim_sw"
                  defaultChecked={this.hasDKIMRecord()}
                  label={`DKIM ${this.props.current_domain.name.replace(
                    /\./g,
                    ""
                  )}._domainkey`}
                  onChange={(e) =>
                    this.setState({
                      isSwDkimOn: e.currentTarget.checked,
                      dkimIsChanged: true,
                    })
                  }
                />
              </MDBCol>
              <MDBCol size="7" className="mt-2">
                <textarea
                  id="dkim_key_textarea"
                  disabled={true}
                  onChange={(e) =>
                    this.setState({
                      dkim: e.currentTarget.value,
                      dkimIsChanged: true,
                    })
                  }
                  value={this.state.dkim}
                  className="form-control"
                  rows="9"
                />
              </MDBCol>
            </MDBRow>
            <MDBRow className="mt-5">
              <MDBBtn className="mx-auto" type="submit">
                Salva
              </MDBBtn>
            </MDBRow>
          </form>
        </>
      )}
    </>
  );

  render = () => (
    <>
      {this.state.loaded && this.props.auth_type === "admin" ? (
        this.renderBody()
      ) : (
        <h5 className="text-center">
          <MDBIcon
            fas
            icon="exclamation-triangle"
            className="text-danger"
          ></MDBIcon>
          Impostazioni al momento non disponibili
        </h5>
      )}
    </>
  );
}
