/* eslint-disable react/display-name */
/* eslint-disable camelcase */
import React from 'react'
import { MDBIcon } from 'mdbreact'
import { Link } from 'react-router-dom'

export default props =>
	props.direction === 'left' ? (
		<Link to={props.to} className="btn-back">
			<MDBIcon icon="angle-left" size="lg" className="mr-1" />
			{props.label}
		</Link>
	) : (
		<Link to={props.to} className="btn-back">
			{props.label}
			<MDBIcon icon="angle-right" size="lg" className="ml-1" />
		</Link>
	)
