import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Component from '../components/Logs.jsx';
import * as actionCreators from '../actions';

const Container = withRouter(connect(
  (state) => {
    const props = {
      domains: state.domains,
      filtred_domains: state.filtred_domains,
      current_domain: state.current_domain,
      logs: state.logs,
      logs_filters: state.logs_filters,
      logs_limit: state.logs_limit,
      get_logs_state: state.get_logs_state,
      auth_type: state.auth_type,
      logsView: state.logsView,
    };
    return props;
  },
  actionCreators,
)(Component));

export default Container;
