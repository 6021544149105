import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Component from '../components/Statistics.jsx';
import * as actionCreators from '../actions';

const Container = withRouter(connect(
  (state) => {
    const props = {
      statistics: state.statistics,
      get_statistics_state: state.get_statistics_state,
    };
    return props;
  },
  actionCreators,
)(Component));

export default Container;
