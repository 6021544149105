/* eslint-disable import/prefer-default-export */
/* eslint-disable react/display-name */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React from 'react'
import { MDBRow, MDBCol, MDBBtn } from 'mdbreact'
import Multiselect from 'react-widgets/lib/Multiselect'
import Input from './Input.jsx'
import Switch from './Switch.jsx'

export default props => (
	<form onSubmit={props.onSubmit}>
		<MDBRow>
			<MDBCol size="12">
				<Input
					id="alias_name"
					type="text"
					label="Nome alias *"
					className="lowercase"
					placeholder="Nome alias"
					value={props.nameValue}
					onChange={props.nameChange}
					required
				/>
			</MDBCol>
		</MDBRow>
		<MDBRow className="mt-2">
			<MDBCol size="12">
				<Input id="create_alias_name" label="Nome *" placeholder="Nome dell'utente" type="text" required />
			</MDBCol>
		</MDBRow>
		<MDBRow className="mt-2">
			<MDBCol size="12">
				<Input
					id="create_alias_surname"
					label="Cognome *"
					placeholder="Cognome dell'utente"
					type="text"
					required
				/>
			</MDBCol>
		</MDBRow>
		<MDBRow className="mt-2">
			<MDBCol size="12">
				<label>Maildrop</label>
				<Multiselect
					data={props.maildropData}
					value={props.maildropValue}
					onChange={props.onMaildropChange}
					onCreate={props.onMaildropCreate}
					allowCreate={props.allowMaildropCreate}
				/>
			</MDBCol>
		</MDBRow>
		<MDBRow className="mt-2">
			<MDBCol size="12">
				{/* The following two line are used to prevent browser from autofilling Form fields */}
				<input type= "text" style={{display:"none"}}/>
				<input type="password" style={{display:"none"}}/>
				{/* end */}
				<Input id="create_alias_pw" label="Password" placeholder="Inserire password" type="password" />
			</MDBCol>
		</MDBRow>
		<MDBRow className="mt-2">
			<MDBCol size="12">
				<Input
					id="create_alias_conf_pw"
					label="Conferma Password"
					placeholder="Ripetere password"
					type="password"
				/>
			</MDBCol>
		</MDBRow>
		<MDBRow className="my-2">
			<MDBCol size="12">
				<small className="orange-text">
					La password deve essere tra 8 e 128 caratteri e non contenere gli spazi!
				</small>
			</MDBCol>
		</MDBRow>
		<Switch id="alias_greylisting" label="GreyListing" defaultChecked={true} />
		<Switch id="alias_spamchecks" label="SpamChecks" defaultChecked={true} />
		<MDBRow className="mt-2 text-center">
			<MDBCol size="12">
				<MDBBtn color="white" onClick={props.onClose}>
					Annulla
				</MDBBtn>
				<MDBBtn className="btn-rhx" type="submit" color="yellow">
					+ Crea
				</MDBBtn>
			</MDBCol>
		</MDBRow>
	</form>
)
