import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Component from '../components/DNS.jsx';
import * as actionCreators from '../actions';

const Container = withRouter(connect(
  (state) => {
    const props = {
      auth_type: state.auth_type,
      current_domain: state.current_domain,
      current_dns_domain: state.current_dns_domain,
      current_dns_zones: state.current_dns_zones,
      get_dns_zones_state: state.get_dns_zones_state,
      get_dns_domain_state: state.get_dns_domain_state,
    };
    return props;
  },
  actionCreators,
)(Component));

export default Container;
