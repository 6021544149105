import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Component from '../components/Login.jsx';
import * as actionCreators from '../actions';

const Container = withRouter(connect(
  (state) => {
    const props = {
      credentials_username: state.credentials_username,
      credentials_password: state.credentials_password,
      auth_type: state.auth_type,
      auth_state: state.auth_state,
    };
    return props;
  },
  actionCreators,
)(Component));

export default Container;
