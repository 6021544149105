import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Component from '../components/BanLogs.jsx';
import * as actionCreators from '../actions';

const Container = withRouter(connect(
  (state) => {
    const props = {
      ban_logs: state.ban_logs,
      get_ban_logs_state: state.get_ban_logs_state,
    };
    return props;
  },
  actionCreators,
)(Component));

export default Container;
