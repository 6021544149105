/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React from "react";
import { Link } from "react-router-dom";
import logo from '../../assets/img/phamm_logo.png'
import {
  MDBNav,
  MDBIcon,
  MDBNavItem,
  MDBNavbarBrand,
  MDBContainer,
} from "mdbreact";
import config from "../config";
import { parseBoolean } from "../utils";

import { version } from "../../../api-phamm/changelog.json";

export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = { tab: "domains" };
  }

  renderTabs(auth_type) {
    if (auth_type === "admin") {
      return (
        <>
          <MDBNavbarBrand>
            <img
              width="80px"
              height="80px"
              src={logo}
              className="img-fluid"
              alt=""
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title={version}
            />
          </MDBNavbarBrand>
          <MDBNavItem
            onClick={() => {
              this.setState({ tab: "domains" });
            }}
            style={{
              backgroundColor:
                this.state.tab === "domains" ? config.mainColor : "#2e3034",
            }}
          >
            <Link
              to="/domains"
              className="nav-link main-nav-link"
              style={{
                color: this.state.tab === "domains" ? "#000" : "#fff",
              }}
            >
              <MDBIcon icon="hdd" className="mr-1" />
              Domini
            </Link>
          </MDBNavItem>
          {config.withProxyTabs === false ? null : (
            <>
              <MDBNavItem
                onClick={() => {
                  this.setState({ tab: "logs" });
                }}
                style={{
                  backgroundColor:
                    this.state.tab === "logs" ? config.mainColor : "#2e3034",
                }}
              >
                <Link
                  to="/logs"
                  className="nav-link main-nav-link"
                  style={{
                    color: this.state.tab === "logs" ? "#000" : "#fff",
                  }}
                >
                  <MDBIcon icon="book" className="mr-1" /> Logs
                </Link>
              </MDBNavItem>
              <MDBNavItem
                onClick={() => {
                  this.setState({ tab: "banned_ip" });
                }}
                style={{
                  backgroundColor:
                    this.state.tab === "banned_ip" ? config.mainColor : "#2e3034",
                }}
              >
                <Link
                  to="/banned_ip"
                  className="nav-link main-nav-link"
                  style={{
                    color: this.state.tab === "banned_ip" ? "#000" : "#fff",
                  }}
                >
                  <MDBIcon icon="user-slash" className="mr-1" />
                  IP Bannati
                </Link>
              </MDBNavItem>
              <MDBNavItem
                onClick={() => {
                  this.setState({ tab: "ban_logs" });
                }}
                style={{
                  backgroundColor:
                    this.state.tab === "ban_logs" ? config.mainColor : "#2e3034",
                }}
              >
                <Link
                  to="/banned_ip/logs"
                  className="nav-link main-nav-link"
                  style={{
                    color: this.state.tab === "ban_logs" ? "#000" : "#fff",
                  }}
                >
                  <MDBIcon icon="address-book" className="mr-1" />
                  Ban Logs
                </Link>
              </MDBNavItem>
              <MDBNavItem
                onClick={() => {
                  this.setState({ tab: "whitelist_ip" });
                }}
                style={{
                  backgroundColor:
                    this.state.tab === "whitelist_ip" ? config.mainColor : "#2e3034",
                }}
              >
                <Link
                  to="/whitelist_ip"
                  className="nav-link main-nav-link"
                  style={{
                    color: this.state.tab === "whitelist_ip" ? "#000" : "#fff",
                  }}
                >
                  <MDBIcon icon="user-check" className="mr-1" />
                  WhiteList IP
                </Link>
              </MDBNavItem>
              <MDBNavItem
                onClick={() => {
                  this.setState({ tab: "blacklist_ip" });
                }}
                style={{
                  backgroundColor:
                    this.state.tab === "blacklist_ip" ? config.mainColor : "#2e3034",
                }}
              >
                <Link
                  to="/blacklist_ip"
                  className="nav-link main-nav-link"
                  style={{
                    color: this.state.tab === "blacklist_ip" ? "#000" : "#fff",
                  }}
                >
                  <MDBIcon icon="user-times" className="mr-1" />
                  BlackList IP
                </Link>
              </MDBNavItem>
              <MDBNavItem
                onClick={() => {
                  this.setState({ tab: "statistics" });
                }}
                style={{
                  backgroundColor:
                    this.state.tab === "statistics" ? config.mainColor : "#2e3034",
                  marginRight: "auto",
                }}
              >
              <Link
                  to="/statistics"
                  className="nav-link main-nav-link"
                  style={{
                    color: this.state.tab === "statistics" ? "#000" : "#fff",
                  }}
                >
                  <MDBIcon icon="chart-pie" className="mr-1" />
                  Statistiche
                </Link>
              </MDBNavItem>
            </>
          )}
          <MDBNavItem>
            <a
              id="logout"
              className="white-text nav-link main-nav-link"
              href="#"
              onClick={() => {
                this.props.logout();
              }}
            >
              {`Esci`}
              <MDBIcon icon="sign-out-alt" className="ml-1" />
            </a>
          </MDBNavItem>
        </>
      );
    }
    if (auth_type === "postmaster" && parseBoolean(config.postmasterLog)) {
      return (
        <>
        <MDBNavbarBrand>
            <img
              width="80px"
              height="80px"
              src={logo}
              className="img-fluid"
              alt=""
            />
          </MDBNavbarBrand>
          <MDBNavItem
            onClick={() => {
              this.setState({ tab: "domains" });
            }}
            style={{
              backgroundColor:
                this.state.tab === "domains" ? config.mainColor : "#2e3034",
            }}
          >
            <Link
              to={`/mailboxes/${this.props.auth_domain}`}
              className="nav-link main-nav-link"
              style={{
                color: this.state.tab === "domains" ? "#000" : "#fff",
              }}
            >
              <MDBIcon icon="hdd" className="mr-1" />
              Dominio
            </Link>
          </MDBNavItem>
          {config.withProxyTabs === false ? null : (
            <>
              <MDBNavItem
                onClick={() => {
                  this.setState({ tab: "logs" });
                }}
                style={{
                  backgroundColor:
                    this.state.tab === "logs" ? config.mainColor : "#2e3034",
                }}
              >
                <Link
                  to="/logs"
                  className="nav-link main-nav-link"
                  style={{
                    color: this.state.tab === "logs" ? "#000" : "#fff",
                  }}
                >
                  <MDBIcon icon="book" className="mr-1" /> Logs
                </Link>
              </MDBNavItem>
              </>
          )}
        <li style={{ marginLeft: "auto" }}>
          <a
            id="logout"
            className="white-text nav-link"
            href="#"
            onClick={() => {
              this.props.logout();
            }}
          >
            {`Esci da ${this.props.auth_username} (${auth_type})`}
            <MDBIcon icon="sign-out-alt" className="ml-1" />
          </a>
        </li>
        </>
      );
    }
    if (((auth_type === "postmaster") && !(parseBoolean(config.postmasterLog))) || (auth_type === "user" || auth_type === "alias")) {
      return (
        <li style={{ marginLeft: "auto" }}>
          <a
            id="logout"
            className="white-text nav-link"
            href="#"
            onClick={() => {
              this.props.logout();
            }}
          >
            {`Esci da ${this.props.auth_username} (${auth_type})`}
            <MDBIcon icon="sign-out-alt" className="ml-1" />
          </a>
        </li>
      );
    }
    return null;
  }

  render() {
    return (
      <>
        <MDBContainer fluid className="rhx-grey">
          <MDBNav className="font-weight-bold mb-4">
            {this.renderTabs(this.props.auth_type)}
          </MDBNav>
        </MDBContainer>
      </>
    );
  }
}
