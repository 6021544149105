export default {
  webmailIP: process.env.WEBMAIL_IP || '91.142.143.13',
  env: process.env.NODE_ENV,
  forPrivate: process.env.FOR_PRIVATE == 'true',
  withProxyTabs: process.env.WITH_PROXY_TABS == 'true',
  withDNS: process.env.WITH_DNS == 'true',
  logoPath: process.env.LOGO_PATH || './assets/img/logoRhxBW.svg',
  mainColor: process.env.MAIN_COLOR || '#fed300',
  postmasterLog: process.env.POSTMASTER_LOG || false,
  optionalInfo: process.env.OPTIONAL_INFO || false,
  // eslint-disable-next-line radix
  defaultQuota: parseInt(process.env.DEFAULT_MAIL_QUOTA) || 3221225472,
  expansionSlot: parseInt(process.env.EXPANSION_SLOT) || 3221225472,
  maxLimitQuota: parseInt(process.env.MAX_LIMIT_QUOTA) || 0,
  ftpDescriptionRoles: ['politico', 'po', 'servizio', 'System User', 'dipendente'],
  user_ftp_enabled: process.env.USER_FTP_ENABLED || false,
  forward_feature: process.env.FORWARD_FEATURE || "disabled",
  forward_message: process.env.FORWARD_MESSAGE || "La funzionalità 'Forward' verrà rimossa nelle versioni future. Consigliamo di passare al forward gestito tramite Webmail.",
  log_days: parseInt(process.env.LOG_DAYS) || 7,
};
