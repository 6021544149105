/* eslint-disable import/prefer-default-export */
/* eslint-disable react/display-name */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React from 'react'
import { MDBRow, MDBCol, MDBBtn } from 'mdbreact'
import Input from './Input.jsx'

export default props => (
	<form onSubmit={props.onSubmit}>
		<MDBRow>
			<MDBCol size="12">
				<h6>Proprietà del dominio</h6>
				<Input
					id="create_domain_vd"
					type="text"
					defaultValue={props.nameValue}
					label="Nome del dominio"
					className="lowercase"
					placeholder="Nome dominio"
					required
				/>
			</MDBCol>
		</MDBRow>
		<MDBRow>
			<MDBCol size="6">
				<Input id="create_domain_maxAntivirus" label="Max Antivirus" defaultValue="0" type="number" required />
			</MDBCol>
			<MDBCol size="6">
				<Input id="create_domain_maxGreyList" label="Max GreyList" type="number" defaultValue="0" required />
			</MDBCol>
		</MDBRow>
		<MDBRow>
			<MDBCol size="6">
				<Input id="create_domain_maxMail" label="Max Mail" type="number" defaultValue="0" required />
			</MDBCol>
			<MDBCol size="6">
				<Input id="create_domain_maxSmtpAuth" label="Max Smtp Auth" type="number" defaultValue="0" required />
			</MDBCol>
		</MDBRow>
		<hr />
		<MDBRow>
			<MDBCol size="12">
				<h6>Proprietà di postmaster</h6>
				<Input
					id="create_domain_homeDirectory"
					label="home Directory"
					defaultValue={`/srv/www/${props.nameValue}`}
					type="text"
					required
				/>
			</MDBCol>
		</MDBRow>
		<MDBRow>
			<MDBCol size="6">
				<Input id="create_domain_uidNumber" label="UID number" type="number" required />
			</MDBCol>
			<MDBCol size="6">
				<Input id="create_domain_gidNumber" label="GID number" type="number" required />
			</MDBCol>
		</MDBRow>
		<MDBRow>
			<MDBCol size="6">
				{/* The following two line are used to prevent browser from autofilling Form fields */}
				<input type= "text" style={{display:"none"}}/>
				<input type="password" style={{display:"none"}}/>
				{/* end */}
				<Input
					id="create_domain_userPassword"
					type="password"
					label="Password"
					pattern="^(?!.* ).{8,128}$"
					required
				/>
			</MDBCol>
			<MDBCol size="6">
				<Input
					id="create_domain_confPassword"
					label="Conferma Password"
					type="password"
					pattern="^(?!.* ).{8,128}$"
					required
				/>
			</MDBCol>
		</MDBRow>
		<small className="orange-text">La password deve essere tra 8 e 128 caratteri e non contenere spazi.</small>
		<MDBBtn color="yellow" className="btn-rhx mt-4" type="submit" block>
			Crea Dominio
		</MDBBtn>
	</form>
)
