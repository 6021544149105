/* eslint-disable react/jsx-key */
import React from 'react'
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBIcon } from 'mdbreact'

const DropdownPage = props => (
	<MDBDropdown>
		<MDBDropdownToggle color="light-grey" size="sm" block>
			<MDBIcon fas icon="info-circle" color="black" size="lg" />
		</MDBDropdownToggle>
		<MDBDropdownMenu basic>
			{props.items.map(item => (
				<MDBDropdownItem disabled>
					<small>{item}</small>
				</MDBDropdownItem>
			))}
		</MDBDropdownMenu>
	</MDBDropdown>
)

export default DropdownPage
