import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Component from '../components/MailBoxes.jsx';
import * as actionCreators from '../actions';

const Container = withRouter(connect(
  (state) => {
    const props = {
      mailboxes: state.mailboxes,
      mail_aliases: state.mail_aliases,
      current_domain: state.current_domain,
      logsView: state.logsView,
      current_ftp_user: state.current_ftp_user,
      create_mail_state: state.create_mail_state,
      delete_mail_state: state.delete_mail_state,
      modify_ftp_user_state: state.modify_ftp_user_state,
      modify_domain_state: state.modify_domain_state,
      auth_type: state.auth_type,
      current_virtual_backup_mail: state.current_virtual_backup_mail,

    };
    return props;
  },
  actionCreators,
)(Component));

export default Container;
