/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
/* eslint-disable camelcase */
import React from 'react'
import { MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBBtn, MDBIcon } from 'mdbreact'

export default class Modal extends React.Component {
	render = () => (
		<MDBModal size={this.props.size} isOpen={this.props.isOpen} toggle={this.props.onClose}>
			<MDBModalHeader toggle={this.props.onClose}>{this.props.title}</MDBModalHeader>
			<MDBModalBody>
				{this.props.content ? <h5><pre>{this.props.content}</pre></h5> : null}
				{this.props.content ? <br></br> : null}
				{this.props.message}
			</MDBModalBody>
			{!this.props.withoutFooter ? (
				<MDBModalFooter>
					<MDBBtn flat className="btn-danger" onClick={this.props.onClose}>
						<MDBIcon icon="times"></MDBIcon> {this.props.closeTitle ? this.props.closeTitle : 'No'}
					</MDBBtn>
					{this.props.onConfirm ? (
						<MDBBtn flat className="btn-success" onClick={this.props.onConfirm}>
							<MDBIcon icon="check"></MDBIcon> {this.props.confirmTitle ? this.props.confirmTitle : 'Si'}
						</MDBBtn>
					) : null}
				</MDBModalFooter>
			) : null}
		</MDBModal>
	)
}
