/* eslint-disable no-tabs */
/* eslint-disable import/prefer-default-export */
/* eslint-disable react/display-name */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React from 'react';
import { MDBRow, MDBCol, MDBBtn } from 'mdbreact';
import Input from './Input.jsx';

export default props => (
	<form onSubmit={props.onSubmit}>
		<MDBRow className="mt-2">
			<MDBCol size="12">
				<Input
					id="create_postfixTransport"
					label="postfixTransport *"
					placeholder="postfixTransport"
					type="text"
					required
					value=""
					
				/>
			</MDBCol>
		</MDBRow>
		<MDBRow className="mt-2 text-center">
			<MDBCol size="12">
				<MDBBtn color="white" onClick={props.onClose}>
					Annulla
				</MDBBtn>
				<MDBBtn className="btn-rhx" color="yellow" type="submit">
					+ Crea
				</MDBBtn>
			</MDBCol>
		</MDBRow>
	</form>
)
