/* eslint-disable no-throw-literal */
/* eslint-disable camelcase */
import { createAction } from 'redux-actions';
import ax from 'axios';
import { toast } from 'react-toastify';
import routes from '../config/routes';

const download = require('downloadjs')

const axios = ax.create({
  withCredentials: true,
});
// eslint-disable-next-line import/prefer-default-export
// export const setFormValues = createAction('FORM_VALUES_SET');
// export const setSslCheck = createAction('SSL_CHECK_SET');

export const setCredentials = createAction('CREDENTIALS_SET');
export const setFiltredDomains = createAction('DOMAINS_SET');
export const getDomainsRequest = createAction('DOMAINS_SEND_REQUEST');
export const getDomainsSuccess = createAction('DOMAINS_SEND_SUCCESS');
export const getDomainsFailure = createAction('DOMAINS_SEND_FAILURE');

export const authRequest = createAction('AUTH_SEND_REQUEST');
export const authSuccess = createAction('AUTH_SEND_SUCCESS');
export const authFailure = createAction('AUTH_SEND_FAILURE');

export const getCurrentSessionRequest = createAction('CURRENT_SESSION_GET_REQUEST');
export const getCurrentSessionSuccess = createAction('CURRENT_SESSION_GET_SUCCESS');
export const getCurrentSessionFailure = createAction('CURRENT_SESSION_GET_FAILURE');

export const logoutRequest = createAction('LOGOUT_SEND_REQUEST');
export const logoutSuccess = createAction('LOGOUT_SEND_SUCCESS');
export const logoutFailure = createAction('LOGOUT_SEND_FAILURE');

export const getMailboxesRequest = createAction('MAILBOXES_SEND_REQUEST');
export const getMailboxesSuccess = createAction('MAILBOXES_SEND_SUCCESS');
export const getMailboxesFailure = createAction('MAILBOXES_SEND_FAILURE');

export const getMailAliasesRequest = createAction('MAIL_ALIASES_SEND_REQUEST');
export const getMailAliasesSuccess = createAction('MAIL_ALIASES_SEND_SUCCESS');
export const getMailAliasesFailure = createAction('MAIL_ALIASES_SEND_FAILURE');

export const getDNSDomainRequest = createAction('DNS_DOMAIN_GET_REQUEST');
export const getDNSDomainSuccess = createAction('DNS_DOMAIN_GET_SUCCESS');
export const getDNSDomainFailure = createAction('DNS_DOMAIN_GET_FAILURE');

export const getDNSZonesRequest = createAction('DNS_ZONES_GET_REQUEST');
export const getDNSZonesSuccess = createAction('DNS_ZONES_GET_SUCCESS');
export const getDNSZonesFailure = createAction('DNS_ZONES_GET_FAILURE');

export const deleteDNSZoneRequest = createAction('DNS_ZONE_DELETE_REQUEST');
export const deleteDNSZoneSuccess = createAction('DNS_ZONE_DELETE_SUCCESS');
export const deleteDNSZoneFailure = createAction('DNS_ZONE_DELETE_FAILURE');

export const createDNSZoneRequest = createAction('DNS_ZONE_CREATE_REQUEST');
export const createDNSZoneSuccess = createAction('DNS_ZONE_CREATE_SUCCESS');
export const createDNSZoneFailure = createAction('DNS_ZONE_CREATE_FAILURE');

export const modifyDNSZoneRequest = createAction('DNS_ZONE_MODIFY_REQUEST');
export const modifyDNSZoneSuccess = createAction('DNS_ZONE_MODIFY_SUCCESS');
export const modifyDNSZoneFailure = createAction('DNS_ZONE_MODIFY_FAILURE');

export const createDKIMRequest = createAction('DKIM_CREATE_REQUEST');
export const createDKIMSuccess = createAction('DKIM_CREATE_SUCCESS');
export const createDKIMFailure = createAction('DKIM_CREATE_FAILURE');

export const deleteDKIMRequest = createAction('DKIM_DELETE_REQUEST');
export const deleteDKIMSuccess = createAction('DKIM_DELETE_SUCCESS');
export const deleteDKIMFailure = createAction('DKIM_DELETE_FAILURE');

export const getMailboxRequest = createAction('MAILBOX_SEND_REQUEST');
export const getMailboxSuccess = createAction('MAILBOX_SEND_SUCCESS');
export const getMailboxFailure = createAction('MAILBOX_SEND_FAILURE');

export const getMailAliasRequest = createAction('MAIL_ALIAS_SEND_REQUEST');
export const getMailAliasSuccess = createAction('MAIL_ALIAS_SEND_SUCCESS');
export const getMailAliasFailure = createAction('MAIL_ALIAS_SEND_FAILURE');

export const getVirtualBackupMailRequest = createAction('VIRTUAL_BACKUP_MAIL_GET_REQUEST');
export const getVirtualBackupMailSuccess = createAction('VIRTUAL_BACKUP_MAIL_GET_SUCCESS');
export const getVirtualBackupMailFailure = createAction('VIRTUAL_BACKUP_MAIL_GET_FAILURE');

export const deleteVirtualBackupMailRequest = createAction('VIRTUAL_BACKUP_MAIL_DELETE_REQUEST');
export const deleteVirtualBackupMailSuccess = createAction('VIRTUAL_BACKUP_MAIL_DELETE_SUCCESS');
export const deleteVirtualBackupMailFailure = createAction('VIRTUAL_BACKUP_MAIL_DELETE_FAILURE');

export const createVirtualBackupMailRequest = createAction('VIRTUAL_BACKUP_MAIL_CREATE_REQUEST');
export const createVirtualBackupMailSuccess = createAction('VIRTUAL_BACKUP_MAIL_CREATE_SUCCESS');
export const createVirtualBackupMailFailure = createAction('VIRTUAL_BACKUP_MAIL_CREATE_FAILURE');

export const getCsvRequest = createAction('GET_CSV_REQUEST');
export const getCsvSuccess = createAction('GET_CSV_SUCCESS');
export const getCsvFailure = createAction('GET_CSV_FAILURE');

export const createMailboxRequest = createAction('MAILBOX_CREATE_REQUEST');
export const createMailboxSuccess = createAction('MAILBOX_CREATE_SUCCESS');
export const createMailboxFailure = createAction('MAILBOX_CREATE_FAILURE');

export const createCatchAllRequest = createAction('CATCH_ALL_CREATE_REQUEST');
export const createCatchAllSuccess = createAction('CATCH_ALL_CREATE_SUCCESS');
export const createCatchAllFailure = createAction('CATCH_ALL_CREATE_FAILURE');

export const deleteMailboxRequest = createAction('MAILBOX_DELETE_REQUEST');
export const deleteMailboxSuccess = createAction('MAILBOX_DELETE_SUCCESS');
export const deleteMailboxFailure = createAction('MAILBOX_DELETE_FAILURE');

export const modifyMailboxRequest = createAction('MAILBOX_MODIFY_REQUEST');
export const modifyMailboxSuccess = createAction('MAILBOX_MODIFY_SUCCESS');
export const modifyMailboxFailure = createAction('MAILBOX_MODIFY_FAILURE');

export const modifyMailAliasRequest = createAction('MAIL_ALIAS_MODIFY_REQUEST');
export const modifyMailAliasSuccess = createAction('MAIL_ALIAS_MODIFY_SUCCESS');
export const modifyMailAliasFailure = createAction('MAIL_ALIAS_MODIFY_FAILURE');

export const modifyCatchAllRequest = createAction('MAIL_ALIAS_MODIFY_REQUEST');
export const modifyCatchAllSuccess = createAction('MAIL_ALIAS_MODIFY_SUCCESS');
export const modifyCatchAllFailure = createAction('MAIL_ALIAS_MODIFY_FAILURE');

export const createMailAliasRequest = createAction('MAIL_ALIAS_CREATE_REQUEST');
export const createMailAliasSuccess = createAction('MAIL_ALIAS_CREATE_SUCCESS');
export const createMailAliasFailure = createAction('MAIL_ALIAS_CREATE_FAILURE');

export const getDomainRequest = createAction('DOMAIN_GET_REQUEST');
export const getDomainSuccess = createAction('DOMAIN_GET_SUCCESS');
export const getDomainFailure = createAction('DOMAIN_GET_FAILURE');

export const createDomainRequest = createAction('DOMAIN_CREATE_REQUEST');
export const createDomainSuccess = createAction('DOMAIN_CREATE_SUCCESS');
export const createDomainFailure = createAction('DOMAIN_CREATE_FAILURE');

export const deleteDomainRequest = createAction('DOMAIN_DELETE_REQUEST');
export const deleteDomainSuccess = createAction('DOMAIN_DELETE_SUCCESS');
export const deleteDomainFailure = createAction('DOMAIN_DELETE_FAILURE');

export const getFTPUserRequest = createAction('FTP_USER_GET_REQUEST');
export const getFTPUserSuccess = createAction('FTP_USER_GET_SUCCESS');
export const getFTPUserFailure = createAction('FTP_USER_GET_FAILURE');

export const modifyFTPUserRequest = createAction('FTP_USER_MODIFY_REQUEST');
export const modifyFTPUserSuccess = createAction('FTP_USER_MODIFY_SUCCESS');
export const modifyFTPUserFailure = createAction('FTP_USER_MODIFY_FAILURE');

export const getBannedIPRequest = createAction('BANNED_IP_GET_REQUEST');
export const getBannedIPSuccess = createAction('BANNED_IP_GET_SUCCESS');
export const getBannedIPFailure = createAction('BANNED_IP_GET_FAILURE');

export const getWhiteListIPRequest = createAction('WHITELIST_IP_GET_REQUEST');
export const getWhiteListIPSuccess = createAction('WHITELIST_IP_GET_SUCCESS');
export const getWhiteListIPFailure = createAction('WHITELIST_IP_GET_FAILURE');

export const getBlackListIPRequest = createAction('BLACKLIST_IP_GET_REQUEST');
export const getBlackListIPSuccess = createAction('BLACKLIST_IP_GET_SUCCESS');
export const getBlackListIPFailure = createAction('BLACKLIST_IP_GET_FAILURE');

export const modifyBannedIPRequest = createAction('BANNED_IP_MODIFY_REQUEST');
export const modifyBannedIPSuccess = createAction('BANNED_IP_MODIFY_SUCCESS');
export const modifyBannedIPFailure = createAction('BANNED_IP_MODIFY_FAILURE');

export const modifyWhiteListIPRequest = createAction('WHITELIST_IP_MODIFY_REQUEST');
export const modifyWhiteListIPSuccess = createAction('WHITELIST_IP_MODIFY_SUCCESS');
export const modifyWhiteListIPFailure = createAction('WHITELIST_IP_MODIFY_FAILURE');

export const modifyBlackListIPRequest = createAction('BLACKLIST_IP_MODIFY_REQUEST');
export const modifyBlackListIPSuccess = createAction('BLACKLIST_IP_MODIFY_SUCCESS');
export const modifyBlackListIPFailure = createAction('BLACKLIST_IP_MODIFY_FAILURE');

export const modifyDomainRequest = createAction('DOMAIN_MODIFY_REQUEST');
export const modifyDomainSuccess = createAction('DOMAIN_MODIFY_SUCCESS');
export const modifyDomainFailure = createAction('DOMAIN_MODIFY_FAILURE');

export const modifyDNSDomainRequest = createAction('DNS_DOMAIN_MODIFY_REQUEST');
export const modifyDNSDomainSuccess = createAction('DNS_DOMAIN_MODIFY_SUCCESS');
export const modifyDNSDomainFailure = createAction('DNS_DOMAIN_MODIFY_FAILURE');

export const getLogsRequest = createAction('LOGS_GET_REQUEST');
export const getLogsSuccess = createAction('LOGS_GET_SUCCESS');
export const getLogsFailure = createAction('LOGS_GET_FAILURE');

export const getStatisticsRequest = createAction('STATISTICS_GET_REQUEST');
export const getStatisticsSuccess = createAction('STATISTICS_GET_SUCCESS');
export const getStatisticsFailure = createAction('STATISTICS_GET_FAILURE');

export const getBanLogsRequest = createAction('BAN_LOGS_GET_REQUEST');
export const getBanLogsSuccess = createAction('BAN_LOGS_GET_SUCCESS');
export const getBanLogsFailure = createAction('BAN_LOGS_GET_FAILURE');

export const setCurrentMail = createAction('CURRENT_MAIL_SET');
export const setCurrentDomain = createAction('CURRENT_DOMAIN_SET');
export const setCurrentMailAlias = createAction('CURRENT_MAIL_ALIAS_SET');
export const setCurrentFTPUser = createAction('CURRENT_FTP_USER_SET');

export const getLogsViewSuccess = createAction('LOGS_VIEW_GET_SUCCESS');
export const getLogsViewFailure = createAction('LOGS_VIEW_GET_FAILURE');
export const setLogsView = createAction('LOGS_VIEW_SET');

export const setModifyDomainState = createAction('SET_MODIFY_DOMAIN_STATE');
export const setDeleteDomainState = createAction('SET_DELETE_DOMAIN_STATE');
export const setCreateMailboxState = createAction('SET_CREATE_MAIL_STATE');
export const setDeleteMailboxState = createAction('SET_DELETE_MAIL_STATE');
export const setModifyMailboxState = createAction('SET_MODIFY_MAIL_STATE');
export const setAuthState = createAction('AUTH_SET_STATE');
export const setDomains = createAction('DOMAINS_SET_STATE');

export const setLogsFilters = createAction('LOGS_FILTERS_SET');
export const setLogsLimit = createAction('LOGS_LIMIT_SET');

export const getDomains = () => async (dispatch) => {
  dispatch(getDomainsRequest());
  const url = routes.apiDomains();
  try {
    const response = await axios.get(url);
    const domains = response.data;
    dispatch(getDomainsSuccess({ domains }));
  } catch (error) {
    console.log(error);
    dispatch(getDomainsFailure({ domains: [] }));
  }
};

export const getLogs = logsData => async (dispatch) => {
  dispatch(getLogsRequest());
  const url = routes.logs();
  try {
    const response = await axios.post(url, logsData);
    const logs = response.data;
    dispatch(getLogsSuccess({ logs }));
  } catch (error) {
    console.log(error);
    dispatch(getLogsFailure({ logs: [] }));
    
  }
};

export const getStatistics = (hoursRange = null) => async (dispatch) => {
  dispatch(getStatisticsRequest());
  const url = routes.statistics();
  try {
    const response = await axios.get(url, { params: { hoursRange}});
    const { dataLine, dataPie, dataProtocols } = response.data;
    dispatch(getStatisticsSuccess({ dataLine, dataPie, dataProtocols }));
  } catch (error) {
    console.log(error);
    dispatch(getStatisticsFailure({ }));
    
  }
};

export const getBanLogs = logsData => async (dispatch) => {
  dispatch(getBanLogsRequest());
  const url = routes.banLogs();
  try {
    const response = await axios.post(url, logsData);
    const logs = response.data;
    dispatch(getBanLogsSuccess({ logs }));
  } catch (error) {
    console.log(error);
    dispatch(getBanLogsFailure({ logs: [] }));
  }
};

export const getBannedIP = (page, limit, filters) => async (dispatch) => {
  dispatch(getBannedIPRequest());
  const url = routes.banned_ip();
  try {
    const response = await axios.get(url, {
      params: {
        page,
        limit,
        filters
      }
    });
    const {rows, count, backup_db} = response.data;
    dispatch(getBannedIPSuccess({ banned_ip: rows, count, backup_db}));
  } catch (error) {
    console.log(error);
    dispatch(getBannedIPFailure({ banned_ip: [], count: 0, backup_db:false }));
  }
};

export const getWhiteListIP = (filter, description) => async (dispatch) => {
  dispatch(getWhiteListIPRequest());
  const url = routes.whitelist_ip();
  try {
    const response = await axios.get(url,{
      params: {
        filter,
        description
      }
    });
    //const response = await axios.get(url);
    const whitelist_ip = response.data;
    dispatch(getWhiteListIPSuccess({ whitelist_ip }));
  } catch (error) {
    console.log(error);
    dispatch(getWhiteListIPFailure({ whitelist_ip: [] }));
  }
};

export const getBlackListIP = (filter, description) => async (dispatch) => {
  dispatch(getBlackListIPRequest());
  const url = routes.blacklist_ip();
  try {
    const response = await axios.get(url,{
      params: {
        filter,
        description
      }
    });
    //const response = await axios.get(url);
    const blacklist_ip = response.data;
    dispatch(getBlackListIPSuccess({ blacklist_ip }));
  } catch (error) {
    console.log(error);
    dispatch(getBlackListIPFailure({ blacklist_ip: [] }));
  }
};

export const modifyBannedIP = ipData => async (dispatch) => {
  dispatch(modifyBannedIPRequest());
  const url = routes.banned_ip();
  try {
    await axios.post(url, ipData);
    dispatch(modifyBannedIPSuccess());
    toast.success('IP modificato con successo!');
  } catch (error) {
    console.log(error);
    toast.error('IP non modificato. Errore');
    dispatch(modifyBannedIPFailure());
  }
};

export const modifyWhiteListIP = ipData => async (dispatch) => {
  dispatch(modifyWhiteListIPRequest());
  const url = routes.whitelist_ip();
  try {
    await axios.post(url, ipData);
    dispatch(modifyWhiteListIPSuccess());
    toast.success('IP modificato con successo!');
  } catch (error) {
    console.log(error);
    toast.error('IP non modificato. Errore');
    dispatch(modifyWhiteListIPFailure());
  }
};


export const modifyBlackListIP = ipData => async (dispatch) => {
  dispatch(modifyBlackListIPRequest());
  const url = routes.blacklist_ip();
  try {
    await axios.post(url, ipData);
    dispatch(modifyBlackListIPSuccess());
    toast.success('IP modificato con successo!');
  } catch (error) {
    console.log(error);
    toast.error('IP non modificato. Errore');
    dispatch(modifyBlackListIPFailure());
  }
};


export const getDomain = domainName => async (dispatch) => {
  dispatch(getDomainRequest());
  const url = routes.domain_base(domainName);
  try {
    const response = await axios.get(url);
    const domain = response.data;
    dispatch(getDomainSuccess({ domain }));
  } catch (error) {
    console.log(error);
    dispatch(getDomainFailure());
  }
};

export const deleteDomain = dn => async (dispatch) => {
  dispatch(deleteDomainRequest());
  const url = routes.domain();
  try {
    await axios.delete(url, { data: dn });
    dispatch(deleteDomainSuccess());
    toast.success('Dominio cancellato con successo!');
  } catch (error) {
    console.log(error);
    toast.error('Errore! Dominio non cancellato!');
    dispatch(deleteDomainFailure());
  }
};

export const getFTPUser = domainName => async (dispatch) => {
  dispatch(getFTPUserRequest());
  const url = routes.ftp_user(domainName);
  try {
    const response = await axios.get(url);
    const ftpUser = response.data;
    dispatch(getFTPUserSuccess({ ftpUser }));
  } catch (error) {
    console.log(error);
    dispatch(getFTPUserFailure());
  }
};

export const modifyFTPUser = ftpData => async (dispatch) => {
  dispatch(modifyFTPUserRequest());
  const url = routes.ftp_user_put();
  try {
    if (ftpData.options.userPassword && ftpData.options.userPassword !== ftpData.options.conf_password) throw 'Wrong Password';
    if (ftpData.options.conf_password && ftpData.options.userPassword !== ftpData.options.conf_password) throw 'Wrong Password';
    
    await axios.put(url, ftpData);
    dispatch(modifyFTPUserSuccess());
    toast.success('Proprietà postmaster modificate con successo!');
  } catch (error) {
    console.log(error);
    toast.error('Proprietà postmaster: Errore, modifiche non salvate!');
    dispatch(modifyFTPUserFailure());
  }
};

export const modifyDomain = domainData => async (dispatch) => {
  dispatch(modifyDomainRequest());
  const url = routes.domain();
  try {
    await axios.put(url, domainData);
    dispatch(modifyDomainSuccess());
    toast.success('Proprietà dominio modificate con successo!');
  } catch (error) {
    console.log(error);
    toast.error('Proprietà dominio: Errore, modifiche non salvate!');
    dispatch(modifyDomainFailure());
  }
};

export const createDomain = ({ domain, options }) => async (dispatch) => {
  dispatch(createDomainRequest());
  const url = routes.domain();
  try {
    if (options.postmasterOptions.userPassword !== options.postmasterOptions.confPassword) throw 'Wrong Password';
    await axios.post(url, { domain, options });
    dispatch(createDomainSuccess());
    toast.success(`Dominio ${domain} creato con successo!`);
  } catch (error) {
    console.log(error);
    toast.error(`Dominio ${domain} non è stato creato a causa di un errore!`);
    dispatch(createDomainFailure());
  }
};

export const getMailboxes = domain => async (dispatch) => {
  dispatch(getMailboxesRequest());
  const url = routes.mailboxes(domain);
  try {
    const response = await axios.get(url);
    const mailboxes = response.data;
    dispatch(getMailboxesSuccess({ mailboxes }));
  } catch (error) {
    console.log(error);
    dispatch(getMailboxesFailure({ mailboxes: [] }));
  }
};

export const getCsv = (domain, aliases = false) => async (dispatch) => {
  dispatch(getCsvRequest());
  let url = routes.csv(domain);
  if (aliases === true) url = `${url}?aliases=true`;
  try {
    const response = await axios.get(url);
    download(response.data , `${domain}_mailboxes.csv`, 'text/csv');
    dispatch(getCsvSuccess());
  } catch (error) {
    console.log(error);
    dispatch(getCsvFailure());
  }
};

export const getMailAliases = domain => async (dispatch) => {
  dispatch(getMailAliasesRequest());
  const url = routes.mailAliases(domain);
  try {
    const response = await axios.get(url);
    const mailAliases = response.data;
    dispatch(getMailAliasesSuccess({ mailAliases }));
  } catch (error) {
    console.log(error);
    dispatch(getMailAliasesFailure({ mailAliases: [] }));
  }
};

export const getMailbox = mail => async (dispatch) => {
  dispatch(getMailboxRequest());
  const url = routes.mailbox_base(mail);
  try {
    const response = await axios.get(url);
    const mailbox = response.data;
    dispatch(getMailboxSuccess({ mailbox }));
  } catch (error) {
    console.log(error);
    dispatch(getMailboxFailure());
  }
};

export const getVirtualBackupMail = domain => async (dispatch) => {
  dispatch(getVirtualBackupMailRequest());
  const url = routes.virtualBackupMail(domain);
  try {
    const response = await axios.get(url);
    const virtualBackupMail = response.data;
    dispatch(getVirtualBackupMailSuccess({ virtualBackupMail }));
  } catch (error) {
    console.log(error);
    dispatch(getVirtualBackupMailFailure());
  }
};

export const deleteVirtualBackupMail = data => async (dispatch) => {
  dispatch(deleteVirtualBackupMailRequest());
  const url = routes.deleteVirtualBackupMail();
  try {
    await axios.delete(url, { data });
    dispatch(deleteVirtualBackupMailSuccess({}));
    toast.success('VirtualBackupMail cancellato con successo!');
  } catch (error) {
    console.log(error);
    toast.error('Errore: VirtualBackupMail email non è stato cancellato!');
    dispatch(deleteVirtualBackupMailFailure());
  }
};

export const createVirtualBackupMail = ({ domain, postfixTransport }) => async (dispatch) => {
  dispatch(createVirtualBackupMailRequest());
  const url = routes.deleteVirtualBackupMail();
  try {
    await axios.post(url, { domain, postfixTransport });
    dispatch(createVirtualBackupMailSuccess());
    toast.success('VirtualBackupMail creato con successo!');
  } catch (error) {
    console.log(error);
    toast.error('Errore. VirtualBackupMail non creato!');
    dispatch(createVirtualBackupMailFailure());
  }
};

export const getMailAlias = alias => async (dispatch) => {
  dispatch(getMailAliasRequest());
  const url = routes.mail_alias_base(alias);
  try {
    const response = await axios.get(url);
    const mailAlias = response.data;
    dispatch(getMailAliasSuccess({ mailAlias }));
  } catch (error) {
    console.log(error);
    dispatch(getMailAliasFailure());
  }
};


export const createMailbox = data => async (dispatch) => {
  dispatch(createMailboxRequest());
  const url = routes.mailbox();
  try {
    const mailData = data;
    if (mailData.password && mailData.password !== mailData.conf_password) throw 'Wrong Password';
    if (mailData.conf_password && mailData.password !== mailData.conf_password) throw 'Wrong Password';
    else {
      mailData.pw = mailData.password;
      delete mailData.password;
      delete mailData.conf_password;
    }
    await axios.post(url, mailData);
    dispatch(createMailboxSuccess());
    toast.success('Casella email creata con successo!');
  } catch (error) {
    console.log(error);
    switch(error.response.status){
      case 409 :
        toast.error("Conflitto, email o alias già esistente!");
        break;
      case 422 :
        toast.error("Errore di sintassi, caratteri non ammessi!");
        break;
      default: 
        toast.error('Creazione casella email fallita!'); 
    }
    dispatch(createMailboxFailure());
  }
};

export const modifyMailbox = mailData => async (dispatch) => {
  dispatch(modifyMailboxRequest());
  const url = routes.mailbox();
  try {
    if (mailData.options.userPassword && mailData.options.userPassword !== mailData.options.conf_password) throw 'Wrong Password';
    if (mailData.options.conf_password && mailData.options.userPassword !== mailData.options.conf_password) throw 'Wrong Password';
    await axios.put(url, mailData);
    dispatch(modifyMailboxSuccess());
    toast.success('Casella email modificata con successo!');
  } catch (error) {
    console.log(error);
    toast.error('Errore! Modifiche della casella email non eseguite!');
    dispatch(modifyMailboxFailure());
  }
};

export const modifyMailAlias = mailData => async (dispatch) => {
  dispatch(modifyMailAliasRequest());
  const url = routes.alias();
  try {
    if (mailData.options.userPassword && mailData.options.userPassword !== mailData.options.conf_password) throw 'Wrong Password';
    if (mailData.options.conf_password && mailData.options.userPassword !== mailData.options.conf_password) throw 'Wrong Password';
    await axios.put(url, mailData);
    dispatch(modifyMailAliasSuccess());
    toast.success('Email Alias modificato con successo!');
  } catch (error) {
    console.log(error);
    toast.error("Errore! Modifiche dell'alias non eseguite !");
    dispatch(modifyMailAliasFailure());
  }
};

export const modifyCatchAll = mailData => async (dispatch) => {
  dispatch(modifyCatchAllRequest());
  const url = routes.catchAll();
  try {
    if (mailData.options.userPassword && mailData.options.userPassword !== mailData.options.conf_password) throw 'Wrong Password';
    if (mailData.options.conf_password && mailData.options.userPassword !== mailData.options.conf_password) throw 'Wrong Password';
    await axios.put(url, mailData);
    dispatch(modifyCatchAllSuccess());
    toast.success('Email Alias modificato con successo!');
  } catch (error) {
    console.log(error);
    toast.error("Errore! Modifiche dell'alias non eseguite !");
    dispatch(modifyCatchAllFailure());
  }
};

export const createMailAlias = data => async (dispatch) => {
  dispatch(createMailAliasRequest());
  const url = routes.alias();
  try {
    const mailData = data;
    if (mailData.password && mailData.password !== mailData.conf_password) throw 'Wrong Password';
    if (mailData.conf_password && mailData.password !== mailData.conf_password) throw 'Wrong Password';
    if (mailData.password) mailData.userPassword = mailData.password;
    delete mailData.password;
    delete mailData.conf_password;
    await axios.post(url, mailData);
    dispatch(createMailAliasSuccess());
    toast.success('Alias creato con successo!');
  } catch (error) {
    console.log(error);
    switch(error.response.status){
      case 409 :
        toast.error("Conflitto, email o alias già esistente!");
        break;
      case 422 :
        toast.error("Errore di sintassi, caratteri non ammessi!");
        break;
      default: 
        toast.error('Creazione alias fallita!'); 
    }
    dispatch(createMailAliasFailure());
  }
};

export const createCatchAll = data => async (dispatch) => {
  dispatch(createCatchAllRequest());
  const url = routes.catchAll();
  try {
    const mailData = data;
    if (mailData.password && mailData.password !== mailData.conf_password) throw 'Wrong Password';
    if (mailData.conf_password && mailData.password !== mailData.conf_password) throw 'Wrong Password';
    if (mailData.password) mailData.userPassword = mailData.password;
    delete mailData.password;
    delete mailData.conf_password;
    await axios.post(url, mailData);
    dispatch(createCatchAllSuccess());
    toast.success('Catch-all alias creato con successo!');
  } catch (error) {
    console.log(error);
    toast.error('Creazione Catch-all alias fallita!');
    dispatch(createCatchAllFailure());
  }
};

export const deleteMailbox = mailData => async (dispatch) => {
  dispatch(deleteMailboxRequest());
  const url = routes.mailbox();
  try {
    await axios.delete(url, { data: mailData });
    dispatch(deleteMailboxSuccess());
    toast.success('Casella email cancellata con successo!');
  } catch (error) {
    console.log(error);
    toast.error('Errore: Casella email non è stata cancellata!');
    dispatch(deleteMailboxFailure());
  }
};

export const getDNSDomain = ({ domain }) => async (dispatch) => {
  dispatch(getDNSDomainRequest());
  const url = routes.dnsDomain(domain);
  try {
    const { data } = await axios.get(url);
    dispatch(getDNSDomainSuccess({ domain: data }));
  } catch (error) {
    console.log(error);
    dispatch(getDNSDomainFailure({ domain: {} }));
  }
};

export const modifyDNSDomain = ({ domain, operation, options }) => async (dispatch) => {
  dispatch(modifyDNSDomainRequest());
  const url = routes.dnsDomain(domain);
  try {
    await axios.put(url, { domain, operation, options });
    dispatch(modifyDNSDomainSuccess());
    toast.success('DNS Record modificato con successo!');
  } catch (error) {
    console.log(error);
    toast.error(`Errore: operation: ${operation} , attributes: ${JSON.stringify(options)}!`);
    dispatch(modifyDNSDomainFailure());
  }
};

export const getDNSZones = ({ domain }) => async (dispatch) => {
  dispatch(getDNSZonesRequest());
  const url = routes.dnsZones(domain);
  try {
    const { data } = await axios.get(url);
    dispatch(getDNSZonesSuccess({ zones: data }));
  } catch (error) {
    console.log(error);
    dispatch(getDNSZonesFailure({ zones: [] }));
  }
};

export const deleteDNSZone = ({ domain, zone }) => async (dispatch) => {
  dispatch(deleteDNSZoneRequest());
  const url = routes.dnsZone();
  try {
    await axios.delete(url, { data: { domain, zone } });
    dispatch(deleteDNSZoneSuccess());
    toast.success('Zona DNS cancellata con successo!');
  } catch (error) {
    console.log(error);
    toast.error('Errore: Zona DNS non è stata cancellata!');
    dispatch(deleteDNSZoneFailure());
  }
};


export const modifyDNSZone = ({ domain, zone, options }) => async (dispatch) => {
  dispatch(modifyDNSZoneRequest());
  const url = routes.dnsZone();
  try {
    await axios.put(url, { domain, zone, options });
    dispatch(modifyDNSZoneSuccess());
    toast.success(`Zona DNS ${zone} modificata con successo!`);
  } catch (error) {
    console.log(error);
    toast.error(`Errore: Zona DNS ${zone} non è stata modificata!`);
    dispatch(modifyDNSZoneFailure());
  }
};

export const createDNSZone = ({ domain, zone, options }) => async (dispatch) => {
  dispatch(createDNSZoneRequest());
  const url = routes.dnsZone();
  try {
    await axios.post(url, { domain, zone, options });
    dispatch(createDNSZoneSuccess());
    toast.success('DNS Zona creata con successo!');
  } catch (error) {
    console.log(error);
    toast.error('Errore: DNS Zona non creata!');
    dispatch(createDNSZoneFailure());
  }
};

export const createDKIM = ({ domain, withoutDNS }) => async (dispatch) => {
  dispatch(createDKIMRequest());
  const url = routes.dnsDkim();
  try {
    const { data } = await axios.post(url, { domain, withoutDNS });
    download(data.tXTRecord, `${domain}_dkim_record.txt`, 'text/plain');
    dispatch(createDKIMSuccess({ ...data }));
    toast.success('DKIM creato con successo!');
  } catch (error) {
    console.log(error);
    toast.error('Errore: DKIM non creato!');
    dispatch(createDKIMFailure());
  }
};

export const deleteDKIM = ({ domain, withoutDNS }) => async (dispatch) => {
  dispatch(deleteDKIMRequest());
  const url = routes.dnsDkim();
  try {
    const { data } = await axios.delete(url, { data: { domain, withoutDNS } });
    dispatch(deleteDKIMSuccess({ ...data }));
    toast.success('DKIM eliminato con successo!');
  } catch (error) {
    console.log(error);
    toast.error('Errore: DKIM non eliminato!');
    dispatch(deleteDKIMFailure());
  }
};

export const auth = loginData => async (dispatch) => {
  dispatch(authRequest());
  const url = routes.auth();
  try {
    const response = await axios.post(url, loginData);
    const { data } = response;
    dispatch(authSuccess({ ...data }));
  } catch (error) {
    console.log(error);
    dispatch(authFailure({ auth_type: '', username: '', domain: '' }));
  }
};


export const getCurrentSession = () => async (dispatch) => {
  dispatch(getCurrentSessionRequest());
  const url = routes.getCurrentSession();
  try {
    const response = await axios.get(url);
    
    const { data } = response;
    dispatch(getCurrentSessionSuccess({ ...data }));
  } catch (error) {
    dispatch(getCurrentSessionFailure({ type: '', username: '', domain: '' }));
  }
};

export const logout = () => async (dispatch) => {
  dispatch(logoutRequest());
  const url = routes.logout();
  try {
    await axios.get(url);
    dispatch(logoutSuccess({ auth_type: '', username: '', domain: '' }));
  } catch (error) {
    console.log(error);
    dispatch(logoutFailure());
  }
};
