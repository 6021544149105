/* eslint-disable react/prop-types */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export default class GuestRoute extends React.Component {
  render() {
    const { component } = this.props;
    const { path } = this.props;
    const route = !this.props.auth_type || this.props.auth_type === 'none' ? (<Route exact={this.props.exact} path={path} component={component}/>) : (<Redirect to='/' />);
    return route;
  }
}
