import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Component from '../components/BannedIP.jsx';
import * as actionCreators from '../actions';

const Container = withRouter(connect(
  (state) => {
    const props = {
      logs: state.logs,
      logs_filters: state.logs_filters,
      logs_limit: state.logs_limit,
      banned_ip: state.banned_ip,
      banned_ip_count: state.banned_ip_count,
      get_banned_ip_state: state.get_banned_ip_state,
      modify_banned_ip_state: state.modify_banned_ip_state,
      backup_db: state.backup_db,
    };
    return props;
  },
  actionCreators,
)(Component));

export default Container;
