// const host = 'https://sync.rhx.it';
const host = 'http://localhost:4005';

const configs = {
  development: {
    apiDomains: () => `${host}/api/domains`,
    auth: () => `${host}/api/auth`,
    getCurrentSession: () => `${host}/api/auth/current_session`,
    logout: () => `${host}/api/logout`,
    mailboxes: domain => `${host}/api/mailboxes/${domain}`,
    mailAliases: domain => `${host}/api/mailboxes/aliases/${domain}`,
    logs: () => `${host}/api/logs`,
    banLogs: () => `${host}/api/ban_logs`,
    mailbox_base: mail => `${host}/api/mailbox/${mail}`,
    mailbox: () => `${host}/api/mailbox`,
    alias: () => `${host}/api/mailbox/alias`,
    catchAll: () => `${host}/api/mailbox/catch_all`,
    mail_alias_base: alias => `${host}/api/mailbox/alias/${alias}`,
    domain_base: domain => `${host}/api/domain/${domain}`,
    ftp_user: domain => `${host}/api/ftp/${domain}`,
    ftp_user_put: () => `${host}/api/ftp`,
    banned_ip: () => `${host}/api/logs/banned_ip`,
    whitelist_ip: () => `${host}/api/logs/whitelist_ip`,
    blacklist_ip: () => `${host}/api/logs/blacklist_ip`,
    domain: () => `${host}/api/domain`,
    dnsDomain: domain => `${host}/api/dns/${domain}`,
    dnsZone: () => `${host}/api/dns/zone`,
    dnsDkim: () => `${host}/api/dns/dkim`,
    dnsZones: domain => `${host}/api/dns/zones/${domain}`,
    csv: domain => `${host}/api/mailboxes/csv/${domain}`,
    virtualBackupMail: domain => `${host}/api/virtualbackupmail/${domain}`,
    deleteVirtualBackupMail: () => `${host}/api/virtualbackupmail`,
    statistics: () => `${host}/api/statistics`
  },
  production: {
    apiDomains: () => '/api/domains',
    auth: () => '/api/auth',
    getCurrentSession: () => '/api/auth/current_session',
    logout: () => '/api/logout',
    mailboxes: domain => `/api/mailboxes/${domain}`,
    mailAliases: domain => `/api/mailboxes/aliases/${domain}`,
    logs: () => '/api/logs',
    banLogs: () => '/api/ban_logs',
    mailbox_base: mail => `/api/mailbox/${mail}`,
    mailbox: () => '/api/mailbox',
    alias: () => '/api/mailbox/alias',
    catchAll: () => '/api/mailbox/catch_all',
    mail_alias_base: alias => `/api/mailbox/alias/${alias}`,
    domain_base: domain => `/api/domain/${domain}`,
    ftp_user: domain => `/api/ftp/${domain}`,
    ftp_user_put: () => '/api/ftp',
    banned_ip: () => '/api/logs/banned_ip',
    whitelist_ip: () => '/api/logs/whitelist_ip',
    blacklist_ip: () => '/api/logs/blacklist_ip',
    domain: () => '/api/domain',
    dnsDomain: domain => `/api/dns/${domain}`,
    dnsZone: () => '/api/dns/zone',
    dnsDkim: () => '/api/dns/dkim',
    dnsZones: domain => `/api/dns/zones/${domain}`,
    csv: domain => `/api/mailboxes/csv/${domain}`,
    virtualBackupMail: domain => `/api/virtualbackupmail/${domain}`,
    deleteVirtualBackupMail: () => '/api/virtualbackupmail',
    statistics: () => '/api/statistics'
  },
};

export default configs[process.env.NODE_ENV];
