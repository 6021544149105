/* eslint-disable radix */
/* eslint-disable no-prototype-builtins */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';
import {
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBInputGroup,
  MDBTable,
  MDBDataTable,
  MDBTableBody,
  MDBTableHead,
} from 'mdbreact';
import Modal from './common/Modal.jsx';
import DBAlert from './common/DBAlert.jsx';
import Input from './common/Input.jsx';
// import { Link } from 'react-router-dom';

export default class WhiteListIP extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      intervalId: 0,
      isModalDOpen: false,
      isModalCOpen: false,
      ipToDelete: '',
      ipToCreate: '',
      desc: '',
      filter: null,
      description: null,
    };
  }

  filter = (e) => {
    const { value } = e.currentTarget;
    this.setState(
    {
      filter: value && value.trim() !== "" ? value : null,
    },
    async () => {
      await this.getIPs();
    });
  };

  filterDesc = (e) => {
    const { value } = e.currentTarget;
    this.setState(
    {
      description: value && value.trim() !== "" ? value : null,
    },
    async () => {
      await this.getIPs();
    });
  };
  
  getIPs = async () => {
    await this.props.getWhiteListIP(this.state.filter, this.state.description);
  };

  // OLD CODE ----------------------------------------------------------------------------
  // onCreate = async (e) => {
  //   const ip = document.getElementById('add_ip_input').value;
  //   const description = document.getElementById('add_description_input').value;
  //   await this.props.modifyWhiteListIP({ ip, description, action: 'add' });
  //   this.getIPs();
  // };
  //--------------------------------------------------------------------------------------

  getBlackLIPs = async (ip) => {
    return await this.props.getBlackListIP(ip, "");
  };

  onCreate = async () => {
    const ip = this.state.ipToCreate;
    const description = this.state.desc;
    await this.props.modifyWhiteListIP({ ip, description, action: 'add' });
    this.onCreateDialogClose();
    this.getIPs();
  };

  onClickCreate = async () => {
    const ip = document.getElementById('add_ip_input').value;
    const description = document.getElementById('add_description_input').value;
    await this.getBlackLIPs(ip);
    if(this.props.blacklist_ip.length !== 0){
      this.setState({ blacklist_ip: [], ipToCreate: ip, desc: description, isModalCOpen: true });
    }
    else{
      await this.props.modifyWhiteListIP({ ip, description, action: 'add' });
      this.getIPs();
    }
  };

  onCreateDialogClose = () => this.setState({ isModalCOpen: false });

  onDelete = async () => {
    const ip = this.state.ipToDelete;
    await this.props.modifyWhiteListIP({ ip, action: 'delete' });
    this.onDeleteDialogClose();
    this.getIPs();
  };

  onClickDelete = (e) => {
    const { id } = e.currentTarget;
    const [ip] = id.split('&_');
    this.setState({ ipToDelete: ip, isModalDOpen: true });
  };

  onDeleteDialogClose = () => this.setState({ isModalDOpen: false });

  componentDidMount = async () => {
    await this.getIPs();
    if (this.props.get_whitelist_ip_state !== 'failure') {
      const intervalId = setInterval(this.getIPs, 10000);
      this.setState({ intervalId });
    }
    document.getElementById("search-input").focus();
  };


  componentWillUnmount = () => {
    clearInterval(this.state.intervalId);
  };

  renderIPs(IPs) {
    const columns = [
      {
        label: 'Indirizzo IPv4',
        field: 'address',
      },
      {
        label: 'Descrizione',
        field: 'description',
      },
      {
        label: 'Aggiunto in',
        field: 'created_at',
      },
      {
        label: '',
        field: 'delete',
      },
    ];
    const rows = IPs.map((item) => ({
      address: <p className="font-weight-normal teal-text">{item.ip}</p>,
      description: item.description,
      created_at: (
        <div className="d-flex flex-column align-items-start mr-4">
          <span className="font-weight-normal">
            {' '}
            <MDBIcon far icon="clock" size="xs" className="mr-1" />{' '}
            {moment(item.createdAt).format('HH:mm:ss')}
          </span>
          <small className="blue-grey-text font-weight-light">
            <MDBIcon far size="md" icon="calendar-alt" className="mr-2" />{' '}
            {moment(item.createdAt).format('YYYY-MM-DD')}
          </small>
        </div>
      ),
      delete: (
        <div className="text-right">
          <a
            tag="a"
            className="text-right"
            id={`${item.ip}&_del_button`}
            onClick={this.onClickDelete.bind(this)}
          >
            <MDBIcon far icon="trash-alt" className="text-danger" size="lg" />
          </a>
        </div>
      ),
    }));

    const data = {
      rows, columns,
    }

    return (
      <MDBDataTable fixed bordered className="table-striped mt-4" /*paging ={true}*/ data={data} entries="20" entriesLabel="Per pagina:" searching={false} sortable={false}>
      </MDBDataTable>
    );
  }

  render() {
    return (
      <>
        <Modal
          size="md"
          isOpen={this.state.isModalDOpen}
          onClose={this.onDeleteDialogClose}
          closeTitle="Annulla"
          onConfirm={this.onDelete.bind(this)}
          title={`Rimuovere l'IP ${this.state.ipToDelete} dalla WhiteList?`}
          confirmTitle="Continua"
          message="Sei sicuro di voler procedere?"
        />
        <Modal
          size="md"
          isOpen={this.state.isModalCOpen}
          onClose={this.onCreateDialogClose}
          closeTitle="Annulla"
          onConfirm={this.onCreate.bind(this)}
          title={`Inserimento IP ${this.state.ipToCreate} in Whitelist`}
          confirmTitle="Continua"
          content={`Attenzione IP presente in Blacklist!\nN.B.:La Whitelist prevale sulla Blacklist.`}
          message="Sei sicuro di voler procedere?"
        />
        <MDBRow>
          <MDBCol md="6">
            <MDBInputGroup
              inputs={<>
                <Input
                type="text"
                id="add_ip_input"
                placeholder="IPv4"
                
                />
                <Input
                type="text"
                id="add_description_input"
                placeholder="Descrizione"
                /></>
              }
              append={

                <MDBBtn
                  //onClick={this.onCreate}
                  onClick={this.onClickCreate.bind(this)}
                  className="m-0 px-3 py-2 z-depth-0 btn-rhx"
                  color="yellow"
                >
                  <MDBIcon icon="plus" />
                </MDBBtn>
              }
              onKeyPress={(event) => {
                //if (event.key === 'Enter') this.onCreate();
                if (event.key === 'Enter') this.onClickCreate.bind(this)();
              }}
              label="Aggiungi un IPv4"
            />
          </MDBCol>
          <MDBCol md="3">
            <label htmlFor="search-input">Cerca un IPv4</label>
            <Input
              id="search-input"
              onChange={this.filter}
              placeholder="Cerca ipv4..."
              append={
                <MDBBtn
                  className="m-0 px-3 py-2 z-depth-0 btn-rhx"
                  color="yellow"
                >
                  <MDBIcon icon="search" />
                </MDBBtn>
              }
            />
          </MDBCol>
          <MDBCol md="3">
            <label htmlFor="search-input">Cerca descrizione</label>
            <Input
              id="search-desc-input"
              onChange={this.filterDesc}
              placeholder="Cerca IPv4 per descrizione"
              append={
                <MDBBtn
                  className="m-0 px-3 py-2 z-depth-0 btn-rhx"
                  color="yellow"
                >
                  <MDBIcon icon="search" />
                </MDBBtn>
              }
            />
          </MDBCol>
        </MDBRow>

        {this.props.get_whitelist_ip_state === 'failure' ? <DBAlert/> : this.renderIPs(this.props.whitelist_ip)}
      </>
    );
  }
}
