/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
/* eslint-disable camelcase */
import React from "react";

export default (props) => (<div className="w-100 text-center">
    <label class="form-label" for="spamProtectionInput">
      {props.title}
    </label>
    <div className="w-100 d-flex flex-row justify-content-center">
    <div className="w-75 d-flex flex-row justify-content-between">
    <div><small className="font-weight-bold">low</small></div>
    <div><small className="font-weight-bold">middle</small></div>
    <div><small className="font-weight-bold">high</small></div>
    <div><small className="font-weight-bold">very high</small></div>
    </div>
    </div>
    <div className="range w-100 text-center">
      <input type="range" class="form-range w-75" min="1" max="4" step="1" defaultValue={props.defaultValue} id="spamProtectionInput"/>
    </div>
  </div>);
