/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/no-string-refs */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-plusplus */
/* eslint-disable radix */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable quote-props */
/* eslint-disable react/prop-types */

import React from "react";
import { MDBBtn, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import Multiselect from "react-widgets/lib/Multiselect";
import Input from "./common/Input.jsx";
import Switch from "./common/Switch.jsx";
import Modal from "./common/Modal.jsx";
import CatchAllCreationForm from "./common/CatchAllCreationForm.jsx";

export default class MailAliasConfig extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: "none",
      selected: [],
      maildrop: [],
      isOpenCreationModal: false,
      maildropOfNew: [],
      refresh: 0,
    };
  }

  componentDidMount = async () => {
    await this.props.getMailAliases(this.props.current_domain.name);
    const catchAll = this.props.mail_aliases.find(
      (item) => item.mail === `@${this.props.current_domain.name}`
    );
    if (catchAll)
      this.setState({ loaded: "success", maildrop: catchAll.maildrop });
    else this.setState({ loaded: "success" });
  };

  onMaildropChange = (value) => {
    this.setState({ maildrop: value });
  };

  onMaildropItemOfNewCreate = (name) => {
    const currentMaildrop = this.state.maildropOfNew;
    this.setState({ maildropOfNew: [...currentMaildrop, name] });
  };

  onMaildropItemCreate = (name) => {
    const currentMaildrop = this.state.maildrop;
    this.setState({ maildrop: [...currentMaildrop, name] });
  };

  onSubmitConfig = async (e) => {
    e.preventDefault();
    const options = {};
    options.accountActive = document.getElementById(
      "catch_config&_accountActive&_sw"
    ).checked;
    options.amavisBypassVirusChecks = !document.getElementById(
      "catch_config&_amavisBypassVirusChecks&_sw"
    ).checked;
    options.bypassGreyListing = !document.getElementById(
      "catch_config&_bypassGreyListing&_sw"
    ).checked;
    options.smtpAuth = document.getElementById(
      "catch_config&_smtpAuth&_sw"
    ).checked;
    options.amavisBypassSpamChecks = !document.getElementById(
      "catch_config&_amavisBypassSpamChecks&_sw"
    ).checked;
    options.amavisSpamKillLevel = document.getElementById(
      "amavisSpamKillLevel£_select"
    ).value;
    options.amavisSpamTagLevel = document.getElementById(
      "amavisSpamTagLevel£_select"
    ).value;
    options.amavisSpamTag2Level = document.getElementById(
      "amavisSpamTag2Level£_select"
    ).value;
    options.maildrop = this.state.maildrop;
    // options.userPassword = document.getElementById('catch_config£_password').value || null;
    // options.conf_password = document.getElementById('catch_config£_conf_password').value || null;
    // options.givenName = document.getElementById('catch_config£_givenName').value;
    // options.sn = document.getElementById('catch_config£_sn').value;
    // options.cn = `${options.givenName} ${options.sn}`;
    await this.props.modifyCatchAll({
      mail: `@${this.props.current_domain.name}`,
      options,
    });
    this.props.getMailAliases(this.props.current_domain.name);
  };

  onCreateCatchAll = async (e) => {
    e.preventDefault();
    const gn = document.getElementById("create_catch_name").value;
    const sn = document.getElementById("create_catch_surname").value;
    const mail = document.getElementById("catch_name").value;
    const maildrop = this.state.maildropOfNew;
    // const password = document.getElementById('create_catch_pw').value || null;
    //  const conf_password = document.getElementById('create_catch_conf_pw').value || null;
    const bypassGreyListing = !document.getElementById("catch_greylisting")
      .checked;
    const amavisBypassSpamChecks = !document.getElementById("catch_spamchecks")
      .checked;

    const mailData = {
      mail,
      maildrop,
      bypassGreyListing,
      amavisBypassSpamChecks,
      sn,
      gn,
    };
    await this.props.createCatchAll(mailData);
    await this.props.getMailAliases(this.props.current_domain.name);
    this.setState({ isOpenCreationModal: false });
  };

  renderSpamKillSelect = (currentValue) => {
    const values = [
      { value: "10.0", text: "Low(10.0)", selected: false },
      { value: "6.0", text: "Medium(6.0)", selected: false },
      { value: "4.5", text: "High(4.5)", selected: false },
      { value: "3.0", text: "Very High(3.0)", selected: false },
    ];
    return (
      <div className="mdl-selectfield">
        <label>Spam Kill Level</label>
        <select
          className="browser-default custom-select"
          id="amavisSpamKillLevel£_select"
        >
          {values.map((item) => (
            <option
              key={item.value}
              value={item.value}
              selected={item.value === currentValue}
            >
              {item.text}
            </option>
          ))}
        </select>
      </div>
    );
  };

  renderSpamTagSelect = (currentValue) => {
    const values = [
      { value: "7.0", text: "Low(7.0)", selected: false },
      { value: "3.0", text: "Medium(3.0)", selected: false },
      { value: "1.5", text: "High(1.5)", selected: false },
      { value: "0.0", text: "Very High(0.0)", selected: false },
    ];
    return (
      <div className="mdl-selectfield">
        <label>Spam Tag Level</label>
        <select
          className="browser-default custom-select"
          id="amavisSpamTagLevel£_select"
        >
          {values.map((item) => (
            <option
              key={item.value}
              value={item.value}
              selected={item.value === currentValue}
            >
              {item.text}
            </option>
          ))}
        </select>
      </div>
    );
  };

  renderSpamTag2Select = (currentValue) => {
    const values = [
      { value: "9.5", text: "Low(9.5)", selected: false },
      { value: "5.5", text: "Medium(5.5)", selected: false },
      { value: "4.0", text: "High(4.0)", selected: false },
      { value: "2.5", text: "Very High(2.5)", selected: false },
    ];
    return (
      <div className="mdl-selectfield" id="last_selectfield">
        <label>Spam Tag2 Level</label>
        <select
          className="browser-default custom-select"
          id="amavisSpamTag2Level£_select"
        >
          {values.map((item) => (
            <option
              key={item.value}
              value={item.value}
              selected={item.value === currentValue}
            >
              {item.text}
            </option>
          ))}
        </select>
      </div>
    );
  };

  renderSwitches = (mailbox) => (
    <div>
      <Switch
        id={"catch_config&_smtpAuth&_sw"}
        className="custom-control-inline"
        defaultChecked={mailbox.smtpAuth}
        label="SmtpAuth"
      />
      <Switch
        id={"catch_config&_accountActive&_sw"}
        className="custom-control-inline"
        defaultChecked={mailbox.accountActive}
        label="Attivo"
      />
      <Switch
        id={"catch_config&_bypassGreyListing&_sw"}
        className="custom-control-inline"
        defaultChecked={!mailbox.bypassGreyListing}
        label="GreyListing"
      />
      {this.props.auth_type === "admin" ||
      this.props.auth_type === "postmaster" ? (
        <Switch
          disabled={this.props.current_domain.maxAntivirus < 1}
          className="custom-control-inline"
          id={"catch_config&_amavisBypassVirusChecks&_sw"}
          defaultChecked={!mailbox.amavisBypassVirusChecks}
          label="Antivirus"
        />
      ) : null}
      <Switch
        id={"catch_config&_amavisBypassSpamChecks&_sw"}
        className="custom-control-inline"
        defaultChecked={!mailbox.amavisBypassSpamChecks}
        label="SpamChecks"
      />
    </div>
  );

  openCreationModal = () => {
    this.setState({ isOpenCreationModal: true });
  };

  onNewMaildropChange = (value) => {
    this.setState({ maildropOfNew: value });
  };

  deleteCatchAll = async () => {
    await this.props.deleteMailbox({
      mail: `@${this.props.current_domain.name}`,
    });
    this.setState({ isOpenDeleteModal: false });
    this.props.getMailboxes(this.props.current_domain.name);
    this.props.getMailAliases(this.props.current_domain.name);
  };

  renderBody = (mailbox) => {
    if (mailbox) {
      return (
        <>
          <Modal
            title="Cancellazione Catch-all Alias"
            isOpen={this.state.isOpenCreationModal}
            message="Sei sicuro di voler cancellare Catch-all Alias?"
            isOpen={this.state.isOpenDeleteModal}
            onClose={() =>
              this.setState({ isOpenDeleteModal: false }).bind(this)
            }
            onConfirm={this.deleteCatchAll.bind(this)}
          />
          <form onSubmit={this.onSubmitConfig}>
            {/*  <MDBRow>
      <MDBCol md="12">
        <p>Configurazione Catch All.</p>
      </MDBCol>
</MDBRow> */}
            {/* <MDBRow>
       <MDBCol md="6" sm="12">
        <Input type="password" id="catch_config£_password" label="Password" defaultValue={''} pattern='^(?!.* ).{6,20}$'/>
        <small>Password , se inserita , deve essere tra 6 e 20 caratteri e non contenere gli spazi!</small>
        <Input type="password" id="catch_config£_conf_password" label="Conferma password" defaultValue={''} pattern='^(?!.* ).{6,20}$'/>
        <small>Le due password , se inserite , devono essere uguali</small>
    </MDBCol> 
      <MDBCol md="6" sm="12">
       <Input type="text" id="catch_config£_givenName" className="mb-4" defaultValue={mailbox.givenName} label="Nome" />
        <Input type="text" id="catch_config£_sn" defaultValue={mailbox.sn} label="Cognome"/>
      </MDBCol>
    </MDBRow> */}

            <MDBRow className="mb-3">
              <MDBCol md="2" className="pt-1">
                <p>Configurazione filtri:</p>
              </MDBCol>
              <MDBCol md="10" className="mb-3">
                {this.renderSwitches(mailbox)}
              </MDBCol>
            </MDBRow>
            <MDBRow className="mb-3">
              <MDBCol md="6">
                {this.renderSpamKillSelect(mailbox.amavisSpamKillLevel)}
              </MDBCol>
              <MDBCol md="6" className="mb-3">
                {this.renderSpamTagSelect(mailbox.amavisSpamTagLevel)}
              </MDBCol>
            </MDBRow>
            <MDBRow className="mb-3">
              <MDBCol md="6">
                {this.renderSpamTag2Select(mailbox.amavisSpamTag2Level)}
              </MDBCol>
              <MDBCol md="6">
                <label>Maildrop</label>
                <Multiselect
                  data={this.props.mailboxes.map((item) => item.mail)}
                  value={this.state.maildrop}
                  onCreate={
                    this.props.auth_type === "admin"
                      ? this.onMaildropItemCreate
                      : undefined
                  }
                  allowCreate={
                    this.props.auth_type === "admin" ? "onFilter" : undefined
                  }
                  onChange={this.onMaildropChange}
                />
              </MDBCol>
            </MDBRow>

            <MDBRow className="justify-content-center mb-4 pt-4">
              <MDBBtn
                color="success"
                type="submit"
                value="Submit"
                disabled={this.props.modify_mail_state === "request"}
              >
                <MDBIcon far icon="save" size="lg" /> Salva
              </MDBBtn>
              <MDBBtn
                value="Submit"
                color="danger"
                onClick={() =>
                  this.setState({ isOpenDeleteModal: true }).bind(this)
                }
              >
                <MDBIcon far icon="trash-alt" size="lg" /> Elimina
              </MDBBtn>
            </MDBRow>
          </form>
        </>
      );
    }
    return (
      <>
        <Modal
          title="Creazione Catch-All Alias"
          isOpen={this.state.isOpenCreationModal}
          withoutFooter
          message={
            <CatchAllCreationForm
              onClose={() =>
                this.setState({ isOpenCreationModal: false }).bind(this)
              }
              onSubmit={this.onCreateCatchAll.bind(this)}
              nameValue={`@${this.props.current_domain.name}`}
              maildropData={this.props.mailboxes.map((item) => item.mail)}
              maildropValue={this.state.maildropOfNew}
              onMaildropCreate={
                this.props.auth_type === "admin"
                  ? this.onMaildropItemOfNewCreate.bind(this)
                  : undefined
              }
              allowMaildropCreate={
                this.props.auth_type === "admin" ? "onFilter" : undefined
              }
              onMaildropChange={this.onNewMaildropChange.bind(this)}
            />
          }
        />
        <h4>CATCH-ALL ALIAS non esistente. Vuoi crearlo?</h4>
        <MDBRow>
          <MDBCol md="6">
            <p>
              <small>
                Catch-all è una funzione che permette di mandare ad un indirizzo
                specificato, tutta la posta destinata ad indirizzi del dominio
                non esistenti. Caselle di destinazione vanno impostate
                sull&apos;attributo <i className="font-weight-bold">maildrop</i>{" "}
              </small>
            </p>
          </MDBCol>
          <MDBCol md="4" className="md-offset-2 text-center">
            <MDBBtn
              className="btn-rhx"
              color="yellow"
              block
              onClick={this.openCreationModal}
            >
              + Crea
            </MDBBtn>
          </MDBCol>
        </MDBRow>
        <h4>Plus Addressing</h4>
        <MDBRow>
          <MDBCol md="6">
            <p>
              <small>
                Forse ti può interessare sapere che supportiamo il *Plus
                Addressing*, che ti consente di ricevere mail anche per
                indirizzi nel formato:
              </small>
            </p>
            <p>tuamail+parola@DOMINIO</p>
            <small>i messaggi saranno consegnati nella casella tuamail@DOMINIO (che deve 
esistere)</small>
          </MDBCol>
        </MDBRow>
      </>
    );
  };

  render() {
    const { current_domain, mail_aliases } = this.props;
    const mailbox = mail_aliases.find(
      (item) => item.mail === `@${current_domain.name}`
    );
    // eslint-disable-next-line react/prop-types
    return (
      <>{this.state.loaded === "success" ? this.renderBody(mailbox) : null}</>
    );
  }
}
