/* eslint-disable react/prop-types */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export default class AliasRoute extends React.Component {
  render() {
    const { component } = this.props;
    const { path } = this.props;
    const route = this.props.auth_type === 'admin' || this.props.auth_type === 'postmaster' || this.props.auth_type === 'alias' ? (<Route exact={this.props.exact} path={path} component={component}/>) : (<Redirect to='/login' />);
    return route;
  }
}