/* eslint-disable radix */
/* eslint-disable no-prototype-builtins */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';
import {
  MDBRow,
  MDBCol,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBIcon,
  MDBInputGroup,
} from 'mdbreact';
import momentLocalizer from 'react-widgets-moment';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import Input from './common/Input.jsx';
import proxyData from '../config/proxy_data';
import DBAlert from "./common/DBAlert.jsx";

moment.locale('it');
momentLocalizer();

export default class Logs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      intervalId: 0,
      logs_filters: {},
      logs_limit: 100,
    };
  }

  getLogs = async () => {
    await this.props.getBanLogs({
      limit: this.state.logs_limit,
      filters: this.state.logs_filters,
    });
  };

  getContinentName = (code) => {
    const element = proxyData.continents.find(el => el.valueField === code);
    return element ? element.textField : code;
  };

  componentDidMount = async () => {
    // eslint-disable-next-line no-undef
    await this.getLogs();
    if (this.props.get_ban_logs_state !== 'failure') {
      const intervalId = setInterval(this.getLogs, 1000);
      this.setState({ intervalId });
    }
    document.getElementById("ip_filter_input").focus();
  };

  componentWillUnmount = () => {
    clearInterval(this.state.intervalId);
  };

  componentDidUpdate = () => {
    /*
  const scrollDiv = document.querySelector('#logs-list');
  scrollDiv.scrollTop = scrollDiv.scrollHeight;
  */
  };

  onClickLogsButton = (e) => {
    const { id } = e.currentTarget;
    const [status] = id.split('_');
    const filters = this.state.logs_filters;
    const buttons = document.querySelectorAll('.logs_button');
    buttons.forEach(button => button.classList.remove('active'));
    document.getElementById(id).classList.add('active');
    if (status === 'all' && filters.hasOwnProperty('status')) {
      delete filters.status;
    } else filters.status = status;
    this.setState({ logs_filters: filters });
    this.getLogs();
  };

  onSelectChange = (e) => {
    const { value } = e.currentTarget;
    const logs_limit = parseInt(value);
    this.setState({ logs_limit });
    // this.getLogs();
  };

  renderSelect = (current) => {
    const currentValue = String(current);
    const values = [
      { value: '500', text: '500', selected: false },
      { value: '250', text: '250', selected: false },
      { value: '100', text: '100', selected: false },
      { value: '50', text: '50', selected: false },
      { value: '25', text: '25', selected: false },
      { value: '10', text: '10', selected: false },
    ];
    return (
      <div>
        <label>Righe da mostrare</label>
        <select
          className="browser-default custom-select"
          id="limit_rows_select"
          onChange={this.onSelectChange}
        >
          {values.map(item => (
            <option
              key={item.value}
              value={item.value}
              selected={item.value === currentValue}
            >
              {item.text}
            </option>
          ))}
        </select>
      </div>
    );
  };

  onInputChange = (e) => {
    const { id, value } = e.currentTarget;
    const [key] = id.split('_');
    const filters = this.state.logs_filters;
    filters[key] = value;
    this.setState({ logs_filters: filters });
    this.getLogs();
  };

  onBeforeDateChange = (value) => {
    const filters = this.state.logs_filters;
    filters.beforeDate = moment(value).format('YYYY-MM-DD HH:mm:ss');
    this.setState({ logs_filters: filters });
    this.getLogs();
  };

  onWithinDateChange = (value) => {
    const filters = this.state.logs_filters;
    filters.withinDate = moment(value).format('YYYY-MM-DD HH:mm:ss');
    this.setState({ logs_filters: filters });
    this.getLogs();
  };

  renderLogs(logs) {
    const columns = [
      {
        label: 'Data',
        field: 'date',
      },
      {
        label: 'Status',
        field: 'status',
      },
      {
        label: 'IPv4',
        field: 'ip',
      },
      {
        label: 'Bannato fino al',
        field: 'to',
      },
      {
        label: 'Zona',
        field: 'zone',
      },
    ];

    const rows = logs.map(item => ({
      date: (
        <div className="d-flex flex-column align-items-start mr-4">
          <span className="font-weight-normal">
            {' '}
            <MDBIcon far icon="clock" size="xs" className="mr-1" />
            {moment(item.createdAt).format('HH:mm:ss')}
          </span>
          <small className="blue-grey-text font-weight-light">
            {' '}
            <MDBIcon far size="md" icon="calendar-alt" className="mr-2" />
            {moment(item.createdAt).format('YYYY-MM-DD')}
          </small>
        </div>
      ),
      status: (
        <p>
          {item.status == 'banned' ? (
            <strong className="text-danger">
              <i className="fas fa-lock mr-2"></i>
              {item.status}
            </strong>
          ) : (
            <strong className="text-info">
              <i className="fas fa-lock-open mr-2"></i>
              {item.status}
            </strong>
          )}
        </p>
      ),
      ip: <a href={"/logs?ip=" + item.ip}><p className="font-weight-normal teal-text">{item.ip}</p></a>,
      to: (
        <>
          {item.status == 'banned' ? (
            <div className="d-flex flex-column align-items-start mr-4">
              <span className="font-weight-normal">
                {' '}
                <MDBIcon far icon="clock" size="xs" className="mr-1" />
                {moment(item.to).format('HH:mm:ss')}
              </span>
              <small className="blue-grey-text font-weight-light">
                {' '}
                <MDBIcon far icon="calendar-alt" size="md" className="mr-2" />
                {moment(item.to).format('YYYY-MM-DD')}
              </small>
            </div>
          ) : null}
        </>
      ),
      zone: (
        <div className="d-flex flex-column align-items-start mr-4">
          <span className="indigo-text font-weight-normal">{item.country}</span>
          <small
            className="orange-text font-weight-normal"
            title={item.continent}
          >
            {this.getContinentName(item.continent)}
          </small>
        </div>
      ),
    }));

    return (
      <MDBTable
        bordered
        scrollY
        responsive
        maxHeight="800px"
        className="table-striped mt-4"
      >
        <MDBTableHead columns={columns} color="dark" />
        <MDBTableBody rows={rows} />
      </MDBTable>
    );
  }

  render() {
    return (
      <>
        <MDBRow className="mb-2">
          <MDBCol md="6">
            <label>IPv4</label>
            <Input
              type="text"
              id="ip_filter_input"
              onChange={this.onInputChange}
            />
          </MDBCol>
          <MDBCol md="6">{this.renderSelect(this.state.logs_limit)}</MDBCol>
        </MDBRow>

        <MDBRow className="mb-2">
          <MDBCol md="6">
            <label>Zona</label>
            <MDBInputGroup
              type="text"
              // id="continent_filter_input"
              inputs={
                <>
                  <Input
                    type="text"
                    placeholder="nazione"
                    id="country_filter_input"
                    onChange={this.onInputChange}
                  />
                  <select
                    className="browser-default custom-select"
                    id="continent_filter_input"
                    onChange={this.onInputChange}
                    placeholder="continente"
                  >
                    <option className="text-muted" value="" default>
                      Tutti
                    </option>
                    {proxyData.continents.map(element => (
                      <option
                        value={element.valueField}
                        key={element.valueField}
                      >
                        {element.textField}
                      </option>
                    ))}
                    {}
                  </select>
                </>
              }
              // onChange={this.onInputChange}
            />
          </MDBCol>
          <MDBCol md="3">
            <label>A partire della data:</label>
            <DateTimePicker
              format="YYYY-MM-DD HH:mm:ss"
              time={false}
              onChange={this.onBeforeDateChange}
            />
          </MDBCol>
          <MDBCol md="3">
            <label>Entro la data:</label>
            <DateTimePicker
              format="YYYY-MM-DD HH:mm:ss"
              time={false}
              onChange={this.onWithinDateChange}
            />
          </MDBCol>
        </MDBRow>

        <ul className="nav nav-tabs mt-4 nav-fill justify-content-center">
          <li className="nav-item">
            <a
              className="nav-link font-weight-bold logs_button active"
              id="all_logs_button"
              onClick={this.onClickLogsButton}
              href="#"
            >
              <MDBIcon icon="list-ul" className="mr-1" />
              Tutti
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link font-weight-bold text-info logs_button"
              id="unbanned_logs_button"
              onClick={this.onClickLogsButton}
              href="#"
            >
              <MDBIcon icon="check" className="mr-1" />
              Unbanned
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link font-weight-bold red-text logs_button"
              id="banned_logs_button"
              onClick={this.onClickLogsButton}
              href="#"
            >
              <MDBIcon icon="times" className="mr-1" />
              Banned
            </a>
          </li>
        </ul>
        {this.props.get_ban_logs_state === 'failure' ? <DBAlert/> : this.renderLogs(this.props.ban_logs)}
      </>
    );
  }
}
