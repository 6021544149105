export default {
  continents: [
    { textField: 'Europa', valueField: 'EU' }, { textField: 'Africa', valueField: 'AF' },
    { textField: 'Sud America', valueField: 'SA' }, { textField: 'Nord America', valueField: 'NA' },
    { textField: 'Asia', valueField: 'AS' }, { textField: 'Oceania', valueField: 'OC' }, { textField: 'Antartide', valueField: 'AN' }
  ],

  countries: [
    {textField: 'Italia', valueField: 'Italy'}
  ],

  daysOfWeek: [
    { textField: 'Lunedì', valueField: 1 }, { textField: 'Martedì', valueField: 2 },
    { textField: 'Mercoledì', valueField: 3 }, { textField: 'Giovedì', valueField: 4 },
    { textField: 'Venerdì', valueField: 5 }, { textField: 'Sabato', valueField: 6 },
    { textField: 'Domenica', valueField: 7 }],

  hosts: [
    { textField: 'imap.rhx.it', valueField: 'imap.rhx.it' }, { textField: 'mail.rhx.it', valueField: 'mail.rhx.it' }, { textField: 'smtp.rhx.it', valueField: 'smtp.rhx.it' },
    { textField: 'imap.netech.net', valueField: 'imap.netech.net' }, { textField: 'mail.netech.net', valueField: 'mail.netech.net' }, { textField: 'smtp.netech.net', valueField: 'smtp.netech.net' },
    { textField: 'imap.bitname.net', valueField: 'imap.bitname.net' }, { textField: 'mail.bitname.net', valueField: 'mail.bitname.net' }, { textField: 'smtp.bitname.net', valueField: 'smtp.bitname.net' },
  ],

  protocols: [
    { textField: 'pop3', valueField: 'pop3' }, { textField: 'imap', valueField: 'imap' }, { textField: 'smtp', valueField: 'smtp' }, { textField: 'webmail', valueField: 'webmail' }],
    
  errors: [
    { textField: '[AUTH] Username and password not accepted', valueField: 600  }, { textField: '[AUTH] Too many authentication failure', valueField: 601 },
    { textField: '[NOPERM] Not Allowed IP', valueField: 602  }, { textField: '[NOPERM] Not Allowed Protocol', valueField: 603 },
    { textField: '[NOPERM] This geographical area is not allowed', valueField: 604  }, { textField: '[NOPERM] This IP is banned', valueField: 605 },
    { textField: '[NOPERM] Access from webmail is not permitted', valueField: 606  }, { textField: '[UNAVAILABLE] Database error. Query failed', valueField: 607 },
    { textField: '[NOPERM] Access in this date is not allowed', valueField: 608  }, { textField: '[NOPERM] This Account is disabled', valueField: 609 }, { textField: '[NOPERM] Access Denied.', valueField: 610 },
    { textField: '[UNAVAILABLE] Internal Server Error', valueField: 500  }, { textField: '[UNAVAILABLE] Service currently not available', valueField: 501 }, { textField: '[UNAVAILABLE] Ldap Server Error', valueField: 502 }
  ]
  
/*  protocols: ['imap', 'pop3'],
    allowedIp: ['193.122.15.122'],
  */
};
