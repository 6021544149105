import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Component from '../components/Header.jsx';
import * as actionCreators from '../actions';

const Container = withRouter(connect(
  (state) => {
    const props = {
      auth_type: state.auth_type,
      auth_domain: state.auth_domain,
      auth_username: state.auth_username,
    };
    return props;
  },
  actionCreators,
)(Component));

export default Container;
