/* eslint-disable react/display-name */
/* eslint-disable camelcase */
import React from "react";

export default (props) => (
  <>
    {props.label ? (
      <label htmlFor={props.id} className="grey-text">
        {props.label}
      </label>
    ) : null}
    <input
      onChange={props.onChange ? props.onChange : undefined}
      value={props.value ? props.value : undefined}
      defaultValue={props.defaultValue ? props.defaultValue : undefined}
      placeholder={props.placeholder ? props.placeholder : undefined}
      type={props.type ? props.type : undefined}
      rows={props.rows ? props.rows : undefined}
      id={props.id}
      pattern={props.pattern ? props.pattern : undefined}
      className={`form-control ${props.className ? props.className : " "}`}
      required={props.required ? props.required : undefined}
      disabled={props.disabled ? props.disabled : undefined}
      name={props.name ? props.name : undefined}
    />
  </>
);
