/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React from 'react'
import { MDBContainer } from 'mdbreact'
import config from '../config';

export default class Footer extends React.Component {
	render() {
		return (
			<footer className="fixed-bottom font-small" style={{backgroundColor : config.mainColor}}>
				<MDBContainer>
					<div className="d-flex justify-content-around py-3">
						<img src={config.logoPath} width="80px" height="80px" className="img-fluid" alt="" />
						<img width="80px" height="80px" src="./fonts/phamm_logo.svg" className="img-fluid" alt="" />
					</div>
				</MDBContainer>
				<div className="footer-copyright text-center py-2">
					Un servizio:
					<a className="text-white" href="https://www.rhx.it">
						{' '}
						RHX
					</a> | <a className="text-white" href="/assets/files/license.txt">License BSD</a>
				</div>
			</footer>
		)
	}
}
