/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable radix */
/* eslint-disable no-prototype-builtins */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React from "react";
import moment from "moment";
import {
  MDBRow,
  MDBCol,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBIcon,
  MDBInputGroup,
  MDBBtn,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBFormInline,
  MDBInput
} from "mdbreact";
import momentLocalizer from "react-widgets-moment";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import config from "../config";
import Input from "./common/Input.jsx";
import proxyData from "../config/proxy_data";
import DBAlert from "./common/DBAlert.jsx";
import Switch from './common/Switch.jsx';

moment.locale("it");
momentLocalizer();

export default class Logs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      intervalIds: [],
      logs_filters: {},
      logs_limit: 100,
      connectionState: "none",
      activeTabId: "all_logs_button",
      reverseZoneInput: false,
    };
  }

  getLogs = async () => {
    /*if (this.props.forDomain) {
      await this.props.getLogs({
        limit: this.state.logs_limit,
        filters: {
          ...this.state.logs_filters,
          domain: this.props.current_domain.name,
        },
      });
    } else {*/
      if(this.isPostmaster()){
        await this.props.getLogs({
          limit: this.state.logs_limit,
          filters:{
            ...this.state.logs_filters,
            domain: localStorage.getItem("domain"),
            beforeDate: moment().startOf("day").subtract(config.log_days,'d').format("YYYY-MM-DD HH:mm:ss"),
          },
        });
      }
      else {
        await this.props.getLogs({
          limit: this.state.logs_limit,
          filters: this.state.logs_filters,
        });
      }
    //}
  };

  getIntervalLogs = async () => {
    this.stopIntervalLogs();
    await this.getLogs();
    if (this.props.get_logs_state !== "failure") {
      const intervalId = setInterval(() => {
        if (this.props.get_logs_state !== "request") this.getLogs();
      }, process.env.PAGE_TIMEOUT);
      this.setState({ intervalIds: [intervalId, ...this.state.intervalIds] });
    }
  };

  stopIntervalLogs = () => {
    const { intervalIds } = this.state;
    intervalIds.forEach((intervalId) => {
      clearInterval(intervalId);
    });
  };

  getContinentName = (code) => {
    const element = proxyData.continents.find((el) => el.valueField === code);
    return element ? element.textField : code;
  };

  onFiltersChange = () => {
    this.stopIntervalLogs();
  };

  componentDidMount = async () => {
    // eslint-disable-next-line no-undef
    await this.getIntervalLogs();
    // await this.getLogs();
    document.getElementById("domain_filter_input").focus();
  };

  isPostmaster = () => {
    return this.props.auth_type === "postmaster";
  }

  isPrivateIP = (ip) => {
    ip = ip.split(".");
    if(ip[0] === "10") return true;
    if(ip[0] === "127") return true;
    if(ip[0] === "172" && ip[1]>15 && ip[1]<32) return true;
    if(ip[0] === "192" && ip[1] === "168") return true;
    return false;
  }

  componentWillMount = () => {
    if(this.isPostmaster() && this.props.current_domain){
      localStorage.setItem("domain",this.props.current_domain.name);
      localStorage.setItem("logsView",this.props.current_domain.logsView);
    }
    if(this.props.location.search) {
      const ip_filter=this.props.location.search.split("=")[1];

      const filters = this.state.logs_filters;
      filters.ip = ip_filter;
      this.setState({ logs_filters: filters });
      this.props.location.search = "";
    }
  };

  componentWillUnmount = () => {
    this.stopIntervalLogs();
  };

  onClickLogsButton = async (e) => {
    const { id } = e.currentTarget;
    const [status] = id.split("_");
    const filters = this.state.logs_filters;

    if (status === "all" && filters.hasOwnProperty("status")) {
      delete filters.status;
    } else filters.status = status;
    this.setState({ logs_filters: filters });
    await this.getIntervalLogs();
    // await this.getLogs();
    this.setState({ activeTabId: id });
  };

  updateActiveTab = (id) => {
    const buttons = document.querySelectorAll(".logs_button");
    buttons.forEach((button) => button.classList.remove("active"));
    document.getElementById(id).classList.add("active");
  };

  onClickFilterButton = async () => {
    await this.getIntervalLogs();

    /* await this.getLogs(); */
  };

  onResetFilters = async () => {
    Array.from(
      document.querySelectorAll(
        "input, select:not(#limit_rows_select), .rw-widget-input"
      )
    ).forEach((input) => (input.value = ""));
    this.setState({ logs_filters: {}, activeTabId: "all_logs_button" });
    await this.getIntervalLogs();
    /* await this.getLogs(); */
  };

  onSelectChange = (e) => {
    this.onFiltersChange();
    const { value } = e.currentTarget;
    const logs_limit = parseInt(value);
    this.setState({ logs_limit });
  };

  renderSelect = (current) => {
    const currentValue = String(current);
    const values = [
      { value: "500", text: "500", selected: false },
      { value: "250", text: "250", selected: false },
      { value: "100", text: "100", selected: false },
      { value: "50", text: "50", selected: false },
      { value: "25", text: "25", selected: false },
      { value: "10", text: "10", selected: false },
    ];
    return (
      <div>
        <label>Righe da mostrare</label>
        <select
          className="browser-default custom-select"
          id="limit_rows_select"
          onChange={this.onSelectChange}
        >
          {values.map((item) => (
            <option
              key={item.value}
              value={item.value}
              selected={item.value === currentValue}
            >
              {item.text}
            </option>
          ))}
        </select>
      </div>
    );
  };

  onInputChange = (e) => {
    this.onFiltersChange();
    const { id, value } = e.currentTarget;
    let [key] = (() =>{
      if(id.split("_")[0] === "error"){
        return ["error_code"];
      } else {
        return id.split("_");
      };
    })();
    const filters = this.state.logs_filters;
    if(key.startsWith('!') && filters.hasOwnProperty(key.replace('!', ''))){
      delete filters[key.replace('!', '')];
    } else {
      delete filters[`!${key}`];
    }
    if (key === "destinationip") key = "destination_ip"; //Rimonimo la key in modo che corrisponda al nome del campo del db
    if (key === "protocol") {
      if( value == "webmail" ) {
        delete filters["!ip"];
        filters["ip"] = config.webmailIP;
        filters[key] = "imap";
      }
      else {
        if( filters["ip"] === config.webmailIP ){
          var ip_filter_element = document.getElementById("ip_filter_input");
          ip_filter_element.value = "";
          delete filters["ip"];
        }
        if( value === "" ) delete filters["!ip"]; 
        else filters["!ip"] = config.webmailIP;
        filters[key] = value;
      }
    }
    else filters[key] = value;
    this.setState({ logs_filters: filters });
    // this.getLogs();
  };

  onBeforeDateChange = (value) => {
    this.onFiltersChange();
    const filters = this.state.logs_filters;
    filters.beforeDate = moment(value).format("YYYY-MM-DD HH:mm:ss");
    this.setState({ logs_filters: filters });
    // this.getLogs();
  };

  toggleReverseZoneInput(){
    Array.from(document.querySelectorAll(
      '.zone-input'
    )
  ).forEach((input) => (input.value = ""));
    const filters = this.state.logs_filters;
    delete filters['country'];
    delete filters['continent'];
    delete filters['!country'];
    delete filters['!continent'];
    var currentValue = this.state.reverseZoneInput;
    this.setState({
      reverseZoneInput: !currentValue,
    });
  }

  onWithinDateChange = (value) => {
    this.onFiltersChange();
    const filters = this.state.logs_filters;
    filters.withinDate = moment(value).format("YYYY-MM-DD HH:mm:ss");
    this.setState({ logs_filters: filters });
    // this.getLogs();
  };

  renderLogs(logs) {
    const columns = [
      {
        label: "Data",
        field: "date",
      },
      {
        label: "Status",
        field: "status",
      },
      {
        label: "Protocollo",
        field: "protocol",
      },
      {
        label: "Username",
        field: "username",
      },
      {
        label: "IPv4",
        field: "ip",
      },
      {
        label: "Zona",
        field: "zone",
      },
    ];

    const rows = logs.map((item) => (
      // Returns only logs having data related to the domain logged in.
      ((this.isPostmaster())&&(!(item.domain === localStorage.getItem("domain"))))? {} : { 
        date: (
        <div className="d-flex flex-column align-items-start mr-4">
          <span className="font-weight-normal">
            {" "}
            <MDBIcon far icon="clock" size="xs" className="mr-1" />
            {moment(item.createdAt).format("HH:mm:ss")}
          </span>
          <small className="blue-grey-text font-weight-light">
            {" "}
            <MDBIcon far size="md" icon="calendar-alt" className="mr-2" />
            {moment(item.createdAt).format("YYYY-MM-DD")}
          </small>
        </div>
        ),
        status: (
          <div className="d-flex flex-column align-items-start">
            {(item.error_code != null && (item.error_code.toString())[0] != "2") ? (
              <span className="red-text">Failed</span>
            ) : (
              <span className="green-text">Success</span>
            )}
            {(item.error_code != null && (item.error_code.toString())[0] != "2") ? (
              <small className="font-weight-light grey-text">
                {item.error_message}
              </small>
            ) : (
              <>
                <small className="font-weight-light grey-text">
                  opening connection to
                </small>
                <small className="font-weight-normal cyan-text">
                  <i className="indigo-text">{item.destination_ip}</i>
                </small>
              </>
            )}
          </div>
        ),
        protocol: (
          <p className="font-weight-normal brown-text">{item.protocol}</p>
        ),
        username: (
          <p className="font-weight-normal blue-text">
            {this.isPostmaster() ? ( item.username.split("@")[0].length>=3 ? ( item.username[0] + "*".repeat(item.username.split("@")[0].length-2) + item.username.split("@")[0][item.username.split("@")[0].length-1] ) : "*".repeat(item.username.split("@")[0].length) ) : item.username.split("@")[0]}
            {item.username.split("@").length > 1 ? "@" : ""}
            <i className="deep-purple-text">{item.username.split("@")[1]}</i>
          </p>
        ),
        ip: (
          <>
            {item.ip === config.webmailIP ? (
                <div className="d-flex flex-column align-items-start">
                  <span className="font-weight-normal teal-text">{item.ip}</span>
                  <small className="font-weight-light grey-text">(webmail)</small>
                </div>
              ) : ( this.isPostmaster() ? 
                (
                  <div className="d-flex flex-column align-items-start">
                  <span className="font-weight-normal teal-text">{item.ip.split(".")[0] + "." + item.ip.split(".")[1] + "." + item.ip.split(".")[2] + "." + "xxx"}</span>
                  {this.isPrivateIP(item.ip) ? <small className="font-weight-light grey-text">(private IP)</small> : null}
                  </div>
                ) :
                (
                  <div className="d-flex flex-column align-items-start">
                  <span className="font-weight-normal teal-text">{item.ip}</span>
                  {this.isPrivateIP(item.ip) ? <small className="font-weight-light grey-text">(private IP)</small> : null}
                  </div>
                )
              )
            }
          </>
        ),
        zone: (
          <div className="d-flex flex-column align-items-start mr-4">
            <span className="indigo-text font-weight-normal">{item.country}</span>
            <small
              className="orange-text font-weight-normal"
              title={item.continent}
            >
              {this.getContinentName(item.continent)}
            </small>
          </div>
        ),
      }
    ));

    return (
      ( (this.props.auth_type === "admin") || ( ((localStorage.getItem("logsView")==="true")) && (this.isPostmaster()) ) ) ? 
      <MDBTable
        bordered
        scrollY
        responsive
        maxHeight="800px"
        className="table-striped mt-4"
      >
        <MDBTableHead columns={columns} color="dark" />
        <MDBTableBody rows={rows} />
      </MDBTable>
      :
      <h5 className="text-center">
        <MDBIcon
          fas
          icon="exclamation-triangle"
          className="text-danger"
        ></MDBIcon>
        Visualizzazione log disabilitata. Per attivarla contattare il gestore della posta.
      </h5>
    );
  }

  render() {
    return (
      <>
        <MDBRow className="mb-2">
          <MDBCol md="3">
            <label>Dominio</label>
            {//!this.props.forDomain ? (
              this.isPostmaster() ?
              <Input
                type="text"
                placeholder = {localStorage.getItem("domain")}
                id="domain_filter_input"
                disabled
              />
              :
              <Input
                type="text"
                id="domain_filter_input"
                onChange={this.onInputChange}
              />
            /*) : null*/}
          </MDBCol>
          <MDBCol md="3">
            <label>IPv4</label>
            <Input
              type="text"
              id="ip_filter_input"
              value={this.state.logs_filters.ip}
              onChange={this.onInputChange}
            />
          </MDBCol>
          <MDBCol md="3">
            <label>Username</label>
            <Input
              type="text"
              id="username_filter_input"
              onChange={this.onInputChange}
            />
          </MDBCol>
          <MDBCol md="3">{this.renderSelect(this.state.logs_limit)}</MDBCol>
        </MDBRow>

        <MDBRow className="mb-2">
          <MDBCol md="3">
            <label>Protocollo / Destinazione</label>
            <MDBInputGroup
              inputs={
                <> 
                  <select
                    className="browser-default custom-select"
                    id="protocol_filter_input"
                    onChange={this.onInputChange}
                    placeholder="protocollo"
                  >
                    <option className="text-muted" value="" default>
                      Tutti
                    </option>
                    {proxyData.protocols.map((element) => (
                      <option
                        value={element.valueField}
                        key={element.valueField}
                      >
                        {element.textField}
                      </option>
                    ))}
                    {}
                  </select>
                  <select
                    className="browser-default custom-select"
                    id="destinationip_filter_input"
                    onChange={this.onInputChange}
                    placeholder="host di destinazione"
                  >
                    <option className="text-muted" value="" default>
                      Tutti
                    </option>
                    {proxyData.hosts.map((element) => (
                      <option
                        value={element.valueField}
                        key={element.valueField}
                      >
                        {element.textField}
                      </option>
                    ))}
                    {}
                  </select>
                </>
              }
            />
          </MDBCol>
          <MDBCol md="3">
              <Switch id="reverseZoneInput"  prependLabel="La zona indicata è da escludere:" checked={this.state.reverseZoneInput} onChange={this.toggleReverseZoneInput.bind(this)}/>
            <MDBInputGroup
              type="text"
              // id="continent_filter_input"
              inputs={
                <>
                  <Input
                    type="text"
                    placeholder="nazione"
                    id={this.state.reverseZoneInput ? "!country_filter_input" : "country_filter_input"}
                    onChange={this.onInputChange}
                    className="zone-input"
                  />
                  <select
                    className="browser-default custom-select zone-input"
                    id={this.state.reverseZoneInput ? "!continent_filter_input" : "continent_filter_input"}
                    onChange={this.onInputChange}
                    placeholder="continente"
                  >
                    <option className="text-muted" value="" default>
                      Tutti
                    </option>
                    {proxyData.continents.map((element) => (
                      <option
                        value={element.valueField}
                        key={element.valueField}
                      >
                        {element.textField}
                      </option>
                    ))}
                    {}
                  </select>
                </>
              }
              // onChange={this.onInputChange}
            />
          </MDBCol>
          <MDBCol md="3">
            {//!this.props.forDomain ? (
              this.isPostmaster() ?
              <>
                <label>A partire della data:</label>
                <DateTimePicker
                  format="YYYY-MM-DD HH:mm:ss"
                  placeholder= {moment().startOf("day").subtract(config.log_days,'d').format("YYYY-MM-DD HH:mm:ss")}
                  time={true}
                  disabled
                />
              </>
              :
              <>
                <label>A partire della data:</label>
                <DateTimePicker
                  format="YYYY-MM-DD HH:mm:ss"
                  time={true}
                  onChange={this.onBeforeDateChange}
                />
              </>
            }
          </MDBCol>
          <MDBCol md="3">
            <label>Entro la data:</label>
            <DateTimePicker
              format="YYYY-MM-DD HH:mm:ss"
              time={true}
              onChange={this.onWithinDateChange}
            />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md="3">
             <label>Errore</label>
            {/*<Input
              type="text"
              id="error_message_filter_input"
              onChange={this.onInputChange}
            />
          </MDBCol> */}
          <MDBInputGroup
            inputs={
              <>
                <select
                  className="browser-default custom-select"
                  id="error_code_filter_input"
                  onChange={this.onInputChange}
                >
                  <option className="text-muted" value="" default>
                    Tutti
                  </option>
                  {proxyData.errors.map((element) => (
                    <option
                      value={element.valueField}
                      key={element.valueField}
                    >
                      {element.textField}
                    </option>
                  ))}
                  {}
                </select>
              </>
            }
          />
          </MDBCol>
          <MDBBtn
            outline
            color="dark"
            className="ml-auto"
            /* disabled={this.props.get_logs_state === "request"} */
            onClick={this.onResetFilters}
          >
            <MDBIcon icon="redo-alt" className="mr-2" />
            Azzera
          </MDBBtn>
          <MDBBtn
            color="dark"
            onClick={this.onClickFilterButton}
            /* disabled={this.props.get_logs_state === "request"} */
          >
            <MDBIcon icon="search" className="mr-2" />
            Filtra
          </MDBBtn>
        </MDBRow>

        <MDBNav className=" mt-4 nav-fill nav-tabs justify-content-center">
          <MDBNavItem>
            <a
              className={`nav-link font-weight-bold logs_button ${
                this.state.activeTabId == "all_logs_button" ? "active" : ""
              }`}
              id="all_logs_button"
              onClick={this.onClickLogsButton}
              href="#"
            >
              <MDBIcon icon="list-ul" className="mr-1" />
              Tutti
            </a>
          </MDBNavItem>
          <li className="nav-item">
            <a
              className={`nav-link font-weight-bold green-text logs_button ${
                this.state.activeTabId == "logged_logs_button" ? "active" : ""
              }`}
              id="logged_logs_button"
              onClick={this.onClickLogsButton}
              href="#"
            >
              <MDBIcon icon="check" className="mr-1" />
              Loggati
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link font-weight-bold red-text logs_button ${
                this.state.activeTabId == "failed_logs_button" ? "active" : ""
              }`}
              id="failed_logs_button"
              onClick={this.onClickLogsButton}
              href="#"
            >
              <MDBIcon icon="times" className="mr-1" />
              Falliti
            </a>
          </li>
        </MDBNav>
        {this.props.get_logs_state === "failure" ? (
          <DBAlert />
        ) : (
          this.renderLogs(this.props.logs)
        )}
      </>
    );
  }
}
