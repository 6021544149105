/* eslint-disable react/display-name */
/* eslint-disable react/no-string-refs */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-plusplus */
/* eslint-disable radix */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable quote-props */
/* eslint-disable react/prop-types */

import React from "react";
import { MDBBtn, MDBRow, MDBCol, MDBInputGroup } from "mdbreact";
import Input from "./common/Input.jsx";
import Switch from "./common/Switch.jsx";

export default (props) => (
  <>
    <MDBRow className="mb-2">
      <MDBCol md="6" sm="12">
        <Input
          type="number"
          id="domain£_maxMail"
          label="Numero massimo email"
          onChange={props.onDomainInputChange}
          disabled={props.authType !== "admin"}
          defaultValue={props.domain.maxMail}
        />
      </MDBCol>
      <MDBCol md="6" sm="12">
        <Input
          type="number"
          id="domain£_maxSmtpAuth"
          label="Numero massimo Smtp Auth"
          onChange={props.onDomainInputChange}
          disabled={props.authType !== "admin"}
          defaultValue={props.domain.maxSmtpAuth}
        />
      </MDBCol>
    </MDBRow>
    <MDBRow className="mb-2">
      <MDBCol md="6" sm="12">
        <Input
          type="number"
          id="domain£_maxAlias"
          label="Numero massimo Alias"
          onChange={props.onDomainInputChange}
          disabled={props.authType !== "admin"}
          defaultValue={props.domain.maxAlias}
        />
      </MDBCol>
      <MDBCol md="6" sm="12">
        <Input
          type="number"
          id="domain£_maxAntivirus"
          label="Numero massimo Antivirus"
          onChange={props.onDomainInputChange}
          disabled={props.authType !== "admin"}
          defaultValue={props.domain.maxAntivirus}
        />
      </MDBCol>
    </MDBRow>
    <MDBRow className="mb-2">
	<MDBCol md="6" sm="12">
		  <label className="text-muted">Quota Massima Espansioni</label>
        <MDBInputGroup
		  inputs={
			  <>
			  <Input
          type="number"
          id="domain£_maxQuota"
          onChange={props.onDomainInputChange}
          disabled={props.authType !== "admin"}
          defaultValue={props.domain.maxQuota}
        />
			  </>
		  }
		  append={<span className="input-group-text">GB</span>}
        />
      </MDBCol>
      <MDBCol md="6" sm="12" className="mt-4 pt-2">
        <MDBRow className="mb-2">
        <Switch
          id="domain£_accountActive"
          onChange={props.onDomainSwitchChange}
          className="custom-control-inline"
          disabled={props.authType !== "admin"}
          defaultChecked={props.domain.accountActive}
          label="Dominio attivo per la ricezione di posta"
        />
          <Switch
            id="domain£_logsView"
            onChange={props.onDomainSwitchChange}
            className="custom-control-inline"
            disabled={!(props.authType == "admin")}
            defaultChecked={props.domain.logsView}
            label="Vista log attiva per accesso postmaster"
          />
        </MDBRow>
      </MDBCol>
    </MDBRow>
  </>
);
